<template>
    <div v-if="is_logged_in && !loading" class="favourite-component">
        <span class="favourite-component--toggle" @click="toggleFavourite()">
            <icon-heart-outline v-if="!is_favourited" />
            <icon-heart-filled v-if="is_favourited" />
        </span>
    </div>
</template>

<script>

    var accepted_types = ['agent', 'property'];

    export default {

        props: ['type', 'id'],

        data: function() {
            return {
                is_favourited: false,
                is_logged_in: false,
                loading: true,
            }
        },

        created() {
            this.is_logged_in = this.$store.getters['user/isLoggedIn'];
            this.loading = false;
        },
        
        mounted() {
            if(accepted_types.indexOf(this.type) == -1) {
                // console.log('Incorrect type passed');
            }

            this.is_favourited = this.isFavourite();
        },

        methods: {
            toggleFavourite: function() {
                this.loading = true;

                let that = this;
                let url = this.getToggleFavUrl();

                axios.post(url, {                          
                            value: this.id                          
                })
                .then(response => {  
                    this.is_favourited = (response.data == 'true') ? true : false;
                })
                .catch(function (error) {
                    // handle error

                })
                .finally(function () {
                    that.loading = false;
                });

                return false;
            },

            isFavourite: function() {
                var dataset = null;
                switch(this.type) {
                    case 'agent':
                        dataset = this.$store.getters['user/FavouriteAgents'];
                        break;
                    case 'property':                        
                        dataset = this.$store.getters['user/FavouriteProperties'];
                        break;
                }

                //if found in array, return true (favourited) else false
                return (dataset.indexOf(this.id) == -1) ? false : true;
            },

            getToggleFavUrl: function() {
                var url = '';

                switch(this.type) {
                    case 'agent':
                        url = '/favourite/agent';
                        break;
                    case 'property':
                        url = '/favourite/property';
                        break;
                }

                return url;
            }
        }
    }
</script>