<template>
    <div>
        <div class="section--my-profile">

            <div class="my-profile--actions">
                <a @click="$emit('viewMainComponent')">
                    <icon-nav-arrow-left />
                    <span>{{ trans('account.back_to_my_profile') }}</span>
                </a>
                <a @click="saveUser">
                    <icon-save />
                    <span>{{ trans('account.save_changes') }}</span>
                </a>
            </div>

            <div v-if="user" class="row my-30">
                <div class="col-6 col-md-3 mb-30">
                    <img class="img-fluid" :src="getUserProfileImage()" />
                </div>
                <div class="col-12 col-md-9 my-profile--details">
                    <div class="mb-15 mb-md-30">
                        <label class="mb-0">{{ trans('account.full_name') }}</label>
                        <input type="text" class="form-control" id="" name="" v-model="user.name" />
                    </div>
                    <div class="mb-15 mb-md-30">
                        <label class="mb-0">{{ trans('account.email') }}</label>
                        <input type="email" class="form-control" id="" disabled readonly name="" v-model="user.email" />
                    </div>
                    <div class="mb-30 mb-md-60">
                        <label class="mb-0">{{ trans('account.phone_number') }}</label>
                        <input type="text" class="form-control" id="" name="" v-model="user.phone_number" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                user: {
                    avatar_url: null,
                    name: null,
                    email: null,
                    phone_number: null
                }
            }
        },

        mounted() {
            this.getUser();
        },

        methods: {
            getUser: function() {
                axios.get('/user')
                .then(response => { 
                    this.user = response.data;
                });
            },

            saveUser: function() {                
                axios.put('/user', this.user)
                .then(response => {    
                    this.emitViewProfile();
                });
            },

            emitViewProfile: function() {
                this.$emit('viewMainComponent');
            },

            getUserProfileImage: function() {
                if (this.user.avatar_url != null) {
                    return this.user.avatar_url;
                }
                else {
                    return '/images/icons/default-user-icon.png';
                }
            }
        }
    }
    
</script>
