<template>
    <div class="section--property-cards-with-map-toggle" :style="getSectionBgColour">

        <section class="section--title--sort">
            <div v-bind:class="{ 'stretch': acfFields.show_orderby == false  && acfFields.show_search_bar == false }">
                <div v-if="acfFields.title && acfFields.title != ''">
                    <component class="mb-0" :is="acfFields.heading_level" v-html="acfFields.title"></component>
                </div>
                <div v-else-if="this.$helpers.stringToBoolean($store.getters['ListingSearch/get']['ForSale']) && this.$helpers.stringToBoolean($store.getters['ListingSearch/get']['ForRent'])">
                    <{{ acfFields.heading_level }}>{{ trans('properties.listed_properties_for_sale_and_rent') }}</{{ acfFields.heading_level }}>
                </div>
                <div v-else-if="this.$helpers.stringToBoolean($store.getters['ListingSearch/get']['ForSale'])">
                    <component class="mb-0" :is="acfFields.heading_level" v-html="trans('properties.listed_properties_for_sale')"></component>
                </div>
                <div v-else-if="this.$helpers.stringToBoolean($store.getters['ListingSearch/get']['ForRent'])">
                    <component class="mb-0" :is="acfFields.heading_level" v-html="trans('properties.listed_properties_for_rent')"></component>
                </div>
                <div v-else>
                    <component class="mb-0" :is="acfFields.heading_level" v-html="trans('properties.listed_properties')"></component>
                </div>
            </div>
            <div class="mt-10 mt-md-0" v-if="acfFields.show_orderby == true && acfFields.show_search_bar == false">
                <property-sort></property-sort>
            </div>
        </section>

        <section v-if="acfFields.show_search_bar" class="section--search--map">
            <div>
                <listings-search :preFilter="acfFields.property_filtering"  @searchSubmit="searchSubmitListener"></listings-search>            
                <div v-if="acfFields.show_map && map_markers" class="map--switch" :class="isMapVisible">
                    <switches v-model="showMap" theme="custom" color="blue"></switches> 
                    <span>{{ trans('properties.show_map') }}</span>
                </div>
                
            </div>
        </section>

        <section class="section--search-mobile-shortcuts" v-if="$store.getters['user/isLoggedIn'] || (acfFields.show_map && map_markers)">
            <div class="row bg-white">
                <a v-if="$store.getters['user/isLoggedIn']" class="btn btn-link" @click="$store.dispatch('ListingSearch/saveSearch');">
                    <span><icon-save /></span> 
                    <span class="text-primary">Save Search</span>
                </a>
                <a v-model="acfFields.show_map && map_markers" class="btn btn-link" data-toggle="modal" data-target="#map-modal">
                    <span><icon-listings /></span> 
                    <span class="text-primary" @click="showMap = !showMap">View Map</span>
                </a>
            </div>
        </section>

        <section class="section--property-cards-with-map-toggle--cards-map" :class="showMap == true ? 'map-open' : ''">
            <section class="section--property-cards-with-map-toggle--cards">
                <section class="section--property-cards-with-map-toggle--cards-row">
                    <loading-component v-if="isLoading"></loading-component>
                    <template v-if="properties != null && properties.length > 0">
                        <property-card v-for="(property, index) in properties" v-bind:key="property.mls" :force-small="forceShowSmallCard(index)" :property="property"></property-card>                      
                    </template>
                    <template v-if="!isLoading && properties.length == 0">
                        <div class="col py-50 text-center mx-auto">
                            <h5>No properties found</h5>
                        </div>
                    </template>
                </section>
            </section>

            <section class="section--property-cards-with-map-toggle--map" v-if="acfFields.show_map">
                <custom-map v-if="map_markers" :markers="map_markers"/>
            </section>
        </section>

        <div class="modal fade" id="map-modal" tabindex="-1" role="dialog" aria-labelledby="map-modalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <custom-map v-if="map_markers" :markers="map_markers"/>
                    </div>
                </div>
            </div>
        </div>

        <section v-if="acfFields.show_pagination && properties != null && properties.length > 0" class="section--pagination">
            <div v-if="pagination" v-for="page in pagination">  
                <div class="pagination--button" :class="{ 'pagination--selected' : page.page == $store.getters['ListingSearch/get']['page'] }" v-on:click="executePagination(page.page)"><p>{{ page.text }}</p></div>
            </div>
        </section>  
              
    </div>
</template>

<script>
    export default {
        props: [
            'acfFields',
            'additionalParameters'
        ],
        data: function () {
            return {
                properties: [],
                pagination: [],
                map_markers: null,
                criterias: {                    
                    page: null,
                    //take: this.getShowPropertyCount(),                    
                },
                isLoading: false,
                showMap: false,
                mapDataLoaded: false,
                maxPaginationNext: 3,
                maxPaginationPrev: 3,
            }
        },

        watch: {
            showMap() {
                var that = this;
                // window.dispatchEvent(new Event('resize'));
                
                setTimeout(function() {
                    that.$root.$emit('update-map');
                    window.dispatchEvent(new Event('resize'));
                }, 500);
            }
        },

        created() {
            this.setPreFiltering(this.acfFields.property_filtering);
            this.setAdditionalParameters(this.additionalParameters);
            this.getQueryStringParameters();
        },

        mounted() {
            //get current page from url and store it in var
            //this.criterias.page = this.getCurrentPage();

            //get property listings from api
            this.getProperties();

            //create event listener for when the back button in the browser is hit.
            this.addHistoryBackButtonListener();
        },

        computed: {
            isMapVisible() {
                return (this.showMap) ? 'Enabled' : 'Disabled';
            },
            
            getSectionBgColour() {
                return (this.acfFields.background_colour) ? 'background-color:'+this.acfFields.background_colour+';'
                : '';
            },
        },

        methods: {
            forceShowSmallCard(index) {
                return (index >= parseInt(this.acfFields.max_large_card_size_amount) && 
                        parseInt(this.acfFields.max_large_card_size_amount) != -1) ? true : false;
            },

            searchSubmitListener: function() {                
                //load new data in main criteria object. Overwrite existing
                //this.updateMainCriteriaWithNewData(search_criteria.criterias);

                this.updateParamsInUrl(this.$store.getters['ListingSearch/get']);

                //fetch properties with updated criteria
                this.getProperties();
            },

            updateMainCriteriaWithNewData: function(new_critera) {
                this.criterias = { ...this.criterias, ...new_critera };
            },

            //get url parameters and store in criteria
            getQueryStringParameters: function() {
                var urlParams = new URLSearchParams(window.location.search);
                //get criterias from vuex
                var criterias = JSON.parse(JSON.stringify(this.$store.getters['ListingSearch/get']));
                
                //loop criterias
                for (var criteria in criterias) {

                    if (criterias.hasOwnProperty(criteria)) {
                        //get value
                        var url_value = urlParams.get(criteria);

                        if (url_value == null || url_value == undefined) {
                            continue;
                        }

                        //if array join them up ex . 1,2,3,4, else just display value ex . 1 
                        criterias[criteria] = Array.isArray(criterias[criteria]) !== false ? url_value.split(',') : url_value;
                    }
                }

                //update vuex with properties retrieved from URL
                this.$store.dispatch('ListingSearch/updateFullSearchCriteria', criterias);
            },

            popStateListenerHandler: function(state) {
                //get page. if page parameter is not found, than get current page from url. This happens when we arrive at the initial entry page                
                // var page = null;
                // if (state !== null && state.hasOwnProperty('page')) {
                //     page = state.page;
                // }          
                // else {
                //     page = this.getCurrentPage();
                // }      

                /**
                 * 
                 * When loading from a back button
                 * we must get the paramters again 
                 * to ensure the right data is loaded.
                 * 
                 * */
                this.getQueryStringParameters();

                this.getProperties();
            },

            addHistoryBackButtonListener: function() {
                var that = this;
                addEventListener('popstate', function (event) {
                    that.popStateListenerHandler(event.state);
                });
            },

            destroyHistoryBackButtonListener: function() {
                removeEventListener('popstate', listener[options]);
            },

            getCurrentPage: function() {
                var urlParams = new URLSearchParams(window.location.search);

                if (urlParams.get('page') == null) {
                    return 1;
                }

                return urlParams.get('page');
            },

            updateParamInUrl: function(key, value) {
                var path = window.location.origin + window.location.pathname;

                var urlParams = new URLSearchParams(window.location.search);
                urlParams.set(key, value);

                //update also main object
                //this.criterias[key] = value;

                window.history.pushState(this.$store.getters['ListingSearch/get'], "", path+'?'+urlParams.toString());
            },

            updateParamsInUrl: function(params) {
                var path = window.location.origin + window.location.pathname
                var urlParams = new URLSearchParams();

                for (let key in params){
                    //skip iteration if value is empty or array is empty
                    if (params[key] == null || (Array.isArray(params[key]) && params[key].length == 0)) {
                        continue;
                    }

                    urlParams.set(key, params[key]);
                }

                window.history.pushState(params, "", path+'?'+urlParams.toString());
            },

            executePagination: function(page) {           
                if (page == null) {
                    return false;
                }     
                
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'page', value: page});  

                window.history.pushState(this.$store.getters['ListingSearch/get'], "", "?"+this.getPropertiesSearchParameters());
                
                this.updateParamInUrl('page', page);
                
                this.getProperties();
                document.querySelector('.section--property-cards-with-map-toggle').scrollIntoView({behavior: "smooth"});
            },

            getShowPropertyCount: function() {
                //if amount in cms is not empty and bigger then 0, use the CMS field
                if(!isNaN(this.acfFields.amount_to_show) && parseInt(this.acfFields.amount_to_show) > 0) {
                    return parseInt(this.acfFields.amount_to_show);
                }
                //else return amount specified in .env file
                else if (process.env.MIX_PROPERTY_PAGING_COUNT != null) {
                    return parseInt(process.env.MIX_PROPERTY_PAGING_COUNT);                
                }
                //in case it matches nothing, fallback to 20
                else return 20;
            },

            setPreFiltering(data) {
                for (var i = 0; i < data.length; i++) {
                    var this_filter = data[i];
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: this_filter.key, value: this_filter.value});          
                }
            },

            setAdditionalParameters(additionalParameters) {

                if(additionalParameters)
                {
                    Object.entries(additionalParameters).forEach(([key, value]) => {
                        this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: key, value: value}); 
                    });
                }
            },

            getPropertiesSearchParameters: function() {
                //not using URLSearchParams due to non IE support
                var urlParams = [];
                var criterias = this.$store.getters['ListingSearch/get'];
                
                for (let key in criterias){
                    if(criterias.hasOwnProperty(key)){

                        // if empty skip / do not include in url
                        if (criterias[key] == null || 
                        (Array.isArray(criterias[key]) && criterias[key].length == 0)) {
                            continue;
                        }

                        //if array join them up ex . 1,2,3,4, else just display value ex . 1 
                        var value = (Array.isArray(criterias[key])) ? criterias[key].join(',')
                                                                    : criterias[key];

                        urlParams.push(key + '=' + value);
                    }
                }
                //join all paras ex. a=1,2,3&b=4&c=5,6

                //add / overwrite the amount to show
                if (this.acfFields.amount_to_show != null) {
                    urlParams.push('Take='+this.acfFields.amount_to_show);
                }   
                
                return urlParams.join('&');
            },

            getProperties: function(criterias) {         
                this.isLoading = true;

                $('#map-modal').modal('hide')

                //assign this to that, so as to be accessibe in axios return
                var that = this;

                axios.get('/api/properties?'+that.getPropertiesSearchParameters())
                .then(function (response) {
                    that.setPagination(that.getShowPropertyCount(), response.data.data.TotalSearchResults);

                    that.properties = response.data.data.Properties;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);

                })
                .finally(function () {
                    that.isLoading = false;
                    // document.querySelector('.section--property-cards-with-map-toggle').scrollIntoView({behavior: "smooth"});
                });

                //get also the map data
                this.getMapData();
            },

            getMapData() {
                var that = this;

                axios.get('/api/map?'+that.getPropertiesSearchParameters())
                .then(function (response) {
                    that.setMapData(response.data.data.townsAndListings.data);
                    that.mapDataLoaded = true;

                    setTimeout(function() {
                        that.$root.$emit('update-map');
                    }, 1000);
                    
                })
                .catch(function (error) {

                })
            },

            setPagination(page_count, total_count) {
                var this_page = parseInt(this.$store.getters['ListingSearch/get']['page']);
                var this_count = this.getShowPropertyCount();
                var total_pages = Math.ceil(total_count / page_count);

                this.pagination = [];
                this.pagination.push({
                    text: '< prev',
                    page: (this_page > 1) ? this_page - 1 : null
                });

                for (var i = this_page - this.maxPaginationPrev; i < this_page; i++){
                    if (i < 1) {
                        continue;
                    }

                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                 this.pagination.push({
                    text: this_page,
                    page: this_page
                });

                for (var i = this_page + 1; i <= this_page + this.maxPaginationNext; i++){
                    if (i > total_pages) {
                        continue;
                    }
                    
                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                if ((this_page + this.maxPaginationNext) < total_pages) {
                    this.pagination.push({
                        text: '...',
                        page: null
                    });
                    this.pagination.push({
                        text: total_pages,
                        page: total_pages
                    });
                }

                this.pagination.push({
                    text: 'next >',
                    page: (this_page < total_pages) ? this_page + 1 : null
                });
                
                // maxPaginationNext
                // maxPaginationPrev

                // for (var i = 1; i <= Math.ceil(total_pages); i++){
                //     this.pagination.push({
                //         text: i,
                //         page: i
                //     });
                // }
            },

            setMapData(listings) {
                this.map_markers = listings;
            },            
        },

        beforeDestroy() {
            this.destroyHistoryBackButtonListener();            
        }
    }
</script>
