<template>
    <div class="section--saved-properties">
        <div class="my-profile--actions">
            <a href="/account/my-profile">
                <icon-nav-arrow-left></icon-nav-arrow-left>
                <span>{{ trans('account.back_to_my_profile') }}</span>
            </a>
        </div>

        <div class="mt-30">
            <h4 class="mb-0">{{ trans('account.saved_properties') }}</h4>
        </div>

        <loading-component v-if="isLoading"></loading-component>

        <div v-if="!isLoading">
            <div v-if="properties.length > 0">
                <div class="row justify-content-center justify-content-md-start">
                    <property-card-single v-for="property in properties" v-bind:key="property.MLS" :property="property"></property-card-single> 
                </div>   
            </div>
            <div v-else>
                <div class="mt-15">{{ trans('account.saved_properties_empty') }}</div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                properties: [],
                isLoading: true
            }
        },

        mounted() {
            this.getFavouritedProperties();
        },

        methods: {
            getFavouritedProperties: function() {
                this.isLoading = true;

                axios.get('/favourite/property')
                .then(response => {  
                    if (response.data.data != null) {
                        this.properties = response.data.data.Properties;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
    
</script>
