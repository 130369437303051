<template>
    <div class="section--link-pods">
        <div class="link-pods--title">{{ trans('blog.categories') }}</div>
        <loading-component v-if="loading"></loading-component> 
        <div class="link-pods--group">
            <a :href="'/'+postLink+'?categories='+category.id" class="link-pods--item" v-for="category in categories">
                <div v-html="category.name" class="item--excerpt link-arrow-sm"></div>
            </a>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['postType'],

        data: function() {
            return {
                categories: [],
                loading: true,
            }
        },
        
        mounted() {
            this.getCategories();
        },

        computed: {
            postLink() {
                return (this.postType == 'posts' || this.postType == null) ? 'blog' : this.postType.toLowerCase();  
            },
        },

        methods: {
            getCategories: function() {
                axios.get('/api/blogs/categories', {})
                .then(response => {    
                    this.categories = response.data.data.items.items;
                    this.loading = false;
                });
            },
        }
         
    }
</script>
