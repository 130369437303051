<template>
	<div class="section--property-similar-links col-12 py-50">
		<div class="section--row">
			<div class="property-similar-links--header">
				<h2>{{ trans('properties.related_links') }}</h2>
			</div>
		</div>
		<div class="section--row pt-30">
			<div class="property-similar-links--province-list">
				<a v-if="!hidePriceFromWeb" :href="getLink + 'ForSale=false&ForRent=false&PriceFrom=' + minPrice + '&PriceTo=' + maxPrice + '&ProvinceIds='+provinceId">{{ trans('properties.between') }} &euro;{{ minPriceFormatted }} {{ trans('properties.and') }} &euro;{{ maxPriceFormatted }} {{ trans('properties.in') }} {{ provinceName }}</a>
				<a :href="getLink + 'ForSale=true&ForRent=false&ProvinceIds='+provinceId">{{ trans('properties.for_sale_in') }} {{ provinceName }}</a>
				<a :href="getLink + 'ForSale=false&ForRent=true&ProvinceIds='+provinceId">{{ trans('properties.for_rent__to_let_in') }} {{ provinceName }}</a>
			</div>
			<div class="property-similar-links--towns-list">
				<a v-if="!hidePriceFromWeb" :href="getLink + 'ForSale=false&ForRent=false&PriceFrom=' + minPrice + '&PriceTo=' + maxPrice + '&TownIds='+townId">{{ trans('properties.between') }} &euro;{{ minPriceFormatted }} {{ trans('properties.and') }} &euro;{{ maxPriceFormatted }} {{ trans('properties.in') }} {{ townName }}</a>
				<a :href="getLink + 'ForSale=true&ForRent=false&TownIds='+townId">{{ trans('properties.for_sale_in') }} {{ townName }}</a>
				<a :href="getLink + 'ForSale=false&ForRent=true&TownIds='+townId">{{ trans('properties.for_rent__to_let_in') }} {{ townName }}</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['hidePriceFromWeb', 'price', 'townName', 'provinceName', 'transactionType'],

		data() {
			return {
				locale: document.getElementsByTagName('html')[0].getAttribute('lang').replace('_', '-'), 
				townId: '',
				provinceId: '',
				priceInt: parseInt(this.price, 10),
			}
		},

		mounted() {
			//town Id & Province Id are not returned from API, so we need to get them from storage
			let towns = this.$store.getters['staticTypes/StaticTypes']("Towns");
			let provinces = this.$store.getters['staticTypes/StaticTypes']("Provinces");
			
			var that = this;
			towns.reduce(function(filtered, option) {
				if (option.Name == that.townName) {
					that.townId = option.Id;
				}
			}, []);

			provinces.reduce(function(filtered, option) {
				if (option.Name == that.provinceName) {
					that.provinceId = option.Id;
				}
			}, []);
		},

		methods: {
	    roundPrice: function (price) {
	      if (this.transactionType == "For Rent") {
	      	return price;
	      }

	      return Math.round(price/10000) * 10000;
	    }
	  },

		computed: {
			minPrice() {
				let price = this.priceInt - ((this.priceInt * 10) / 100);
				// return Math.round(price/1000) * 1000;
				return this.roundPrice(price);
			},
			maxPrice() {
				let price = this.priceInt + ((this.priceInt * 10) / 100);
				// return Math.round(price/1000) * 1000;
				return this.roundPrice(price);
			},
			minPriceFormatted() {
				let price = this.priceInt - ((this.priceInt * 10) / 100);
				return (this.roundPrice(price)).toLocaleString(this.locale, { minimumFractionDigits: 0 });
			},
			maxPriceFormatted() {
				let price = this.priceInt + ((this.priceInt * 10) / 100);
				return (this.roundPrice(price)).toLocaleString(this.locale, { minimumFractionDigits: 0 });
			},
			getLink() {
				return window.location.origin + '/listings/?';
			},
		}
	}
</script>