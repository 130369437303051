<template>
    <div class="section--blog-card">
        <div>

            <div class="bg-cover bg-absolute bg-center" :style="'background-image: url('+getBackgroundImageOfPost(article)+')'"></div>

            <div class="bg-absolute bg-linear-gradient-default"></div>

            <a :href="'/' + type + '/' + article.slug">
                <div class="blog-card--content">
                    <div>
                        <div class="blog-card--title">
                            <h4 class="text-white" v-html="article.title.rendered"></h4>
                        </div>
                        <div class="blog-card--link">
                            <span class="text-white link-arrow-sm font-weight-bold" v-html="trans('blog.read_article')"></span>
                        </div>
                    </div>
                </div>
            </a>

        </div>
    </div>

</template>

<script>

    export default {
        props: ['article', 'type'],

        methods: {
            getBackgroundImageOfPost(post) {
                if (post['_embedded'] != undefined && 
                    post['_embedded']['wp:featuredmedia'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details'] != undefined  && 
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return '';
                }
            },

            limitTextWordSize(input, length) {
                let words = input.split(' ');

                if (words.length > length) {
                    words = words.slice(0, length);

                    return (words.join(' ')) + ' ...';
                }
                else {
                    return input;
                }
            },

        }
    }

</script>