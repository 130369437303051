<template>
    <div class="contract-type--fields">
        <div>
        	<label>{{ trans('field-search-containers.contract_types') }}</label>
        	<select v-model="ContractTypeId">
        		<option :value="null">{{ trans('field-search-containers.default_no_value_contract_type') }}</option>
        		<option v-for="(contractType) in staticContractTypes" :value="contractType.Id">{{ contractType.Name }}</option>
        	</select>
        </div>
    </div>
</template>

<script>
    export default {

        data: function() {
            return {
                staticContractTypes: null,
            }
        },

        created() {
            this.load();
        },

        methods: {

            load: function() {
                this.staticContractTypes = this.$store.getters['staticTypes/StaticTypes']("ContractTypes");
            },
            
        },

        computed: {
            ContractTypeId: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['ContractTypeId'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ContractTypeId', value: val});                     
                }
            },
        }
    }
</script>
