<template>
    <div class="section--blog-pods-pagination" :style="getSectionBgColour">
        
        <div class="component-structure--title" v-if="acfFields.title">
            <div><h5 v-html="acfFields.title"></h5></div>
        </div>

        <div class="component-structure--heading">    
            <div class="component-structure--heading-header" v-if="acfFields.header">
                <h2 class="h1" v-html="acfFields.header"></h2>
            </div>
            <div class="component-structure--heading-text" v-if="acfFields.text" v-html="acfFields.text"></div>
        </div>

        <loading-component v-if="loading"></loading-component>
        <div class="component-structure--pods">
            <div class="blog-pods--column">
                <div class="blog-pods--row">

                    <div class="post--item-container" v-for="post in posts">
                        <a :href="'/'+acfFields.post_type.value+'/'+post.slug">
                            <div class="post--item">
                                <div>
                                    <div class="item--type" v-html="acfFields.post_type.label"></div>
                                    <div class="item--title">
                                        <h5 v-html="post.title.rendered"></h5>
                                    </div>
                                    <div class="item--excerpt" v-html="post.excerpt.rendered"></div>
                                </div>
                                <div class="item--link">
                                    <div class="link-arrow-sm">{{ trans('blog.read_more') }}</div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div v-if="emptyResponse" class="post--no-posts">
                        <p>{{ trans('blog.no_posts_found') }}</p>
                    </div>

                </div>
                <div class="section--pagination" v-if="pagination && total_pages > 1">
                    <div v-for="page in pagination">  
                        <div v-if="page.page != null" class="pagination--button" :class="{ 'pagination--selected' : page.page == pageLoad }" v-on:click="executePagination(page.page)"><p>{{ page.text }}</p></div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
    export default {
        props: ['acfFields'],
        //props: ['page', 'show', 'type', 'filter', 'order'],

        data: function() {
            return {
                posts: [],
                pageLoad: 1,
                total_pages: 0,
                pagination: [],
                maxPaginationNext: 3,
                maxPaginationPrev: 3,
                loading: true,
                emptyResponse: false
            }
        },
        
        mounted() {
            this.pageLoad = parseInt(this.getUrlParameter('page'));
            if (isNaN(this.pageLoad)) {
                this.pageLoad = 1;
            }

            this.loadBlogs();
        },

        computed: {
            getSectionBgColour() {
                return (this.acfFields.background_colour) ? 'background-color:'+this.acfFields.background_colour+';'
                : '';
            },
        },

        methods: {

            executePagination(page) {
                var queryString = [];

                if (this.getUrlParameter('categories') != "" && this.getUrlParameter('categories') != null) {
                    queryString.push('categories='+this.getUrlParameter('categories'))
                }

                queryString.push('page='+page)
                
                var path = window.location.origin+window.location.pathname + '?'+queryString.join('&');

                window.location.replace(path);

                return false;
            },


            getUrlParameter(name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            },

            setPagination(page_count, total_count, this_page) {

                var total_pages = Math.ceil(total_count / page_count);

                this.pagination = [];
                this.pagination.push({
                    text: '< prev',
                    page: (this_page > 1) ? this_page - 1 : null
                });

                for (var i = this_page - this.maxPaginationPrev; i < this_page; i++){
                    if (i < 1) {
                        continue;
                    }

                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                 this.pagination.push({
                    text: this_page,
                    page: this_page
                });

                for (var i = this_page + 1; i <= this_page + this.maxPaginationNext; i++){
                    if (i > total_pages) {
                        continue;
                    }
                    
                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                if ((this_page + this.maxPaginationNext) < total_pages) {
                    this.pagination.push({
                        text: '...',
                        page: null
                    });
                    this.pagination.push({
                        text: total_pages,
                        page: total_pages
                    });
                }

                this.pagination.push({
                    text: 'next >',
                    page: (this_page < total_pages) ? this_page + 1 : null
                });
                
                // maxPaginationNext
                // maxPaginationPrev

                // for (var i = 1; i <= Math.ceil(total_pages); i++){
                //     this.pagination.push({
                //         text: i,
                //         page: i
                //     });
                // }
            },

            loadMore: function() {
                //increment page
                //this.pageLoad++;

                this.loadBlogs();
            },

            loadBlogs: function() {
                var filter = {
                    'categories': this.acfFields.categories
                };
                this.getPosts(parseInt(this.acfFields.amount), this.acfFields.post_type.value, filter, this.pageLoad);
            },

            getPosts: function(show, type, filter, pageLoad) {
                this.loading = true;
                var that = this;
                var options = this.buildApiCriteriaApiStructure(filter);
                
                axios.get('/api/'+type.toLowerCase(), {
                    params: {
                    "page": pageLoad,
                    'show': show,
                    'options': options
                    }
                })
                .then(response => {
                    if (response.data.data.items.data.length > 0) {
                        that.emptyResponse = false;
                        
                        this.posts = this.posts = response.data.data.items.data;
                        this.total_pages = response.data.data.headers['total_pages'];
                        this.setPagination(show, response.data.data.headers['total_posts'], pageLoad);
                    }
                    else {
                        that.emptyResponse = true;
                    }
                    //this.posts = this.posts.concat(response.data.data.items.data);
                    
                })
                .catch(function (error) {
                    that.emptyResponse = true;
                })
                .then(function () {
                    that.loading = false;
                });  ;
            },

            buildApiCriteriaApiStructure: function(filter) {
                // [0] = order by(date,title,etc..) [1] = order(asc,desc)
                var response = {
                    '_embed': true
                };

                if (filter != undefined) {
                    var categories = [];

                    //if filter is string, parse it as JSON object
                    if (typeof filter == 'string') {
                        categories = JSON.parse(filter);
                    }
                    else {
                        categories = filter;
                    }

                    response.categories = categories;
                }
                
                return response;
            },

            getBackgroundImageOfPost(post) {
                if (post['_embedded'] != undefined && 
                    post['_embedded']['wp:featuredmedia'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details'] != undefined  && 
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return process.env.MIX_DEFAULT_IMAGE;
                }
            },

            formatDate(date) {
                var d = new Date(date);
                var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                var mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

                return `${da}-${mo}-${ye}`;
            }
        }
         
    }
</script>
