/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
// require('./custom');

//for ie support polyfill
import 'core-js/features/url-search-params';

window.Vue = require('vue');

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import { Modal, VoerroModal } from '@voerro/vue-modal';
Vue.component('modal', Modal);
window.VoerroModal = VoerroModal;

// import Slick from 'vue-slick';
// import 'slick-carousel/slick/slick.css';
// Vue.component('slick', Slick)

import Flickity from 'vue-flickity'
Vue.component('flickity', Flickity)

//https://github.com/drewjbartlett/vue-switches
import Switches from 'vue-switches';
Vue.component('switches', Switches)

import SweetModal from 'sweet-modal-vue/src/plugin.js'
Vue.use(SweetModal)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueSlider from 'vue-slider-component'
Vue.component('VueSlider', VueSlider)

Vue.config.silent = true;

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fab);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon)

import store from './store.js';

//load all vue components
// const req = require.context('./Components/', true, /\.(js|vue)$/i);
// req.keys().map(key => {
//     let name = key.match(/[^/]+$/)[0]
//     name = name.substr(0, name.lastIndexOf('.'));
//     return Vue.component(name, req(key).default)
// });

const req = require.context('./', true, /\.vue$/i)
req.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], req(key).default))



//inject helpers file to vue components as a plugin
import helpers from './helpers.js';
const plugin = {
    install(Vue, options) {
        Vue.prototype.$helpers = helpers; // we use $ because it's the Vue convention
    }
};
Vue.use(plugin);

//inject translation

import lodash from 'lodash';
const _ = lodash;
Vue.prototype.trans = string => _.get(window.i18n, string);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//load some specific stuff in sequence so that we are sure the child components have them

//first load lookups 
store.dispatch("staticTypes/loadLookups").then(() => {
    //then set if auth user & load user data for faster processing later on
    store.dispatch('user/setAuthUser', window.token).then(() => {
        //then load vue
        const app = new Vue({
            el: '#app',
            store
        });
    });
});

