<template>
    <div class="section--my-profile-change-password">
        <form>
            <div>
                <label>{{ trans('account.old_password') }}</label>
                <input class="form-control" type="password" id="old-password" v-model="user.old_password">
            </div>
            <div>
                <label>{{ trans('account.password') }}</label>
                <input class="form-control" type="password" id="password" v-model="user.password">
            </div>
            <div>
                <label>{{ trans('account.confirm_password') }}</label>
                <input class="form-control" type="password" id="confirm-password" v-model="user.confirm_password">
            </div>
            <div class="mt-15">
                <a class="btn btn-quaternary" @click="saveUserPassword">{{ trans('account.save') }}</a>
                <a class="btn btn-light" @click="emitCloseComponent">{{ trans('account.cancel') }}</a>
            </div>
        </form>
    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                user: {
                    old_password: '',
                    password: '',
                    confirm_password: ''
                }
            }
        },

        methods: {
            saveUserPassword: function() {
                axios.put('/user/password', {
                    old_password: this.user.old_password,
                    password: this.user.password,
                    password_confirmation: this.user.confirm_password
                })
                .then(response => {    
                    //close dialog
                    this.emitCloseComponent();
                });
            },
            emitCloseComponent: function() {
                this.$emit('closePasswordChangeComponent');
            }
        }
    }
    
</script>
