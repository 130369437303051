<template>
  <a class="text-decoration-none" :href="'/branch/'+office.ShortLink">
    <div class="agent-preview-card">
      <div class="preview-card--image">
        <div class="preview-card--image-bg" :style="'background-image: url('+office.Photos[1].ImageURL+')'"></div>
        <!-- <img class="img-fluid" :src="office.Photos[2].ImageURL" :alt="office.Name"> -->
      </div>
      <div class="preview-card--content">
        <div class="preview-card--content-name">{{ office.Name }}</div>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: ['office'],
  }
</script>


