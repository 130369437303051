<template>
    <div class="property-types-macro-types--fields">
        <div class="section--fields--header"
            data-toggle="collapse" 
            data-target="#property-types-macro-types--fields-collapse" 
            aria-expanded="false" 
            aria-controls="property-types-macro-types--fields-collapse">
            {{ trans('field-search-containers.select_property_type') }}
        </div>

        <div class="collapse mt-15" :class="(CloseByDefault == true) ? '' : 'show'" id="property-types-macro-types--fields-collapse">
            <div>
                <div v-for="macro in MacroTypes" v-bind:key="macro.Id">
                    <property-types-by-macro-type :ApplyButton="false" :MacroType="macro"></property-types-by-macro-type>
                </div>
            </div>

            <div v-if="ApplyButton" class="fields-column-display--buttons">
                <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
                <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'CloseByDefault'],

        data: function() {
            return {
                MacroTypes: null,
            }
        },

        computed: {
            ListingType() {
                return this.$store.getters['ListingSearch/get']['Residential'];
            }
        },

        watch: {
            ListingType(oldValue, newValue) {
                //reset current data and reload
                // this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedPropertyTypes', value:[]});              

                this.load(this.$store.getters['ListingSearch/get']['Residential']);         
            }
        },

        created() {
            this.load(this.$store.getters['ListingSearch/get']['Residential']);
        },

        methods: {

            load: function(filter) {
                //this logic is based since the residential & commercial are 
                //both rows in lookups and seperate specific properties in listing, 
                //hence cannot map them correctly.
                if (filter == true) filter = 1; //assumption if true, get residential
                else filter = 2; //assumption if false, get commercial
                

                //FilterResidentialCommercial filter accordingly
                this.MacroTypes = this.$store.getters['staticTypes/StaticTypes']("PropertyMacroTypes");

                if (filter !== undefined) {
                    function filterByListing(data) {
                        return data.ListingTypeId == filter;
                    }
                    
                    this.MacroTypes = this.MacroTypes.filter(filterByListing)
                }
            },
            
        }
    }
</script>
