<template>
    <div class="section--listings-search">
        <form class="row" @submit.prevent="(event) => { handleFormSubmit(event) }">            
            <div class="d-flex flex-lg-wrap justify-content-center justify-content-lg-start section--listings-search--filters">
                <div class="mr-10 my-5 d-none d-lg-flex provinces">
                    <provinces-container :minimal="true" text="Provinces"></provinces-container>
                </div>
                
                <div class="mr-10 my-5 d-none d-lg-flex towns">
                    <towns-container :minimal="true" text="Towns"></towns-container>
                </div>

                <div class="mr-10 my-5 d-none d-lg-flex zones">
                    <zones-container :minimal="true" text="Areas"></zones-container>
                </div>
                
                <div class="mr-10 my-5 d-none d-lg-flex property-types">
                    <property-types-container :minimal="true" text="Property Types"></property-types-container>
                </div>

                <div class="mr-10 my-5 d-none d-lg-flex price-range">
                    <price-range-container :minimal="true"></price-range-container>
                </div>
                
                <div class="mr-10 my-5 d-none d-lg-flex bedroom">
                    <bedroom-container :minimal="true"></bedroom-container>
                </div>

                <div class="mr-10 my-5 d-none d-lg-flex internal-features">
                    <internal-features-container :minimal="true" text="Internal Features"></internal-features-container>
                </div>

                <div class="mr-10 my-5 d-none d-lg-flex external-features">
                    <external-features-container :minimal="true" text="External Features"></external-features-container>
                </div>

                <modal id="advanced-search-container" v-cloak>
                    <template slot="title"><h4 class="mb-0">{{ trans('properties.advanced_filters') }}</h4></template>
                    <div slot="body">
                        <advanced-search-container @closeModal="closeModal"></advanced-search-container>
                    </div>
                    <div slot="footer">
                        <advanced-search-container-buttons @closeModal="closeModal"></advanced-search-container-buttons>
                    </div>
                </modal>
    <!--
                <advanced-search-container v-if="showAdvancedFilter" @closeModal="showAdvancedFilter = !showAdvancedFilter"></advanced-search-container>
    -->
                <button type="button" class="btn btn-light dropdown-toggle btn-sm mr-10 my-5" @click="handleAdvancedFiltersClick">{{ trans('properties.more_filters') }}</button>            
                <!-- <button type="button" class="btn btn-light dropdown-toggle btn-sm mr-10 my-5 d-lg-none" @click="handleAdvancedFiltersClick">{{ trans('properties.filter_properties') }}B</button> -->

                <property-sort class="my-5"></property-sort>         

                <!--<button class="btn-primary">{{ trans('properties.search') }}</button>-->
            </div>
            <div class="d-flex flex-wrap flex-row flex-lg-column mt-30 mt-md-0 section--listings-search--actions">
                <div>
                    <button type="button" class="btn small btn-link" @click="clear">{{ trans('field-search-containers.clear') }}</button>
                    <button type="button" class="btn btn-primary" @click="$root.$emit('search-submit')">{{ trans('field-search-containers.apply') }}</button>
                </div>
                <div v-if="$store.getters['user/isLoggedIn']" class="d-none d-lg-block mt-20">
                    <a class="save-search"  @click="$store.dispatch('ListingSearch/saveSearch')">
                        <icon-save />&nbsp;{{ trans('properties.save_search') }}
                    </a>
                </div>
            </div>
        </form>

        
    </div>
</template>

<style>
</style>

<script>
    export default {

        props: ['preFilter'],

        data: function(){
            return {
                showAdvancedFilter: false
            }
        },

        mounted() {
            this.setPreFiltering(this.preFilter);

            this.searchSubmitListener();
        },

        methods: {
            searchSubmitListener: function() {
                var that = this;
                this.$root.$on('search-submit', that.handleFormSubmit);
            },

            //emit selection to parent
            handleFormSubmit: function(event) {
                // Reset page number with each submit when pressed from the form button
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'page', value: 1});
                var criterias = this.$store.getters['ListingSearch/get'];

                this.$emit('searchSubmit', {criterias});
            },

            clear() {
                this.$store.dispatch('ListingSearch/clearSearchCriteria');
                this.$root.$emit('clear-all-fields');
            },

            closeModal: function() {
                this.showAdvancedFilter = false;
                VoerroModal.hide('advanced-search-container');
            },

            handleAdvancedFiltersClick: function() {
                //open advanced filters popup
                this.showAdvancedFilter = !this.showAdvancedFilter;

                if (this.showAdvancedFilter) {
                    VoerroModal.show('advanced-search-container');
                }
                else {
                    VoerroModal.hide('advanced-search-container');
                }
            },

            setPreFiltering(data) {
                for (var i = 0; i < data.length; i++) {
                    var this_filter = data[i];
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: this_filter.key, value: this_filter.value});          
                }
            }
        }
        
    }
</script>
