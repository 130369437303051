<template>
    <div class="external-features--fields">
        <div class="section--fields--header"
            data-toggle="collapse" 
            data-target="#external-features--fields-collapse" 
            aria-expanded="false" 
            aria-controls="external-features--fields-collapse">
            {{ trans('field-search-containers.select_external_features') }}
        </div>

        <div class="collapse" :class="(CloseByDefault == true) ? '' : 'show'" id="external-features--fields-collapse">
            <div class="container-fluid">
                <div class="section--fields-column-display">
                    <div v-bind:class="limitDisplay ? 'limit-display show-8': ''" v-for="feature in ExternalFeatures" v-bind:key="feature.Id">
                        <label class="checkbox-label">
                            <input type="checkbox" v-model="ComponentSelectedExternalFeatures" :value="feature.Id" />
                            <span class="checkbox-text">{{ feature.Name }}</span>  
                        </label>     
                    </div>
                </div>
            </div>

            <div class="fields-column-display--show-all" v-if="ExternalFeatures.length > 16">
                <a @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
            </div>

            <div v-if="ApplyButton" class="fields-column-display--buttons">
                <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
                <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'CloseByDefault'],

        data: function() {
            return {
                ExternalFeatures: null,
                //ComponentSelectedExternalFeatures: this.SelectedExternalFeatures,
                limitDisplay: true
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.ExternalFeatures.length + " external features"
                                            : "Show Less";
            },

            ComponentSelectedExternalFeatures: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['SelectedExternalFeatures']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedExternalFeatures', value:val});                    
                }
            }
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('ComponentSelectedExternalFeatures', (val) => {
                //     this.$emit('update:SelectedExternalFeatures', { val });                
                // }, { immediate: false });
            }
        },

        created() {
            this.load();
        },

        methods: {

            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedExternalFeatures', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                this.ExternalFeatures = this.$store.getters['staticTypes/StaticTypes']("ExternalFeatures");
            },
            
        }
    }
</script>
