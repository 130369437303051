<template>
    <div :style="getSectionBgColour" v-if="blog">
        <loading-component v-if="isLoading"></loading-component>
        <div class="section--latest-blog desktop" v-if="!isLoading">
            <div class="latest-blog--desktop">
                <div class="latest-blog--content">
                    <div>
                        <h5 v-html="acfFields.title"></h5>
                    </div>
                    
                    <div v-if="blog">
                        <h2 class="h1" v-html="blog.title.rendered"></h2>
                        <p v-html="blog.excerpt.rendered"></p>
                    </div>

                    <div class="content--button-link" v-if="blog">
                        <div> <a class="btn btn-primary" :href="'/blog/'+blog.slug" :target="(acfFields.button_link.open_in_new_tab == true) ? '__blank' : ''">{{ acfFields.button_link.button_text }}</a> </div>
                    </div>
                </div>

                <div class="latest-blog--image" v-if="blog">
                    <img class="rounded shadow" :src="getBackgroundImageOfPost" />
                </div>
            </div>
        </div>

        <div class="section--latest-blog mobile" v-if="!isLoading">
            <div class="latest-blog--mobile">

                <div class="latest-blog--content" :style="'background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url('+getBackgroundImageOfPost+')'">
                    <div class="content--box">
                        <div>
                            <h5 v-html="acfFields.title"></h5>
                        </div>
                        
                        <div class="mt-15" v-if="blog">
                            <h2 class="text-white" v-html="blog.title.rendered"></h2>
                        </div>

                        <div class="content--button-link" v-if="blog">
                            <div> <a class="link-arrow-sm" :href="'/blog/'+blog.slug" :target="(acfFields.button_link.open_in_new_tab == true) ? '__blank' : ''">{{ acfFields.button_link.button_text }}</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['acfFields'],

        data: function() {
            return {
                blog: null,
                isLoading: false,
            }
        },

        mounted() {
            this.getLatestBlog();
        },

        computed: {
            getBackgroundImageOfPost() {

                if (this.blog != null &&
                    this.blog['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return this.blog['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return process.env.MIX_DEFAULT_IMAGE;
                }
            },

            getSectionBgColour() {
                return (this.acfFields.background_colour) ? 'background-color:'+this.acfFields.background_colour+';'
                : '';
            },
        },

        methods: {
            getLatestBlog: function() {
                var that = this;
                this.isLoading = true;

                axios.get('/api/blogs/latest/')
                .then(response => {
                    this.blog = response.data.data;
                })
                .finally(function () {
                    that.isLoading = false;
                });
            },
        },

    }
</script>
