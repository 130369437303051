<template>
    <div class="section--account">
        <div class="account--container">
            <div class="account--row">
                <div class="account--main-menu--column">
                    <div class="account--main-menu--container">
                        <div class="main-menu--top">
                            <a href="#" @click="handleLinkClick" :class="{'is-selected': selected_component.primary == 'my-profile'}" 
                                        ref="my-profile" data-component="my-profile" data-sub-component="my-profile-edit">{{ trans('account.my_profile') }}</a>
                            <a href="#" @click="handleLinkClick" :class="{'is-selected': selected_component.primary == 'saved-properties'}" 
                                        ref="saved-properties" data-component="saved-properties">{{ trans('account.saved_properties') }}</A>
                            <a href="#" @click="handleLinkClick" :class="{'is-selected': selected_component.primary == 'saved-searches'}" 
                                        ref="saved-searches" data-component="saved-searches">{{ trans('account.saved_searches') }}</a>
                            <a href="#" @click="handleLinkClick" :class="{'is-selected': selected_component.primary == 'preferred-agents'}" 
                                        ref="preferred-agents" data-component="preferred-agents">{{ trans('account.preferred_agents') }}</a>
                            <a href="#" @click="handleLinkClick" :class="{'is-selected': selected_component.primary == 'email-notifications'}" 
                                        ref="email-notifications" data-component="email-notifications">{{ trans('account.email_notifications') }}</a>
                            <a href="/logout">{{ trans('header.logout') }}</a>
                        </div>
                        <div class="main-menu--bottom">
                            <a href="#" @click="deleteAccountConfirmation">{{ trans('account.delete_account') }}</a>
                        </div>
                    </div>
                </div>
                <div class="account--components">
                    <component v-bind:is="selected_component.primary" 
                                :data-sub-component="selected_component.secondary" 
                                @viewSubComponent="loadSubComponent"  
                                @viewMainComponent="loadMainComponent">
                    </component>
                </div>
            </div>
        </div>

        <sweet-modal ref="delete_confirmation" title="Alert!">
            <p>
                {{ trans('account.are_you_sure_delete_account') }} 
            </p>
            <p>
                {{ trans('account.this_action_cannot_be_reverted') }}
            </p>
            
            <a slot="button" class="btn btn-link" @click="deleteAccountConfirmationCancel">{{ trans('account.cancel') }}</a>
            <a slot="button" class="btn btn-primary" @click="deleteAccountConfirmationConfirm">{{ trans('account.confirm') }}</a>
        </sweet-modal>

    </div>
</template>

<script>
    export default {
        props: ['page'],

        data() {
            return {
                selected_component: {
                    primary: null,
                    secondary: null
                }
            }
        },

        mounted() {
            if (this.page != undefined) {
                this.selected_component = {
                    primary: this.page,
                    secondary: this.$refs[this.page].getAttribute('data-sub-component')
                };
            }
        },

        methods: {
            handleLinkClick: function(event) {
                let data_component = {
                    primary: event.currentTarget.getAttribute('data-component'),
                    secondary: event.currentTarget.getAttribute('data-sub-component')
                }

                if (data_component != this.selected_component) {
                    this.selected_component = data_component;
                }

                return false;
            },

            loadSubComponent: function() {
                let data_component = this.selected_component.secondary;
                this.selected_component.secondary = this.selected_component.primary;
                this.selected_component.primary = data_component;
            },

            loadMainComponent: function() {
                let data_component = this.selected_component.primary;
                this.selected_component.primary = this.selected_component.secondary;
                this.selected_component.secondary = data_component;
            },

            deleteAccountConfirmation: function() {
                this.$refs.delete_confirmation.open();
            },

            deleteAccountConfirmationConfirm: function() {
                this.confirmDeleteAccount();
            },

            deleteAccountConfirmationCancel: function() {
                this.$refs.delete_confirmation.close();
            },

            confirmDeleteAccount: function() {
                axios.delete('/user')
                .then(response => {    
                    location.reload();
                });
            }
        }
    }    
</script>
