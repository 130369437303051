<template>
    <div v-if="Object.keys(ShareCompareProperties).length > 0" class="section--share-compare-component">
        <div class="share-compare-component--row">
            <div class="share-compare-component--column">
                <div class="share-compare-component--content shadow-default" :class="open == true ? 'open' : ''">
                    <div v-if="Object.keys(ShareCompareProperties).length > 0" class="total">
                        <span>{{ Object.keys(ShareCompareProperties).length }}</span>
                    </div>
                    <div class="share-compare-component--header" @click="open=!open">
                        <div class="leading-tight">
                            {{ trans('misc.share_compare_up_to_4_properties') }}
                        </div>
                        <div>
                            <a>
                                <div v-if="!open">
                                    <icon-arrow-up />
                                    <span class="small font-weight-bold ml-5 text-uppercase text-light">{{ trans('misc.show') }}</span>
                                </div>
                                <div v-else>
                                    <icon-arrow-down />
                                    <span class="small font-weight-bold ml-5 text-uppercase">{{ trans('misc.hide') }}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="share-compare-component--properties" v-if="open">
                        <div class="share-compare--properties">
                            <div v-for="image, mls in ShareCompareProperties">
                                <div class="share-compare--properties-image-container">
                                    <img :src="image" />
                                    <a class="delete" @click="removePropertyFromShareCompare(mls)"><icon-close /></a>
                                </div>
                            </div>
                            <div v-if="JSON.stringify(ShareCompareProperties) === JSON.stringify({})">
                                {{ trans('misc.no_properties_selected') }}
                            </div>
                        </div>
                        <div v-if="JSON.stringify(ShareCompareProperties) !== JSON.stringify({})" class="share-compare--buttons">
                            <a class="d-flex align-items-center" @click="share()"><icon-share /><span class="ml-5">{{ trans('misc.share') }}</span></a>
                            <div>
                                <a v-if="Object.keys(ShareCompareProperties).length > 1" class="d-flex align-items-center" :href="CompareUrl" target="_blank"><icon-compare /><span class="ml-5">{{ trans('misc.compare') }} </span></a>
                                <a v-else class="d-flex align-items-center btn btn-link disabled" target="_blank"><icon-compare /><span class="ml-5">{{ trans('misc.compare') }} </span></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <sweet-modal ref="share_compare" v-on:close="loadModal=false">
            <share-compare-contact-form v-if="loadModal" :link="PropertiesLink"></share-compare-contact-form>
        </sweet-modal>
        

    </div>
</template>

<script>
    export default {
        data() {
            return {
                open: false,
                count: 0,
                link: '',
                loadModal: false
            }
        },
        methods: {
            removePropertyFromShareCompare(mls) {
                this.$store.dispatch('shareCompare/removeProperty', mls);
            },

            share() {
                //this var is used to lazy load the contact form component, 
                //so it gets the link automatically on clicking the share, 
                //and not on component load
                this.loadModal = true;

                this.$refs.share_compare.open();
            },

            compare() {
                var data = this.$store.getters['shareCompare/Properties'];
                axios.get('/api/listings/compare', {
                    params: {
                        properties: Object.keys(data)
                    }
                })
                .then(response => {  
                });
            }
        },

        computed: {
            
            PropertiesLink() {
                var data = this.$store.getters['shareCompare/Properties'];
                data = Object.keys(data);

                var parameters = [];
                for(var key in data) {
                    parameters.push(data[key]);
                }

                var url = process.env.MIX_APP_URL + '/listings?Favourites=' + parameters.join(',');
                
                return url;
            },

            ShareCompareProperties: {
                get() {
                    return this.$store.getters['shareCompare/Properties'];
                }
            },

            CompareUrl() {
                var data = this.$store.getters['shareCompare/Properties'];
                data = Object.keys(data);
                var url = '/listings/compare?';

                var parameters = [];
                for(var key in data) {
                    parameters.push('properties[]='+data[key]);
                }

                url += parameters.join('&');

                return url;
            }
        }
    }
</script>