<template> 
    <div>
        <div class="pod--container">
            <loading-component v-if="loading"></loading-component>
            <div class="pod" v-for="post in posts">   
                <div>            
                    <a :href="'/'+type+'/'+post.slug">
                        <div class="pod--content" :style="'background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 58%, rgba(0, 0, 0, 0.5)), url('+getBackgroundImageOfPost(post)+')'">
                            <p>{{ post.title.rendered }}</p>
                        </div>
                    </a>
                </div>      
            </div>
        </div>
        <div class="pod--load-more" v-if="page < total_pages">
            <button @click="loadMore()" class="btn btn-primary">{{ trans('blog.load_more') }}</button>
        </div>  
    </div>
</template>

<script>
    export default {
        props: ['show', 'type', 'filter', 'order'],

        data: function() {
            return {
                posts: [],
                page: 1,
                total_pages: 0,
                loading: true,
            }
        },
        
        mounted() {
            this.loadBlogs();
        },

        methods: {

            loadMore: function() {
                //increment page
                this.page++;

                this.loadBlogs();
            },

            loadBlogs: function() {
                this.getBlogs(this.show, this.type, this.filter, this.order, this.page);
            },

            getBlogs: function(show, type, filter, order, page) {

                var options = this.buildApiCriteriaApiStructure(filter, order);
                this.loading = true;
                axios.get('/api/'+type, {
                    params: {
                    "page": page,
                    'show': show,
                    'options': options
                    }
                })
                .then(response => {
                    this.loading = false;
                    this.posts = this.posts.concat(response.data.data.items.data);
                    this.total_pages = response.data.data.headers['total_pages'];
                });
            },

            buildApiCriteriaApiStructure: function(filter, order) {
                // [0] = order by(date,title,etc..) [1] = order(asc,desc)
                var order_group = order.split(' '); 

                var categories = [];

                //if filter is string, parse it as JSON object
                if (typeof filter == 'string') {
                    categories = JSON.parse(filter);
                }
                else {
                    categories = filter;
                }

                return {
                    'orderby': (order_group[0]).toLowerCase(),
                    'order': order_group[1].toLowerCase(),
                    'categories': categories
                }
            },

            getBackgroundImageOfPost(post) {
                if (post['_embedded'] != undefined && 
                    post['_embedded']['wp:featuredmedia'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details'] != undefined  && 
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return process.env.MIX_DEFAULT_IMAGE;
                }
                
            }
        }
         
    }
</script>
