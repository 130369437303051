<template>
    <div class="section--latest-sold-properties" :style="getSectionBgColour">

        <div class="component-structure--heading">    
            <div class="component-structure--heading-header">
                <h2 class="h1">{{ acfFields.header }}</h2>
            </div>
        </div>

        <div class="component-structure--carousel latest-sold-properties--carousel">
            <div>
                <div>
                    <properties-carousel show="4" :criterias="criteria" :carousel-watch-css="true"></properties-carousel>
                </div>
            </div>
        </div>

        <div>
            <a href="" :target="(acfFields.button_link.open_in_new_tab == true) ? '__blank' : ''">{{ acfFields.button_link.button_text }}</a>
        </div>

    </div>

</template>

<script>

    export default {
        props: ['acfFields'],

        data: function() {
            return {
                properties: null,
                isLoading: false,
                criteria: {
                    ListingStatusId: 6,
                }
            }
        },

        computed: {
            getSectionBgColour() {
                return (this.acfFields.background_colour) ? 'background-color:'+this.acfFields.background_colour+';'
                : '';
            },
        },

        mounted() {
            this.getLatestSoldProperties();
        },

        methods: {
            getLatestSoldProperties: function() {
                var that = this;
                this.isLoading = true;
                
                axios.get('/api/properties?Take=3')
                .then(response => {
                    this.properties = response.data.data.Properties;
                })
                .finally(function () {
                    that.isLoading = false;
                });
            },
        },

    }
</script>
