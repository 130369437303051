<template>

    <div class="section--blog-pods">
        <loading-component v-if="loading"></loading-component>
        <div class="blog-pods--column">
            <div class="blog-pods--row">

                <div class="post--item-container" v-for="post in posts">
                    <a :href="'/'+type+'/'+post.slug">
                        <div class="post--item">
                            <div>
                                <div class="item--type" v-html="type"></div>
                                <div class="item--title">
                                    <h5 v-html="post.title.rendered"></h5>
                                </div>
                                <div class="item--excerpt" v-html="post.excerpt.rendered"></div>
                            </div>
                            <div class="item--link">
                                <div class="link-arrow-sm">{{ trans('blog.read_more') }}</div>
                            </div>
                        </div>
                    </a>
                </div>

                <div v-if="emptyResponse" class="post--no-posts">
                    <p>{{ trans('blog.no_posts_found') }}</p>
                </div>

            </div>
        </div>
    </div>



<!--     <div class="section--blog-pods">     
        <div class="blog-pods--container"> 
            <loading-component v-if="loading"></loading-component>
            <div class="blog-pods--container--row"> 
                <div class="blog-pods--row--column"> 
                    <div class="blog-pods--row--container">   
                        <div class="blog-pods--row">   
                            <div v-if="emptyResponse" class="post--no-posts">
                                <p>{{ trans('blog.no_posts_found') }}</p>
                            </div>
                            <div v-for="post in posts" class="post--item-container">    
                                <div class="post--item"> 
                                    <a :href="'/'+type+'/'+post.slug" class="item--content">
                                        <div class="item--type">
                                            <p>{{ type }}</p>
                                        </div>
                                        <div class="item--text--content">
                                            <div class="item--title">
                                                <h5 v-html="post.title.rendered"></h5>
                                            </div>
                                            <div class="item--excerpt">
                                                <p v-html="post.excerpt.rendered"></p>
                                            </div>
                                            <div class="item--link">
                                                {{ trans('blog.read_more') }}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
    export default {
        
        props: ['show', 'type', 'filter', 'order', 'taxonomySlug'],

        data: function() {
            return {
                posts: [],
                loading: true,
                emptyResponse: false
            }
        },
        
        mounted() {
            this.loadBlogs();
        },

        methods: {

            loadMore: function() {

                this.loadBlogs();
            },

            loadBlogs: function() {
                this.getBlogs(this.show, this.type, this.filter, this.order);
            },

            getBlogs: function(show, type, filter) {
                this.loading = true;
                var that = this;
                var options = this.buildApiCriteriaApiStructure(filter);
                
                axios.get('/api/'+type.toLowerCase(), {
                    params: {
                    'show': show,
                    'options': options
                    }
                })
                .then(response => {
                    if (response.data.data.items.data.length > 0) {
                        that.emptyResponse = false;
                        
                        this.posts = this.posts = response.data.data.items.data;
                    }
                    else {
                        that.emptyResponse = true;
                    }
                    //this.posts = this.posts.concat(response.data.data.items.data);
                    
                })
                .catch(function (error) {
                    that.emptyResponse = true;
                })
                .then(function () {
                    that.loading = false;
                });
            },

            buildApiCriteriaApiStructure: function(filter, order) {
                // [0] = order by(date,title,etc..) [1] = order(asc,desc)
                var response = {
                    '_embed': true
                };

                if (order != undefined) {
                    var order_group = order.split(' '); 
                    response.orderby = (order_group[0]).toLowerCase();
                    response.order = (order_group[1]).toLowerCase();
                }

                if ((filter != undefined) && (filter != "false")) {
                    var categories = [];

                    //if filter is string, parse it as JSON object
                    if (typeof filter == 'string') {
                        categories = JSON.parse(filter);
                    }
                    else {
                        categories = filter;
                    }

                    if (this.type == "guides") {
                        response.guide_category = categories;
                    } else {
                        response.categories = categories;
                    }

                }
                
                return response;
            },
        }
         
    }
</script>
