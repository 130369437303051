<template>
  <div class="fields-column-display--buttons">
    <button type="button" @click="clear" class="no-style text-secondary font-weight-bold mr-25">Clear</button>
    <button type="button" @click="apply" class="link-arrow-sm no-style text-primary font-weight-bold">Apply all</button>
  </div>
      

</template>

<script>
  export default {
    
    methods: {

      clear() {
        this.$store.dispatch('ListingSearch/clearSearchCriteria');
        this.$emit('clear-all-fields');
      },

      apply() {
        // this.$emit('search-submit');
        this.$emit('closeModal');
        
        var url_params = this.$helpers.convertObjectInQueryParameters(this.$store.getters['ListingSearch/get']);

        //redirect to properties with params attaced
        window.location.href = '/listings?'+url_params;
      },

    },

  }
</script>



 