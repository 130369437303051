<template>
    <div class="section--advanced-search-container">

      <div class="advanced-search-container--criteria px-30">
        <mls :ApplyButton="false"></mls>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <ai-residential-commercial :enable-ai-search="false" :show-residential="1" :show-commercial="1" :ApplyButton="false"></ai-residential-commercial >
      </div>

      <div class="advanced-search-container--criteria px-30">
        <sale-to-let-advanced :show-buy="true" :show-rent="true" :ApplyButton="false"></sale-to-let-advanced>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <div class="d-flex flex-column flex-md-row">
          <div class="mr-md-40"><bedroom :header="trans('properties.bedrooms')"></bedroom></div>
          <bathroom :header="trans('properties.bathrooms')"></bathroom>
        </div>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <price-range-dropdown :ApplyButton="false" :showSliderRange="true"></price-range-dropdown>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <square-metres></square-metres>
      </div>

      <!-- <div class="advanced-search-container--criteria criteria--columns">
        <div>
          <div>
            <sale-to-let :ApplyButton="false"></sale-to-let>
          </div>
          <div>
            <price-range-dropdown :ApplyButton="false" :showSliderRange="true"></price-range-dropdown>
          </div>
          <div>
            <bedroom :header="trans('properties.bedrooms')"></bedroom>
            <bathroom :header="trans('properties.bathrooms')"></bathroom>
          </div>
        </div>
      </div> -->

      <div class="advanced-search-container--criteria px-30">
        <towns-by-provinces :apply-button="false" :close-by-default="closeByDefault"></towns-by-provinces>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <property-types-by-macro-types :apply-button="false" :close-by-default="closeByDefault"></property-types-by-macro-types>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <internal-features :apply-button="false" :close-by-default="closeByDefault"></internal-features>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <external-features :apply-button="false" :close-by-default="closeByDefault"></external-features>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <internal-inventories :apply-button="false" :close-by-default="closeByDefault"></internal-inventories>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <marketing-categories :apply-button="false" :close-by-default="closeByDefault"></marketing-categories>
      </div>

      <div class="advanced-search-container--criteria px-30">
        <marketing-statuses :apply-button="false" :close-by-default="closeByDefault"></marketing-statuses>
      </div>
      
    </div>

</template>

<script>
  export default {

    data() {
      return {
        closeByDefault: false,
      }
    },

    mounted() {
      var mobileRes = ["xs", "sm"];
      
      if (mobileRes.includes(this.$helpers.deviceSize())) {
        this.closeByDefault = true;
      }
    },
    
    methods: {
      updatePrices() {

      },

      clear() {
        this.$store.dispatch('ListingSearch/clearSearchCriteria');
        this.$root.$emit('clear-all-fields');
      },

      apply() {
        this.$root.$emit('search-submit');
        this.$emit('closeModal');
      },

    },

  }
</script>



 