<template>
    <div class="towns-by-province">
    
        <div class="section--fields--sub-header-checkbox ">
            <div class="sub-header--checkbox">
                <label class="checkbox-label" >
                    <input type="checkbox" v-model="selectAllSelected" @change="actionAllSelected" />
                    <span class="checkbox-text">{{ Province.Name }}</span>  
                </label>  
            </div>
            <div class="sub-header--arrow">
                <a @click="showMore = !showMore">
                    <icon-arrow-down v-if="!showMore" />
                    <icon-arrow-up v-else />
                </a>
            </div>
        </div>

        <div v-if="showMore" class="container">

            <div class="section--fields-column-display row">
                
                <div v-bind:class="limitDisplay ? 'limit-display show-8': ''" v-for="town in towns" v-bind:key="town.Id">
                    <label class="checkbox-label" @click="selectAllSelected = false">
                        <input type="checkbox" v-model="componentTownIds" :value="town.Id" />
                        <span class="checkbox-text">{{ town.Name }}</span>  
                    </label>      
                    
                </div>
            
            </div>

            <div v-if="towns.length > 8" class="fields-column-display--show-all row">
                <a @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
            </div>

            <div class="section--fields--header row small mt-20"><strong>{{ trans('field-search-containers.select_zones') }}</strong></div>

            <div class="section--fields-column-display row">

                <div v-for="zone in this.zones" v-bind:key="zone.Id">
                    <label class="checkbox-label">
                        <input type="checkbox" v-model="componentZoneIds" :value="zone.Id" />
                        <span class="checkbox-text">{{ zone.Name }}</span>  
                    </label>
                </div>

            </div>

            <div v-if="ApplyButton" class="fields-column-display--buttons row">
                <a v-on:click="clearClick" class="text-primary">{{ trans('field-search-containers.clear') }}</a>
                <a v-on:click="applyClick" class="text-secondary link-arrow-sm">{{ trans('field-search-containers.apply') }}</a>
            </div>
        
        </div>
    
    </div>
</template>

<script>
export default {
    props: ['ApplyButton', 'Province'],

    data: function() {
        return {
            towns: null,
            selectedTowns: [],
            zones: [],
            limitDisplay: true,
            id: null,
            showMore: false,
            selectAllSelected: false
        }
    },

    computed: {
        ShowAllText: function() {
            return (this.limitDisplay) ? "Show all " + this.towns.length + " towns"
                                        : "Show Less";
        },

        componentTownIds: {
            get () {                    
                return this.$store.getters['ListingSearch/get']['TownIds']
            },
            set (val) {    
                this.$store.dispatch('ListingSearch/updateSearchCriteria', { key: 'TownIds', value:val });                    
                this.selectAllSelected = false;
                this.selectedTowns = val;
            }
        },

        componentZoneIds: {
            get () {                    
                return this.$store.getters['ListingSearch/get']['ZoneIds']
            },
            set (val) {    
                this.$store.dispatch('ListingSearch/updateSearchCriteria', { key: 'ZoneIds', value:val });                    
            }
        },
    },

    methods: {
        handleMainContainerClear: function() {
            this.selectAllSelected = false;
        },

        actionAllSelected: function() {
            
            if(this.selectAllSelected) {
                this.selectAll();
            }
            else {
                this.unSelectAll();
            }

        },

        selectAll: function() {
            //clone array
            var current_list = [].concat(this.$store.getters['ListingSearch/get']['TownIds']);

            for (var key in this.towns) {
                if (!this.towns.hasOwnProperty(key)) continue;

                var obj = this.towns[key];

                //if town is not in current_list, push to current_list
                if (current_list.indexOf(obj.Id) == -1) {
                    current_list.push(obj.Id);
                }
            }

            //update main store
            this.$store.dispatch('ListingSearch/updateSearchCriteria', { key: 'TownIds', value:current_list });  

            this.selectAllSelected = true;

        },

        unSelectAll: function() {
            var current_list = [].concat(this.$store.getters['ListingSearch/get']['TownIds']);

            for (var key in this.towns) {
                if (!this.towns.hasOwnProperty(key)) continue;

                var obj = this.towns[key];

                //if town is not in current_list, push to current_list
                if (current_list.indexOf(obj.Id) > -1) {
                    current_list.splice(current_list.indexOf(obj.Id));
                }
            }

            //update main store
            this.$store.dispatch('ListingSearch/updateSearchCriteria', { key: 'TownIds', value:current_list });  

            this.selectAllSelected = false;

        },

        clearClick: function() {
            this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value:[]});       
            this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ZoneIds', value:[]});    
            this.selectedTowns = this.towns;   
        },

        applyClick: function() {
            this.$root.$emit('search-submit');
        },

        // townsOfProvince(province) {
        //     var that = this;
        //     return this.towns
        // },

        load: function() {
            var that = this;
            var provinceId = this.Province.Id;

            this.towns = this.$store.getters['staticTypes/StaticTypes']("Towns").filter(function(element) {
                return that.filterByListing(element, provinceId);
            });

            this.selectedTowns = this.towns;
        },

        filterByListing: function(data, province) {
            return data.ProvinceId == province;
        }
        
    },

    mounted() {
        var that = this;
        this.$root.$on('clear-all-fields', that.handleMainContainerClear);
    },

    created() {
        this.load();
    },

    watch: {
        selectedTowns(updatedData) {
            this.zones = [];

            if(!updatedData || updatedData.length == 0) {
                this.selectedTowns = this.towns;
            }

            this.$store.getters['staticTypes/StaticTypes']("Zones").forEach(zone => {
                this.selectedTowns.forEach(town => {
                    if( zone.TownId == town.Id || zone.TownId == town ) { this.zones.push(zone); }
                });
            });
        }
    }
}
</script>
