<template>
	<div class="section--agents-search">

		<div class="agents-search--container">
			<div class="agents-search--row py-35">
					<div class="search-for-agent">
						
						<div v-if="filterName && submittedSearch" class="pb-15">
							<h4>{{ trans('components.searches_for') }} '{{ filterName }}'</h4>
						</div>

						<div>
							<form method="GET" action="/agent-search">
									<div class="input-group">
										<input name="s" v-model="filterName" type="text" class="form-control" :placeholder="trans('components.search_by')" aria-label="Search" aria-describedby="basic-addon2">
										<div class="input-group-append">
											<button class="btn btn-primary" type="button" @click="searchAgents()">
												{{ trans('components.search') }}
											</button>
										</div>
									</div>
							</form>
						</div>
					</div>

<!-- 					<div class="agent-sort">
						<button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuSort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="dropdown-toggle--selected">{{ getSortBy }}</span>
						</button>
						<div class="dropdown-menu dropdown-menu-filter dropdown-menu-right" aria-labelledby="dropdownMenuSort">
							<div class="" v-for="sort in agentSort" :key="sort.Id" @click="setSortBy(sort)">
									{{ sort.Name }}
							</div>
						</div>
					</div>  -->
			</div>

			<div class="divider-section"></div>

			<div class="agents-search--row elements-after-another mobile-sticky">
				<div class="col d-flex flex-wrap">
					<div class="branch-filter mr-15 mb-15">
						<button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuBranches" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="dropdown-toggle--selected">{{ getFilterBranch }}</span>
						</button>
						<div class="dropdown-menu dropdown-menu-filter enlarge-mobile" aria-labelledby="dropdownMenuBranches">
							<div class="" v-for="branch in branches" :key="branch.OfficeId" @click="setFilterBranch(branch.Name)">
									{{ branch.Name }}
							</div>
						</div>
					</div>
					
					<div class="language-filter mr-15 mb-15">
						<button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuLanguages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="dropdown-toggle--selected">{{ getFilterLanguage }}</span>
						</button>
						<div class="dropdown-menu dropdown-menu-filter" aria-labelledby="dropdownMenuLanguages">
							<div class="" v-for="language in languages" :key="language.Id" @click="setFilterLanguage(language.Name)">
									{{ language.Name }}
							</div>
						</div>
					</div>

					<div class="sorting-filter mr-15 mb-15">
						<button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuSort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="dropdown-toggle--selected">{{ getSortBy }}</span>
						</button>
						<div class="dropdown-menu dropdown-menu-filter" aria-labelledby="dropdownMenuSort">
							<div class="" v-for="sort in agentSort" :key="sort.Id" @click="setSortBy(sort)">
									{{ sort.Name }}
							</div>
						</div>
					</div>

					<div hidden class="sorting-filter">
			            <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuOfficeType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			              <span class="dropdown-toggle--selected">{{ getOfficeTypeId }}</span>
			            </button>
			            <div class="dropdown-menu dropdown-menu-filter dropdown-menu-right" aria-labelledby="dropdownMenuOfficeType">
			              <div class="" v-for="type in officeType" :key="type.Id" @click="setOfficeTypeId(type)">
			                  {{ type.Name }}
			              </div>
			            </div>
			          </div>
				</div>
			</div>

			<div class="agents-search--row">
				<loading-component v-if="isLoading"></loading-component>

				<div class="col-12" v-if="agents.length > 0">
					<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
						<div class="col mb-15 mb-lg-30" v-for="agent in agents" >
							<agent-card :key="agent.AssociateId" :agent="agent"></agent-card>
						</div>
					</div>   
				</div>
				
				<div v-if="agents.length == 0 && isLoading == false" class="preferred-agents--row align-items-center justify-content-center d-flex">
						<div class="col-12 text-center py-50">
							<span class="h4">No agents found</span>
						</div>
				</div>	
				
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['term', 'prePopAgents'],

		data() {
			return {
				agentSort: [
					{
						Id: 'NameAsc',
						Name: 'Name Ascending'
					},
					{
						Id: 'NameDesc',
						Name: 'Name Descending'
					}
				],
				branches: [],
				languages: [],
				sortBy: null,
				sortByShow: null,
				filterBranch: null,
				filterLanguage: null,
				filterName: '',
				agents: [],
				isLoading: false,
				submittedSearch: false,
		        officeType: [
		          {
		            Id: null,
		            Name: 'Any'
		          },
		          {
		            Id: '1',
		            Name: 'Letting'
		          },
		          {
		            Id: '2',
		            Name: 'Sales'
		          }
		        ],
		        officeTypeId: null,
		        officeTypeIdShow: null,
			}
		},

		mounted() {
			// this.selected = 
			this.getBranches();
			this.getLanguages();

			this.agents = this.prePopAgents;
			this.filterName = this.term;
		},

		computed: {
			getSortBy() {
				return this.sortByShow || 'Sort';
			},
			getFilterBranch() {
				return this.filterBranch || 'Branch';
			},
			getFilterLanguage() {
				return this.filterLanguage || 'Language';
			},
			getOfficeTypeId() {
				return this.officeTypeIdShow || 'Office Type';
			}
		},

		methods: {
			setSortBy(val) {
				this.sortBy = val.Id;
				this.sortByShow = val.Name;
				this.searchAgents();
			},

			setFilterBranch(val) {
				if(val == 'Any') {
					val = null;
				}

				this.filterBranch = val;
				this.searchAgents();
			},

			setFilterLanguage(val) {
				if(val == 'Any') {
					val = null;
				}

				this.filterLanguage = val;
				this.searchAgents();
			},

			setOfficeTypeId(val) {
				this.officeTypeId = val.Id;
				this.officeTypeIdShow = val.Name;
				this.searchAgents();
			},

			getBranches() {
				axios.get('/api/branches')
				.then(response => {
					this.isLoading = false;
					
					this.branches = [{
						OfficeId : null,
						Name: 'Any',
					}];

					this.branches = this.branches.concat(response.data.data);
				});
			},

			getLanguages() {
				this.languages = [
					{
						Id : null,
						Name: 'Any',
					},
					{
						Id : 'English',
						Name: this.trans('components.english'),
					},
					{
						Id : 'Maltese',
						Name: this.trans('components.maltese'),
					},
					{
						Id : 'French',
						Name: this.trans('components.french'),
					},
					{
						Id : 'German',
						Name: this.trans('components.german'),
					},
					{
						Id : 'Spanish',
						Name: this.trans('components.spanish'),
					},
				];
			},

			searchAgents() {
				this.isLoading = true;
				this.agents = [];
				this.submittedSearch = true;
				
				axios.get('/api/agents', {
					params: {
						sorting: this.sortBy,
						s: this.filterName,
						office: this.filterBranch,
						languages: (this.filterLanguage == null) ? this.filterLanguage
																			: [this.filterLanguage],
            			office_type_id: this.officeTypeId,
					}
				})
				.then(response => {
					this.isLoading = false;
					this.agents = response.data.data;
				});
			},
		},

	}
</script>