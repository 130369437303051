<template>
    <l-map :key="componentKey" ref="map" :zoom="zoom" :center="center" @zoomend="onZoom">  
        <l-tile-layer :url="url"></l-tile-layer>          
        
        <l-marker v-for="(marker, index) in markers" :key="marker.TownId" 
                    v-if="marker.Coordinates && marker.AmountOfListings > 0"
                    :lat-lng="marker.Coordinates" 
                    :icon="getMapIcon(marker.AmountOfListings)"
                    @click="filterByTown(marker.TownId)">
            <l-popup> 
                <span v-if="marker.link">
                    <a :href="marker.link">{{ marker.TownName }}</a>
                </span>
                <span v-else>
                    {{ marker.TownName }}
                </span>
            </l-popup>
        </l-marker>

        <l-geo-json :geojson="geojson"></l-geo-json>


    </l-map>
</template>

<style>
    .number-icon {
        background-color:rgba(224, 28, 47, 0.7);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px !important;
        width: 30px !important;
    }
</style>

<script>

    import { Icon }  from 'leaflet'
    import {LMap, LTileLayer, LMarker, LPopup, LGeoJson } from 'vue2-leaflet';

    Vue.component('l-map', LMap)
    Vue.component('l-tile-layer', LTileLayer)
    Vue.component('l-marker', LMarker)
    Vue.component('l-popup', LPopup)
    Vue.component('l-geo-json', LGeoJson)

    // this part resolve an issue where the markers would not appear
    delete Icon.Default.prototype._getIconUrl;

    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        props: ['markers'],
        data() {
            return {
                url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
                zoom: 11,
                center: [35.9281689, 14.359101], //centered malta .. ideally this is put as env variable
                componentKey: 1,
                geojson: {
                    "type": "Polygon",
                    "coordinates": []
                },
            };
        },

        watch:{
            markers (val) {
                this.updateMarkers();
            }
        },

        methods: {
            onZoom(ev) {
                // console.log(ev.target._zoom); //1 till 13/14 show provinces else show towns, 
            },
            forceRerender() {
                this.componentKey = this.componentKey + 1;
            },
            getMapIcon: function(amount) {
                if (amount == undefined) {
                    amount = '';
                }
                
                return L.divIcon({
                    className: "number-icon",
                    iconSize: [30, 30],
                    iconAnchor: [0, 0],
                    popupAnchor: [15, 0],
                    html: '<b>'+amount+'</b>'       
                });
            },

            invalidateSize() {
                LMap.invalidateSize(true);
            },

            filterByTown(townId) {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value: [townId]});                    
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'page', value: 1});                    
                this.$root.$emit('search-submit');
            },

            centerMapByMarkers() {

                var markersBounds = [];
                
                for(var i = 0; i < this.markers.length; i++) {
                    
                    let thisMarker = this.markers[i];
                    
                    if (thisMarker.Coordinates == null || thisMarker.AmountOfListings == 0) {
                        continue;
                    }
                    
                    markersBounds.push([thisMarker.Coordinates.lat, thisMarker.Coordinates.lon]);
                }

                if (markersBounds.length > 0) {
                    try {
                        // var bounds = this.$refs.map.mapObject.getBounds();

                        // This is needed to ensure map is not completely zoomed out on first load
                        this.$refs.map.mapObject.invalidateSize();

                        this.$refs.map.mapObject.flyToBounds(markersBounds, {'minZoom': 11, 'maxZoom': 13});
                    }
                    catch(e) {
                        console.log(e);
                    }
                }
            },

            updateMarkers() {

                let townsWithListings = this.markers.filter(element => {
                      return element.AmountOfListings !== 0;
                });

                let that = this;

                that.geojson.coordinates = [];

                // FOR MULTIPLE LOCALITIES
                townsWithListings.forEach(function(value) {
                    let townCoordinates = that.$store.getters['townBoundaries/townBoundaryById'](value.TownId)[0]['Boundary'];
                    townCoordinates = eval(townCoordinates);
                    if (townCoordinates) {
                        that.geojson.coordinates.push(townCoordinates);
                    }
                });

                // ONLY FOR A SINGLE LOCALITY
                // if (townsWithListings.length == 1) {

                    // axios.get('https://nominatim.openstreetmap.org/search.php?country=malta&city=Lija&polygon_geojson=1&format=jsonv2')
                    // .then(response => {
                    //     let townCoordinates = response.data[0] ? response.data[0].geojson.coordinates[0] : null;
                    //     console.log('REMOTE CALL START');
                    //     console.log(townCoordinates);
                    //     console.log('REMOTE CALL END');
                    //     if (townCoordinates) {
                    //         that.geojson.coordinates.push(townCoordinates);
                    //     }
                    // });
                // }

                that.centerMapByMarkers();
            }
        },
        mounted() {
            // this.updateMarkers();
            this.$store.dispatch("townBoundaries/loadTownBoundaries");
            var that = this;
            this.$root.$on('update-map', that.updateMarkers);
        }
    }
</script>