<template>
    <div class="language-change--dropdown">        
        <select v-on:change="languageChange($event)" v-model="selected">
            <option v-for="(value) in languages" :value="value.locale">{{ value.locale.replace('_', '-') }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                languages: Array,
                selected: String
            }
        },
        
        created() {
            this.getLanguages();
            // this.getCurrentLanguage();
        },
        
        mounted() {
            // this.getLanguages();
            this.getCurrentLanguage();
        },

        methods: {

            getCurrentLanguage: function() {
                this.selected = this.$cookies.get("lang");
            },

            getLanguages: function() {
                axios.get('/api/languages')
                .then(response => {
                    this.languages = response.data.languages;
                });
            },

            languageChange: function changeItem(event) {
                //set language cookie
                this.$cookies.set("lang",event.target.value, Infinity);

                // Also set direction cookie
                this.languages.forEach(language => {
                    if(language.locale == event.target.value)
                    {
                        this.$cookies.set("directory", language.textDirection, Infinity);
                    }
                });

                //reload page to retrieve content with desired language
                location.reload();
            }

        }
    }
</script>