<template>
    <div class='section--video-component'>

        <div class="video-component--row">

            <div class="video-component--content" @click="openVideo"> 
                
                <div :style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('+acfFields['first_frame_image']['url']+')'">
                    <div>
                        <div>
                            <h5 class="text-quaternary">{{ acfFields['title'] }}</h5>
                            <h3 class="text-white">{{ acfFields['header'] }}</h3>
                            <span class="play-button"></span>
                        </div>
                    </div>
                </div>

            </div>

            <sweet-modal :enable-mobile-fullscreen="true" ref="youtubevideo" v-on:close="stopVideo()">
                <youtube :video-id="videoId" ref="youtube"></youtube>
            </sweet-modal>
        
        </div>
    </div>
</template>

<script>
    export default {
        props: ['acfFields'],
        data() {
            return {
                videoId: this.acfFields['youtube_code']
            }
        },
        methods: {
            openVideo: function() {
                this.$refs.youtubevideo.open();
                this.playVideo();
            },
            playVideo() {
                this.player.playVideo();
            },
            stopVideo() {
                this.player.stopVideo();
            },
        },
        computed: {
            player() {
                return this.$refs.youtube.player
            }
        }
    }
</script>