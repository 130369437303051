<template>
    <div>
        <a @click="isOpen = !isOpen"><icon-nav-arrow-right /></a>
        <child-selection-component 
            v-if="isOpen" 
            :text="text" 
            :menus="subMenu" 
            @closeChildComponent="isOpen = false"
            @closeSelectionComponent="isOpen = false; $emit('closeSelectionComponent');">
            </child-selection-component>
    </div>
</template>

<script>
    export default {
        props: ['text', 'subMenu'],

        data() {
            return {
                isOpen: false
            }
        },
    }
</script>