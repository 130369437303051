<template>
    <div class="square-metres--fields">
        	<div class="row">
        		<div class="col-6 col-md-3 mb-30 mb-md-0">
        			<label>{{ trans('field-search-containers.minimum_internal_sqm') }}</label>
        			<select v-model="MinimumInternalSQM">
			            <option :value="null">{{ trans('field-search-containers.default_no_value_sqm') }}</option>
			            <option v-for="(squareMetre) in squareMetres" :value="squareMetre">{{ squareMetre }}</option>
			        </select>
        		</div>
        		<div class="col-6 col-md-3 mb-30 mb-md-0">
        			<label>{{ trans('field-search-containers.minimum_external_sqm') }}</label>
        			<select v-model="MinimumExternalSQM">
			            <option :value="null">{{ trans('field-search-containers.default_no_value_sqm') }}</option>
			            <option v-for="(squareMetre) in squareMetres" :value="squareMetre">{{ squareMetre }}</option>
			        </select>
        		</div>
        		<div class="col-6 col-md-3">
        			<label>{{ trans('field-search-containers.minimum_total_sqm') }}</label>
        			<select v-model="MinimumTotalSQM">
			            <option :value="null">{{ trans('field-search-containers.default_no_value_sqm') }}</option>
			            <option v-for="(squareMetre) in squareMetres" :value="squareMetre">{{ squareMetre }}</option>
			        </select>
        		</div>
        		<div class="col-6 col-md-3">
        			<label>{{ trans('field-search-containers.minimum_plot_size') }}</label>
        			<select v-model="MinimumPlotSize">
			            <option :value="null">{{ trans('field-search-containers.default_no_value_sqm') }}</option>
			            <option v-for="(squareMetre) in squareMetres" :value="squareMetre">{{ squareMetre }}</option>
			        </select>
        		</div>
        	</div>

    </div>
</template>

<script>
    export default {

        data: function() {
            return {
                squareMetres: [
                	10,
                	20,
                	30,
                	40,
                	50,
                	60,
                	70,
                	80,
                	90,
                	100,
                	150,
                	200,
                	250,
                	300,
                	350,
                	400,
                	450,
                	500,
                	550,
                	600,
                	650,
                	700,
                	750,
                	800,
                	850,
                	900,
                	950,
                	1000
                ]
            }
        },

        computed: {
            MinimumInternalSQM: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['MinimumInternalSQM'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumInternalSQM', value: val});                     
                }
            },
            MinimumExternalSQM: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['MinimumExternalSQM'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumExternalSQM', value: val});                     
                }
            },
            MinimumTotalSQM: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['MinimumTotalSQM'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumTotalSQM', value: val});                     
                }
            },
            MinimumPlotSize: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['MinimumPlotSize'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumPlotSize', value: val});                     
                }
            },
        },
    }
</script>
