<template>
    <div v-if="tags.length">
        <h4 class="mb-15">Tags</h4>
        <div>
            <a v-for="(tag, index) in tags" :href="'/' + slugBase + '?tags='+tag.id">
              {{tag.name}}<span v-if="index != tags.length - 1">, </span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['tagIds', 'slugBase'],

        data: function() {
            return {
                tags: [],
            }
        },

        mounted() {
            if (this.tagIds) {
                this.getTags();
            }
        },

        methods: {         
            getTags: function() {
              axios.get('/api/tags', {
                params: {
                  'include': this.tagIds.join(',')
                }
              })
              .then(response => {
                this.tags = response.data.data.items.items;
              })
              .finally(function () {
                // that.isLoading = false;
              });
            },
        }
    }
</script>
