<template>
    <div>

        <div class="dropdown">
            <button class="btn btn-light dropdown-toggle"  :class="buttonMinimal" type="button" id="dropdownMenuButton-bedrooms" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="dropdown-toggle--selected">{{ trans('field-search-containers.bedrooms') }}</span>
            </button>
            <div class="dropdown-menu dropdown-small keep-open-on-click" aria-labelledby="dropdownMenuButton-bedrooms">
                <bedroom :ApplyButton="true"></bedroom>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['minimal'],

        computed: {
            buttonMinimal() {
                if(this.minimal == true) {
                    return 'btn-sm';
                }
                else {
                    return 'btn-lg';
                }
            },
        }
    }
</script>
