const state = {
    Properties: JSON.parse(localStorage.getItem("ShareCompare")) || {},
    Loaded: false
};

const getters = {
    Properties: state => state.Properties,
    IsPropertyInList: state => index => (state.Properties[index] == undefined) ? false : true,
    GetPropertiesCount() {
        var count = 0;
        for (var properties in state.Properties) {
            count++;
        }
        return count;
    }
};

const mutations = {
    addProperty(state, { property, getters }) {
        if (getters.GetPropertiesCount < 4) {
            Vue.set(state.Properties, property.mls, property.image);

            localStorage.setItem("ShareCompare", JSON.stringify(state.Properties));
        }
        else {
            throw new Error('Cannot exceed count of 4 Listings');
        }
    },

    removeProperty(state, mls) {
        Vue.delete(state.Properties, mls);

        localStorage.setItem("ShareCompare", JSON.stringify(state.Properties));
    },
};

const actions = {

    addProperty({ state, commit, getters }, property) {
        commit('addProperty', { property, getters });
    },

    removeProperty({ state, commit }, propertyMls) {
        commit('removeProperty', propertyMls);
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};