<template>
    <div class="contract-type--fields mt-10 mt-md-0">
        <div>
        	<label>{{ trans('field-search-containers.chance_of_availability_types') }}</label>
        	<select v-model="ChanceOfAvailabilityTypeId">
        		<option :value="null">{{ trans('field-search-containers.default_no_value_chance_of_availability_type') }}</option>
        		<option v-for="(ChanceOfAvailabilityType) in staticChanceOfAvailabilityTypes" :value="ChanceOfAvailabilityType.Id">{{ ChanceOfAvailabilityType.Name }}</option>
        	</select>
        </div>
    </div>
</template>

<script>
    export default {

        data: function() {
            return {
                staticChanceOfAvailabilityTypes: null,
            }
        },

        created() {
            this.load();
        },

        methods: {

            load: function() {
                this.staticChanceOfAvailabilityTypes = this.$store.getters['staticTypes/StaticTypes']("ChanceOfAvailabilityTypes");
            },
            
        },

        computed: {
            ChanceOfAvailabilityTypeId: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['ChanceOfAvailabilityTypeId'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ChanceOfAvailabilityTypeId', value: val});                     
                }
            },
        }
    }
</script>
