<template>
    <div class="towns-by-provinces--fields">
        <div class="section--fields--header" 
            data-toggle="collapse" 
            data-target="#towns-by-provinces--fields-collapse" 
            aria-expanded="false" 
            aria-controls="towns-by-provinces--fields-collapse">
            {{ trans('field-search-containers.select_location') }}
        </div>

        <div class="collapse mt-15" :class="(CloseByDefault == true) ? '' : 'show'" id="towns-by-provinces--fields-collapse">
            <div>
                
                <div v-for="province in provinces" v-bind:key="province.Id">
                    <towns-by-province :ApplyButton="false" :Province="province"></towns-by-province>
                </div>
            
            </div>

            <div v-if="ApplyButton" class="fields-column-display--buttons">
                <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
                <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'CloseByDefault'],

        data: function() {
            return {
                provinces: null,
            }
        },

        created() {
            this.load();
        },

        methods: {

            load: function() {
                this.provinces = this.$store.getters['staticTypes/StaticTypes']("Provinces");
            },
            
        }
    }
</script>
