<template>
    <div class="section--similar-properties col-12">
        
        <div class="component-structure--title">
            <div>
                <h5>{{ trans('properties.related') }} </h5>
            </div>
        </div>        

        <div class="component-structure--heading">    
            <div class="component-structure--heading-header">
                <h2 class="h1" v-html="trans('properties.other_properties_you_may_like')"></h2>
            </div>

            <div class="component-structure--heading-button">
                <a v-if="link" class="btn btn-primary" :href="'/listings?'+link">{{ trans('properties.view_all') }}</a>
            </div>
        </div>

        <loading-component v-if="loading"></loading-component>

        <div v-if="!loading" class="component-structure--carousel">
            <flickity v-if="properties.length" @init="onInit" class="flickity" ref="flickity" :options="carouselSettings">
                <property-card-single class="carousel-cell" v-for="property in properties" v-bind:key="property.MLS" :property="property"></property-card-single> 
            </flickity> 
            <div v-else>
                <div class="text-center">
                    <p>There are currently no similar properties to show</p>
                </div>
            </div>
        </div>

        <div class="component-structure--mobile-view-all">
            <div>
                <a v-if="link" class="btn btn-primary" :href="'/listings?'+link">{{ trans('properties.view_all') }}</a>
            </div>
        </div>
        
    </div>
    
</template>

<script>
    export default {
        props: ['show', 'mls'],

        data: function() {
            return {
                properties: [],
                carouselSettings: {
                    imagesLoaded: true,
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'left',
                    wrapAround: true,
                    freeScroll: false,
                    autoPlay: false,
                },
                link: '',
                loading: true,
            }
        },

        mounted() {        
            this.getSimilarProperties(this.mls, this.show);
        },

        methods: {
            onInit() {
                var that = this;
                this.$refs.flickity.once('settle', function () {
                    that.$refs.flickity.resize();
                });
            },

            getSimilarProperties: function(mls, show) {
                this.loading = true;

                axios.get('/api/properties/'+mls+'/similar', {
                    Params: {
                        show: show
                    }
                })
                .then(response => {   
                    this.link = this.$helpers.convertObjectInQueryParameters(response.data.data.Criteria);
                    this.properties = response.data.data.Properties;
                    if (this.properties.length > 4)  {
                        this.carouselSettings.autoPlay  = true;
                    }
                })
                .finally(() => {
                   this.loading = false;
                });
            }
        }         
    }
</script>


