<template>
    <property-card-single v-if="forceSmall || property.ContractType.toLowerCase() !== 'priority' || resolution == 'xs' || resolution == 'sm'" :property="property" :favourite="favourite" />
    <property-card-double v-else :property="property" :favourite="favourite" />
</template>



<script>
    export default {
        props: {
            property: Object,
            favourite: {
                type: Boolean,
                default: true
            },
            forceSmall: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                resolution: '',
            }
        },

        methods: {
            updateResolution() {
                this.resolution = this.$helpers.deviceSize();
            }
        },

        mounted() {
            this.resolution = this.$helpers.deviceSize();
            window.addEventListener('resize', this.updateResolution);
        },
    }
</script>
