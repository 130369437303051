<template>
    <div class="section--child-selection-component">
        <div class="child-selection-component--header">
            <a class='header--back-button' @click="$emit('closeChildComponent')"> 
                <span><icon-nav-arrow-left /> {{ text }}</span>
            </a>
            <a class='header--close-button' @click="$emit('closeSelectionComponent')">
                <icon-close></icon-close>
            </a>
        </div>

        <nav class="child-selection-component--contents">  
            <div class="child-row--items" v-for="item in menuData">
                <div class="child-contents--item">
                    <div>
                        <a :href="item.url" :class="item.classes.join(' ')">
                            {{ item.title }}
                        </a>            
                    </div>
                </div>
                <div class="child-contents--item--sub-items">
                    <div v-for="subItem in item.children">
                        <!-- <a :href="subItem.url" :class="subItem.classes.join(' ')"> -->
                        <a :href="subItem.url" v-bind:class="{ 'has-children': subItem.children.length }">
                            {{ subItem.title }}
                        </a>
                        <ul v-if="subItem.children.length" class="list-unstyled">
                            <li v-for="subItem in subItem.children">
                                <a :href="subItem.url" :class="subItem.classes.join(' ')">{{ subItem.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>     
        </nav>
    </div>
</template>

<script>
    export default {
        props: ['text', 'menus'],

        data() {
            return {
                'menuData': this.menus
            }
        },

        mounted() {
        }

    }

</script>