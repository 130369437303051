<template>
    <div>

        <div class="dropdown">
            <button class="btn btn-light dropdown-toggle"  :class="buttonMinimal" type="button" id="dropdownMenuButton-internal-features" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="dropdown-toggle--selected">{{ getFirstSelection }}</span> <span class="dropdown-toggle--count">{{ getSelectedInternalFeaturesDataLength }}</span>
            </button>
            <div class="dropdown-menu dropdown-large keep-open-on-click" aria-labelledby="dropdownMenuButton-internal-features">
                <internal-features :ApplyButton="true"></internal-features>
            </div>
        </div> 

    </div>
</template>

<script>
    export default {
        props: ['minimal', 'text'],

        data: function() {
            return {
                InternalFeatures: [],
                Selected: null,
                DisplayText: ''
            }
        },

        mounted() {
            this.InternalFeatures = this.$store.getters['staticTypes/StaticTypes']("InternalFeatures");

            this.DisplayText = this.text ?? 'select an internal features';
        },

        computed: {
            buttonMinimal() {
                if(this.minimal == true) {
                    return 'btn-sm';
                }
                else {
                    return 'btn-lg';
                }
            },

            getSelectedInternalFeaturesDataLength() {
                if (Array.isArray(this.$store.getters['ListingSearch/get']['SelectedInternalFeatures'])) {
                    var length = this.$store.getters['ListingSearch/get']['SelectedInternalFeatures'].length;
                    return (length > 1) ? '+' + length : length;
                }
                else {
                    return 0;
                }
            },

            getFirstSelection() {
                this.Selected = (this.$store.getters['ListingSearch/get']['SelectedInternalFeatures'])[0]
                var filtered = (this.InternalFeatures.filter(this.filterByListing))[0];
                
                return (filtered == undefined) ? this.DisplayText : filtered.Name;
            }
        },

        methods: {
            filterByListing: function(data) {
                return data.Id == this.Selected;
            }
        }
    }
</script>
