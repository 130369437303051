const state = {
  townBoundaries: {},
};

const getters = {
  townBoundaryById: (state) => (id) => {
    return state.TownBoundaries.Towns.filter(townBoundary => townBoundary.Id == id)
  }
};

const actions = {

  //sync call, load before continue
  async loadTownBoundaries({ dispatch, commit }) {

    if (localStorage.getItem('TownBoundaries') !== null) {
      var townBoundaries = JSON.parse(localStorage.getItem('TownBoundaries'));

      var current_time = Date.now();

      //if storage is expired, reload
      if (townBoundaries.timestamp < current_time) {
        await dispatch('getTownBoundariesFromApi');
      }
      else {
        //retrieve state from localStorage
        commit('setTownBoundaries', townBoundaries)
      }
    }
    else {
      //items not in storage, retrieve.
      await dispatch('getTownBoundariesFromApi');
    }
  },

  getTownBoundariesFromApi({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/towns')
        .then(function (response) {
          commit('setTownBoundaries', response.data.data);
          commit('setTownBoundariesInLocalStorage', response.data.data);
          
          //resolve promise
          resolve();
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {

        });
    });
  }
};

const mutations = {
  setTownBoundaries(state, value) {
    state.TownBoundaries = value;
  },

  setTownBoundariesInLocalStorage(state, value) {
    var expires = (24 * 60 * 60) * 1000;  // default: milliseconds for 1 day
    //since local storage does not expire, implement manual expiration.
    value.timestamp = Date.now() + expires;
    localStorage.setItem("TownBoundaries", JSON.stringify(value));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};