<template>
    <div>
        <div class="section--fields-column-display">
            <div v-bind:class="limitDisplay ? 'limit-display show-16': ''" v-for="inventory in ExternalInventories" v-bind:key="inventory.Id">    
                <label class="checkbox-label">
                    <input type="checkbox" v-model="ComponentSelectedExternalInventories" :value="inventory.Id" />
                    <span class="checkbox-text">{{ inventory.Name }}</span>  
                </label>     
            </div>
        </div>

        <div class="fields-column-display--show-all">
            <a v-if="ExternalInventories.length > 16" @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
        </div>

        <div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton'],

        data: function() {
            return {
                ExternalInventories: null,
                //ComponentSelectedExternalInventories: this.SelectedExternalInventories,
                limitDisplay: true
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.ExternalInventories.length + " external inventories"
                                            : "Show Less";
            },

            ComponentSelectedExternalInventories: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['SelectedExternalInventories']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedExternalInventories', value:val});                    
                }
            }
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('ComponentSelectedExternalInventories', (val) => {
                //     this.$emit('update:SelectedExternalInventories', { val });                
                // }, { immediate: false });
            }
        },

        created() {
            this.load();
        },

        methods: {
            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedExternalInventories', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                this.ExternalInventories = this.$store.getters['staticTypes/StaticTypes']("ExternalInventories");
            }
        }
    }
</script>
