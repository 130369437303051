<template>
    <div>    
        <div class="section--fields--header">{{ trans('field-search-containers.select_provinces') }}</div>

        <div class="container">
            <div class="section--fields-column-display">    
                <div v-bind:class="limitDisplay ? 'limit-display show-16': ''" v-for="province in Provinces" v-bind:key="province.Id">

                    <label class="checkbox-label">
                        <input type="checkbox" v-model="ComponentProvinceIds" :value="province.Id" />
                        <span class="checkbox-text">{{ province.Name }}</span>  
                    </label>           
                </div>
            </div>
        </div>

        <div class="fields-column-display--show-all" v-if="Provinces.length > 16">
            <a @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
        </div>

        <div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['ApplyButton'],

        data: function() {
            return {
                Provinces: null,
                //ComponentProvinceIds: this.ProvinceIds,
                limitDisplay: true
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.Provinces.length + " provinces"
                                            : "Show Less";
            },

            ComponentProvinceIds: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['ProvinceIds']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ProvinceIds', value:val});                    
                }
            }
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('ComponentProvinceIds', (val) => {
                //     this.$emit('update:ProvinceIds', {val});                
                // }, { immediate: false });
            }
        },

        created() {
            this.load();
        },

        methods: {
            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ProvinceIds', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                this.Provinces = this.$store.getters['staticTypes/StaticTypes']("Provinces");
            }
        }
    }
</script>
