<template>
    <div class="section--fields-dropdown">
        <div class="fields section--fields-dropdown-display">
            <!--<div class="section--fields--header">{{ trans('field-search-containers.select_location') }}</div>-->
            <div class="dropdown-header-search">

                <div class="dropdown-search">
                    <input type="text" id="search" class="form-control px-15" :placeholder="trans('field-search-containers.search_for_zone_placeholder')" v-on:keyup="listFilter" />
                </div>
            </div>

            
            <div class="dropdown-elements px-15 mt-15">
                <div v-bind:class="limitDisplay ? 'limit-display': ''" v-for="zone in zonesShow" v-bind:key="zone.Id">

                    <label class="checkbox-label">
                        <input type="checkbox" :name="'zones_'+id+'[]'" :id="'zone_'+zone.Id+'_'+id" v-model="componentZoneIds" :value="zone.Id" />
                        <span class="checkbox-text">{{ zone.Name }}</span>  
                    </label>   

                </div>
            </div>
        </div>
            

        <div v-if="ApplyButton" class="fields-column-display--buttons top-shadow">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
        
    </div>
</template>

<script>
    export default {
        props: {
            ApplyButton: { type: Boolean|String },
            toggle: {
                type: Boolean,
                default: false,
            }
        },

        data: function() {
            return {
                zones: null,
                zonesShow: null,
                limitDisplay: true,
                id: null
            }
        },

        computed: {
            componentZoneIds: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['ZoneIds']
                },
                set (val) {    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ZoneIds', value:val});                    
                }
            }
        },

        mounted() {
            //create random id - this is used to allow the same component to be 
            //displayed multiple times on a page by adding a random string to the id
            this.id = Math.random().toString(36).substring(2, 5);
        },

        created() {
            this.load();
        },

        methods: {

            /**
             * Filters zones by towns
             * @param {*} TownIds 
             */
            filterZonesByTown(townIds) {
                if( townIds.length == 0 ) { this.zonesShow = this.zones; return; }

                var zonesShow = [];
                this.zones.forEach(zone => {
                    townIds.forEach(townId => {
                        if(townId == zone.TownId) { zonesShow.push(zone); }
                    });
                });

                this.zonesShow = zonesShow;
            },

            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ZoneIds', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                this.zones = this.$store.getters['staticTypes/StaticTypes']("Zones");
                this.filterZonesByTown( this.$store._modules.root._children.ListingSearch.state.TownIds );
            },

            listFilter(event) {
                this.Selected = event.target.value;
                var filtered = (this.zones.filter(this.filterByListing));
                this.zonesShow = filtered;
            },

            filterByListing: function(data) {
                return (data.Name.toLowerCase().indexOf(this.Selected.toLowerCase())) > -1;
            },

        },

        watch: {

            /**
             * If the updated data is true, place focus on search input and seelct it automatically
             * @param {*} updatedData 
             */
            toggle(updatedData) {
                if(updatedData == true) {
                    var input = document.getElementById('search');
                    input.focus();
                    input.select();
                }
            },

            /**
             * Watch for Town ids changes from Vuex store
             * @param {*} updatedData 
             */
            '$store._modules.root._children.ListingSearch.state.TownIds'(updatedData) {
                this.filterZonesByTown( updatedData );
            }

        }
    }
</script>
