<template>
    <div class="section--blog-card-minimal">

        <div class="bg-primary shadow-default">

            <div class="bg-cover bg-absolute bg-center" :style="'background-image: url('+getBackgroundImageOfPost(article)+')'"></div>

            <div class="bg-absolute bg-linear-gradient-default"></div>

            <a :href="'/' + type + '/' + article.slug">
                <div class="blog-card--content">
                    <div class="blog-card--title">
                        <p v-html="article.title.rendered"></p>
                    </div>
                </div>
            </a>

        </div>

    </div>

</template>

<script>

    export default {
        props: ['article', 'type'],

        methods: {
            getBackgroundImageOfPost(post) {
                if (post['_embedded'] != undefined && 
                    post['_embedded']['wp:featuredmedia'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details'] != undefined && 
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return '';
                }
            }
        }
    }

</script>