<template>
    <div>
        <ai-residential-commercial 
            :enable-ai-search="enableAiSearch" 
            :showResidential="showResidential" 
            :showCommercial="showCommercial"
            @AISearch="emitAISearch"
        />
    </div>
</template>

<script>

    export default {

        props: {
            enableAiSearch: Boolean,
            showResidential: Boolean,
            showCommercial: Boolean,
        },

        methods: {
            /**
             * @param {*} showAISearch bool valuie to show/hide AI search
             */
            emitAISearch(showAISearch) {
                this.$emit('showAISearch', showAISearch);
            }
        }
        
    }
</script>