<template>
    <div class="section--image-slider">
        <flickity v-if="images.length" @init="onInit" class="flickity" ref="flickity" :options="carouselSettings">
            <div style="" class="carousel-cell" v-for="image in images">
                <div :style="'background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 40%), url('+image+')'">     
                </div>
            </div>

            <div class="carousel-cell" v-if="youtubeVideos" v-for="video in youtubeVideos" @click="openVideo">
                <div class="image-holder" :style="'background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 40%), url('+getYoutubeVideoImage(video)+')'">     
                    <span class="play-button"></span>
                </div>
            </div>
        </flickity> 

        <sweet-modal v-if="youtubeVideos && youtubeVideos[0]" :enable-mobile-fullscreen="true" ref="youtubevideo" v-on:close="stopVideo()">
            <youtube :player-vars="youtubePlayerVars" :video-id="getYoutubeVideoIdFromUrl(youtubeVideos[0])" ref="youtube"></youtube>
        </sweet-modal>
        
        <div class="image-slider--control-panel">
            <div class="image-slider--vr-tour">
                <div class="d-flex align-items-center" v-if="vrLink">
                    <icon-vr></icon-vr><a class="link-arrow-sm ml-10" :href="vrLink" target="_blank">{{ trans('misc.take_a_vr_tour') }}</a>
                </div>
            </div>
            <div class="image-slider--navigation">

                <div class="navigation--dots">
                    <span>1</span>
                    <ol class="flickity-page-dots">
                        <li v-for="index in sliderLength" class="dot" :class="getCurrentIndex == index - 1 ? 'is-selected' : ''" :aria-current="getCurrentIndex == index - 1 ? 'step' : ''" :aria-label="'Page dot '+ index - 1"></li>
                    </ol>
                    <span>{{ sliderLength }}</span>
                </div>

                <div class="click-link" @click="previous"><icon-arrow-left /></div>
                <div class="click-link" @click="next"><icon-arrow-right /></div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['images', 'vrLink', 'youtubeVideos'],

        data: function() {
            return {
                carouselSettings: {
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'left',
                    wrapAround: false,
                    freeScroll: false,
                    autoPlay: 2300,
                },
                youtubePlayerVars: {
                    width: '100%',
                    resize : true,
                },
                getCurrentIndex: 0,
            }
        },

        computed: {
            player() {
                return this.$refs.youtube.player
            },

            sliderLength() {
                if( !this.youtubeVideos ) { return this.images.length; }
                return this.youtubeVideos.length + this.images.length;
            }
        },

        methods: {

            onInit() {
                var that = this;
                this.$refs.flickity.on('select', function () {
                    that.getCurrentIndex = that.$refs.flickity.selectedIndex();
                });
            },

            next() {
                this.$refs.flickity.next();
                this.$refs.flickity.stopPlayer();
            },
            
            previous() {
                this.$refs.flickity.previous();
                this.$refs.flickity.stopPlayer();
            },

            getYoutubeVideoIdFromUrl(url) {
                // https://stackoverflow.com/a/8260383
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                var match = url.match(regExp);
                return (match && match[7].length == 11) ? match[7] : false;
            },

            getYoutubeVideoImage(url) {
                let videoId = this.getYoutubeVideoIdFromUrl(url);
                
                //https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                //return 'https://img.youtube.com/vi/'+videoId+'/0.jpg';

                //get image from youtube img (higher res)
                return 'https://img.youtube.com/vi/'+videoId+'/maxresdefault.jpg';
            },

            openVideo: function() {
                this.$refs.youtubevideo.open();
                this.playVideo();
            },

            playVideo() {
                this.player.playVideo();
            },

            stopVideo() {
                this.player.stopVideo();
            },
        }
    }
</script>