import Vue from 'vue'
import Vuex from 'vuex'

//Import Vuex Modules
import StaticTypes from './VuexModules/StaticTypes';
import User from './VuexModules/User';
import ListingSearch from './VuexModules/ListingSearch';
import ShareCompare from './VuexModules/ShareCompare';
import TownBoundaries from './VuexModules/TownBoundaries';

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    modules: {
        user: User,
        staticTypes: StaticTypes,
        ListingSearch: ListingSearch,
        shareCompare: ShareCompare,
        townBoundaries: TownBoundaries,
    }
})