<template>
    <div class="section--email-notifications-box">
        <div class="notification--name"><span>{{ name }}</span></div>
        <div class="notification--switch" :class="isEnabled"><switches v-model="enabled" theme="custom" color="blue"></switches> <span>{{ isEnabled }}</span></div>
    </div>
</template>

<script>

    export default {
        props: ['id', 'name', 'current'],
        
        data: function() {
            return {
                enabled: null
            }
        },

        computed: {
            isEnabled() {
                return (this.enabled) ? 'Enabled' : 'Disabled';
            }
        },

        mounted() {
            this.enabled = this.current;

            //the watcher is init here, to eliminate the events being triggered on component load
            //since the variable being watched is assigned before this step
            this.$watch('enabled', (val) => {
                this.$emit('updatedNotification', { 
                                                    id: this.id, 
                                                    value: val 
                                                });                
            }, { immediate: false });
        },
    }
    
</script>
