<template>
    <div>
        <div class="section--fields--header">
            <p>{{ trans('field-search-containers.select_towns') }}</p>
        </div>

        <div class="section--fields-column-display">
            <div v-bind:class="limitDisplay ? 'limit-display show-8': ''" v-for="town in towns" v-bind:key="town.Id">

                <label class="checkbox-label">
                    <input type="checkbox" v-model="componentTownIds" :value="town.Id" />
                    <span class="checkbox-text">{{ province.Name }}</span>  
                </label>       
                
            </div>
        </div>

        <div class="fields-column-display--show-all">
            <a v-if="towns.length > 16" @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
        </div>

        <div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="text-primary">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="text-secondary link-arrow-sm">{{ trans('field-search-containers.apply') }}</a>
        </div>
    
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton'],

        data: function() {
            return {
                towns: null,
                limitDisplay: true,
                id: null
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.Provinces.length + " towns"
                                            : "Show Less";
            },

            componentTownIds: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['TownIds']
                },
                set (val) {    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value:val});                    
                }
            }
        },

        mounted() {
            //create random id - this is used to allow the same component to be 
            //displayed multiple times on a page by adding a random string to the id
            this.id = Math.random().toString(36).substring(2, 5);
        },

        created() {
            this.load();
        },

        methods: {
            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },
            load: function() {
                this.towns = this.$store.getters['staticTypes/StaticTypes']("Towns");
            }
        }
    }
</script>
