<template>
    <div>
        <label @click="Open = !Open">
            {{ trans('field-search-containers.marketing_categories') }}
            <span>{{ getSelectedMarketingCategoriesDataLength }}</span>
        </label>

        <div v-show="Open">
            <marketing-categories></marketing-categories>
        </div>        
    </div>
</template>

<script>
    export default {

        data: function() {
            return {
                Open: false                
            }
        },

        computed: {
            getSelectedMarketingCategoriesDataLength() {                
                if (Array.isArray(this.$store.getters['ListingSearch/get']['SelectedMarketingCategories'])) {
                    return this.$store.getters['ListingSearch/get']['SelectedMarketingCategories'].length;
                }
                else {
                    return 0;
                }
            }
        }
    }
</script>
