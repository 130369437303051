<template>
    <div class="section--saved-searches--box" v-if="display" :title="display">
        <div class="name">{{ value.display_name }}</div>
        <span>&nbsp;:&nbsp;</span>
        <div class="values">{{ display }}</div>
    </div>
</template>

<script>

    export default {
        props: ['name', 'value'],
        data: function() {
            return {
                display: null
            }
        },

        mounted() {
            this.display = this.parseDisplay(this.value.value);
        },

        methods: {
            parseDisplay: function(value) {
                if (typeof value === 'object') {
                    return (Object.values(value)).join(', ');
                }

                return value;               
            },
        }
    }
    
</script>
