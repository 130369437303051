const state = {
    //get data from local storage
    User: {},
    FavouriteProperties: [],
    FavouriteAgents: [],
};

const getters = {
    isLoggedIn(state) {
        return state.User !== null;
    },
    User: (state) => {
        return state.User;
    },
    FavouriteProperties: (state) => {
        return state.FavouriteProperties;
    },
    FavouriteAgents: (state) => {
        return state.FavouriteAgents;
    }
};

const mutations = {
    setAuthUser(state, user) {
        state.User = user;
    },

    setFavouriteProperties(state, properties) {
        state.FavouriteProperties = properties;
    },

    setFavouriteAgents(state, agents) {
        state.FavouriteAgents = agents;
    }
};

const actions = {

    async setAuthUser({ dispatch, commit }, user) {
        commit('setAuthUser', user);

        if (user !== null) {
            await dispatch('loadPropertyFavouritesApi');
            await dispatch('loadAgentsFavouritesApi');
        }
    },

    loadPropertyFavouritesApi({ state, commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/favourite/property/minimal')
                .then(function (response) {
                    // console.log(response);
                    commit('setFavouriteProperties', response.data.data);

                    //resolve promise
                    resolve();
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },

    loadAgentsFavouritesApi({ state, commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/favourite/agent/minimal')
                .then(function (response) {
                    // console.log(response);
                    commit('setFavouriteAgents', response.data.data);

                    //resolve promise
                    resolve();
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};