<template>
    <div class="property-types-macro-type">
    
        <div class="section--fields--sub-header-checkbox ">
            <div class="sub-header--checkbox">
                <label class="checkbox-label">
                    <input type="checkbox" v-model="selectAllSelected" @change="actionAllSelected" />
                    <span class="checkbox-text">{{ MacroType.Name }}</span>  
                </label>       
            </div>
            <div class="sub-header--arrow">
                <a @click="showMore = !showMore"><icon-arrow-down /></a>
            </div>
        </div>

        <div v-if="showMore" class="container">

            <div class="section--fields-column-display row">
                
                <div v-bind:class="limitDisplay ? 'limit-display show-8': ''" v-for="property_type in PropertyTypes" v-bind:key="property_type.Id">
                    <label class="checkbox-label" @click="selectAllSelected = false">
                        <input type="checkbox" v-model="ComponentSelectedPropertyTypes" :value="property_type.Id" />
                        <span class="checkbox-text">{{ property_type.Name }}</span>  
                    </label>  
                </div>
            
            </div>

            <div v-if="PropertyTypes.length > 16" class="fields-column-display--show-all row">
                <a @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
            </div>

            <div v-if="ApplyButton" class="fields-column-display--buttons">
                <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
                <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
            </div>

        </div>
    
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'MacroType'],

        data: function() {
            return {
                PropertyTypes: null,
                limitDisplay: true,
                selectAllSelected: false,
                showMore: false
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.PropertyTypes.length + " property types"
                                            : "Show Less";
            },

            ComponentSelectedPropertyTypes: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['SelectedPropertyTypes']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedPropertyTypes', value:val});                    
                    e.stopPropagation()
                }
            },
        },

        mounted() {
            var that = this;
            this.$root.$on('clear-all-fields', that.handleMainContainerClear);
        },

        created() {
            this.load();
        },

        methods: {
            handleMainContainerClear: function() {
                this.selectAllSelected = false;
            },
            
            actionAllSelected: function() {
                
                if(this.selectAllSelected) {
                    this.selectAll();
                }
                else {
                    this.unSelectAll();
                }

            },

            selectAll: function() {
                //clone array
                var current_list = [].concat(this.$store.getters['ListingSearch/get']['SelectedPropertyTypes']);

                for (var key in this.PropertyTypes) {
                    if (!this.PropertyTypes.hasOwnProperty(key)) continue;

                    var obj = this.PropertyTypes[key];

                    //if town is not in current_list, push to current_list
                    if (current_list.indexOf(obj.Id) == -1) {
                        current_list.push(obj.Id);
                    }
                }

                //update main store
                this.$store.dispatch('ListingSearch/updateSearchCriteria', { key: 'SelectedPropertyTypes', value:current_list });  

                this.selectAllSelected = true;

            },
            unSelectAll: function() {
                var current_list = [].concat(this.$store.getters['ListingSearch/get']['SelectedPropertyTypes']);

                for (var key in this.PropertyTypes) {
                    if (!this.PropertyTypes.hasOwnProperty(key)) continue;

                    var obj = this.PropertyTypes[key];

                    //if town is not in current_list, push to current_list
                    if (current_list.indexOf(obj.Id) > -1) {
                        current_list.splice(current_list.indexOf(obj.Id));
                    }
                }

                //update main store
                this.$store.dispatch('ListingSearch/updateSearchCriteria', { key: 'SelectedPropertyTypes', value:current_list });  

                this.selectAllSelected = false;

            },

            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedPropertyTypes', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                var that = this;
                var macroTypeId = this.MacroType.Id;
            
                this.PropertyTypes = this.$store.getters['staticTypes/StaticTypes']("PropertyTypes").filter(function(element) {
                    return that.filterByListing(element, macroTypeId);
                });
            },

            filterByListing: function(data, macroId) {
                return data.PropertyMacroTypeId == macroId;
            }
            
        }
    }
</script>
