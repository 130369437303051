<template>
    <div>
      <a id="test" href="javascript:;" @click="openFancybox()" class="a">Slideshow</a>
    </div>
</template>

<script>
    export default {
        props: ['mls', 'images'],

        created() {
          // console.log(this.mls);
        },

        methods: {
            openFancybox() {
              var fancyboxImages = [];

              $.each(this.images, function (i, image) {
                fancyboxImages.push({
                  'src': image, 
                });
              });  
              
              $.fancybox.open(fancyboxImages, {
                loop : true,
                hash : "gallery-" + this.mls,
                buttons: [
                  "zoom",
                  //"share",
                  "slideShow",
                  "fullScreen",
                  //"download",
                  "thumbs",
                  "close"
                ]
              });
            }
        }
    }
</script>