<template>
    <div class="section--agents-of-branch--container"> 

        <div class="agents-of-branch--row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4"> 
            <div class="col mb-15 mb-lg-30" v-for="admin in admins">
                <agent-card :key="admin.AssociateId" :agent="admin" :viewProfile="false"></agent-card>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            branchId: {
            type: String,
          }, 
          admins: {
            type: Object,
          }
        },

        data: function() {
            return {
                isLoading: false,
            }
        },
        
        mounted() {
            
        },

        methods: {
            
        }
    }
</script>