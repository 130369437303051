<template>
    <div class="section--saved-searches">

        <div class="my-profile--actions">
            <a href="/account/my-profile">
                <icon-nav-arrow-left></icon-nav-arrow-left>
                <span>{{ trans('account.back_to_my_profile') }}</span>
            </a>
            <a href="/account/email-notifications">
                <icon-cog />
                <span>{{ trans('account.notification_preferences') }}</span>
            </a>
        </div>
        
        <div class="mt-30">
            <h4 class="mb-0">{{ trans('account.saved_searches') }}</h4>
        </div>

        <loading-component v-if="isLoading"></loading-component>
        
        <div v-if="!isLoading">
            <div class="container-fluid" v-if="searches.length">
                <div class="saved-searches--row saved-searches--list" v-for="(search, index) in searches" :key="index">   
                    <div class="saved-searches--search-attributes">
                        <div class="saved-searches--row">
                            <div class="search-attributes--criterias">
                                
                                <!-- <div v-for="(attribute_value, attribute_name) in search.value"  @click="openCriteriaModal(attribute_value.display_name, attribute_value.value)"
                                    v-if="((attribute_value.value !== null && !Array.isArray(attribute_value.value))|| (Array.isArray(attribute_value.value) && attribute_value.value.length > 1))">  
                                    <saved-searches-box :name="attribute_name" :value="attribute_value"></saved-searches-box>
                                </div> -->

                                <div v-for="(attribute_value, attribute_name) in search.value" v-if="((attribute_value.value !== null && !Array.isArray(attribute_value.value))|| (Array.isArray(attribute_value.value) && attribute_value.value.length > 1))">  
                                    <saved-searches-box :name="attribute_name" :value="attribute_value"></saved-searches-box>
                                </div>

                            </div>
                            <div class="search-attributes--button">
                                <div class="saved-searches--search-link">
                                    <a class="btn btn-primary" :href="getUrl(search.value)">{{ trans('account.search_again') }}</a>
                                </div>
                                <div class="saved-searches--remove-link">
                                    <a class="text-danger" @click="deleteSavedSearch(search.key, index)">
                                        <icon-close />
                                        {{ trans('account.remove_search') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="mt-15">{{ trans('account.saved_searches_empty') }}</div>
            </div>
        </div>

        <modal>
            <template slot="title"></template>
            <template slot="body"></template>
        </modal>
        
    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                searches: [],
                isLoading: true
            }
        },

        mounted() {
            this.getSavedSearches();
        },

        methods: {
            getUrl: function(data) {
                var url = [];

                for (var property in data) {
                    var param = '';
                    if (data.hasOwnProperty(property)) {
                        var this_object = data[property];
                            //skip if value is null or empty
                        if ((this_object.value === null) || 
                            (Array.isArray(this_object.value) && !this_object.value.length > 0)) {
                            continue;
                        }

                        param = property + '=';

                        if (typeof this_object.value === 'object' && this_object.value !== null) {
                            param += (Object.keys(this_object.value)).join(',');
                        }
                        else {
                            param += this_object.value;
                        }

                        url.push(param);
                    }
                }
                
                return '/listings?'+url.join('&');
            },
            openCriteriaModal: function(title, body) {
                var formattedBody = undefined;
                if (typeof body === 'object' && body !== null) {
                    var formattedBody = Object.values(body);
                    formattedBody = '<div>'+formattedBody.join('</div><div>')+'</div>';
                }
                else {
                    formattedBody = '<div>'+body+'</div>';
                }

                VoerroModal.show({
                    title: title,
                    body: formattedBody,
                });
            },
            getSavedSearches: function() {
                this.isLoading = true;

                axios.get('/saved-searches')
                .then(response => {  
                    //convert return object to array so that the data is reactive
                    this.searches = Object.entries(response.data.data).map(([key, value]) => ({key,value}));
                    this.isLoading = false;
                });
            },
            deleteSavedSearch: function(searchId, index) {
                this.isLoading = true;

                axios.put('/saved-searches/'+searchId)
                .then(response => {  
                    this.searches.splice(index, 1);        
                    this.isLoading = false;            
                });
            }

        }
    }
    
</script>
