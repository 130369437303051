export default {
    convertObjectInQueryParameters(object) {
        //not using URLSearchParams due to non IE support
        var urlParams = [];

        for (let key in object) {
            if (object.hasOwnProperty(key)) {

                var this_iteration = object[key];

                // if empty skip / do not include in url
                if (this_iteration == null ||
                    (Array.isArray(this_iteration) && this_iteration.length == 0)) {
                    continue;
                }

                //if array join them up ex . 1,2,3,4, else just display value ex . 1 
                var value = (Array.isArray(this_iteration)) ? this_iteration.join(',')
                    : this_iteration;

                urlParams.push(key + '=' + value);
            }
        }

        //join all paras ex. a=1,2,3&b=4&c=5,6
        return urlParams.join('&');
    },


    addCommas(nStr) {
        nStr += '';

        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;

        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }

        return x1 + x2;
    },

    deviceSize() {
        if (window.matchMedia('(max-width: 575.98px)').matches) {
            return 'xs';
        }
        else if (window.matchMedia('(min-width: 576px) and (max-width: 767.98px)').matches) {
            return 'sm';
        }
        else if (window.matchMedia('(min-width: 768px) and (max-width: 991.98px)').matches) {
            return 'md';
        }
        else if (window.matchMedia('(min-width: 992px) and (max-width: 1199.98px)').matches) {
            return 'lg';
        }
        else if (window.matchMedia('(min-width: 1200px)').matches) {
            return 'xl';
        }
    },

    stringToBoolean: function(value){

        if (typeof value == "boolean") {
            return value;
        }

        switch(value.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(value);
        }
    }
}