<template>
    <div class="field--price-min-max" :class="IsMinimal">

        <div class="price-min-max--fields">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="price-from-symbol">&euro;</span>
                </div>
                <input type="number" class="form-control" :placeholder="trans('field-search-containers.min_price')" :aria-label="trans('field-search-containers.min_price')" v-model="ComponentPriceFrom" aria-describedby="price-from-symbol">
            </div>

            <span class="h5 mx-10 my-5 my-lg-0">
                <span class="d-none d-lg-block">{{ trans('field-search-containers.and') }}</span>
            </span>

            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="price-from-to">&euro;</span>
                </div>
                <input type="number" class="form-control" :placeholder="trans('field-search-containers.max_price')" :aria-label="trans('field-search-containers.max_price')" v-model="ComponentPriceTo" aria-describedby="price-from-to">
            </div>
        </div>

        <button v-if="ApplyButton" v-on:click="$emit('updatePrices', prices)">{{ trans('field-search-containers.apply') }}</button>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'minimal'],

        data: function() {
            return {
                // prices: {
                //     PriceFrom: null,
                //     PriceTo: null
                // }
            }
        },

        computed: {
            SliderData() {
                return [this.ComponentPriceFrom, this.ComponentPriceTo];
            },
            IsMinimal() {
                return (this.minimal == true) ? 'minimal' : '';
            },
            ComponentPriceFrom: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['PriceFrom']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceFrom', value:val});                    
                }
            },
            ComponentPriceTo: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['PriceTo']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceTo', value:val});                    
                }
            }
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('updatePrices', (val) => {
                //     this.$emit('updatePrices', {val});                
                // }, { immediate: false });
            }
        },

        created() {
           
        },

        methods: {
        }
    }
</script>
