<template>
    <div class="field--mls">
		<div class="d-flex align-items-center">
			<div class="mr-20">
				<p class="mb-0">REF-ID</p>
			</div>  
			<div class="input-groups">
				<input type="text" class="form-control" placeholder="000000000-000" aria-label="" v-model="ComponentMls">
			</div>
		</div>

		<div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
	</div>
</template>

<script>
    export default {
        props: ['ApplyButton'],

        data: function() {
            return {
            }
        },

        computed: {
            ComponentMls: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['MLS']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MLS', value:val});                    
                }
            },
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('updatePrices', (val) => {
                //     this.$emit('updatePrices', {val});                
                // }, { immediate: false });
            }
        },

        created() {
           
        },

        methods: {

            clearClick: function() {
                //clear both properties
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MLS', value:null});             
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

        }
    }
</script>
