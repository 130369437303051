<template>

    <div>

        <div v-if="enableAiSearch">
            <label class="mb-0 mr-15 mb-10" :class="traditionalSearchClass" :for="traditionalSearchBtnId">
                <input type="radio" :id="traditionalSearchBtnId" name="traditional" value="traditional" v-model="searchValue" autocomplete="off" hidden> 
                <span>{{ trans('field-search-containers.traditional_search') }}</span>
            </label>

            <label class="mb-0 mr-15 mb-10" :class="aiSearchClass" :for="aiSearchBtnId">
                <input type="radio" :id="aiSearchBtnId" name="ai" value="ai" v-model="searchValue" autocomplete="off" hidden> 
                <span>{{ trans('field-search-containers.ai_search') }}</span>
            </label>

            <div class="border-top mt-10 mb-20"></div>
        </div>

        <div class="field--residential-commercial" v-if="searchValue == 'traditional'">

            <label v-if="showResidential" class="mb-0" for="residential-search-selection">
                <input type="checkbox" id="residential-search-selection" name="listing_type" v-model="ComponentResidential" autocomplete="off"> 
                <span>{{ trans('field-search-containers.residential') }}</span>
            </label>

            <label v-if="showCommercial" class="mb-0" for="commercial-search-selection">
                <input type="checkbox" id="commercial-search-selection" name="listing_type" v-model="ComponentCommercial" autocomplete="off"> 
                <span>{{ trans('field-search-containers.commercial') }}</span>
            </label>

        </div>

    </div>

</template>

<script>
    export default {

        props: {
            enableAiSearch: Boolean,
            showResidential: Boolean,
            showCommercial: Boolean,
        },

        data() {
            return {
                traditionalSearchBtnId: 'traditional_search_' + Math.floor(Math.random() * 100),
                aiSearchBtnId: 'ai_search_' + Math.floor(Math.random() * 100),
                searchValue: 'traditional',
                aISearch: false,
                aiSearchClass: 'btn-sm btn-outline-primary border border-primary rounded-lg',
                traditionalSearchClass: 'btn-sm btn-primary border border-primary rounded-lg',
            }
        },

        computed: {
            ComponentResidential: {
                get () {                                              
                    return this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['Residential']);
                },
                set (val) {                                       
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'Residential', value:val});                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'Commercial', value:!val});                    
                }
            },
            ComponentCommercial: {
                get () {        
                    return this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['Commercial']);
                },
                set (val) {                                   
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'Commercial', value:val});                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'Residential', value:!val});                    
                }
            }
        },

        watch: {
            searchValue(updatedData) {
                if(updatedData == 'traditional') {
                    this.aISearch = false;
                    this.aiSearchClass = 'btn-sm btn-outline-primary border border-primary rounded-lg';
                    this.traditionalSearchClass = 'btn-sm btn-primary border border-primary rounded-lg';
                }
                else {
                    this.aISearch = 'ai';
                    this.aiSearchClass = 'btn-sm btn-primary border border-primary rounded-lg';
                    this.traditionalSearchClass = 'btn-sm btn-outline-primary border border-primary rounded-lg';
                }

                this.$emit('AISearch', this.aISearch);
            },
        }
    }
</script>
