<template>
    <div class="field--price-min-max--dropdown">
        <div class="price-min-max--fields">
            <div class="price-min-max--fields--header section--fields--header">{{ trans('field-search-containers.type_in_price_range') }}</div>

<!--             <div class="price-range-slider--content" v-if="showSliderRange">
                <vue-slider tooltip="none" :interval="1000" :silent="true" :min="0" :max="(ComponentPriceTo > 800000) ? parseInt(ComponentPriceTo) : 800000" v-model="SliderData"></vue-slider>
            </div> -->

            <div class="price-min-max--fields--content">
                <div>
                    <div>
                        <p>{{ trans('field-search-containers.min_price') }}</p>
                    </div>  
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="price-from-symbol">&euro;</span>
                        </div>
                        <input type="number" class="form-control" placeholder="100,000" aria-label="Min Price" v-model="ComponentPriceFrom" aria-describedby="price-from-symbol">
                    </div>
                </div>

                <!-- <div class="seperator"><span>-</span></div> -->

                <div>
                    <div>
                        <p>{{ trans('field-search-containers.max_price') }}</p>
                    </div>                
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="price-from-to">&euro;</span>
                        </div>
                        <input type="number" class="form-control" placeholder="1,000,000" aria-label="Max Price" v-model="ComponentPriceTo" aria-describedby="price-from-to">
                    </div>
                </div>
            </div>
        </div>

        <div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'showSliderRange'],

        data: function() {
            return {
                // prices: {
                //     PriceFrom: null,
                //     PriceTo: null
                // }
            }
        },

        computed: {
            SliderData: {
                get () {                    
                    return [this.ComponentPriceFrom, this.ComponentPriceTo];
                },
                set (val) {
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceFrom', value:val[0]});                                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceTo', value:val[1]});                                    
                    // this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceFrom', value:val});                    
                }
            },

            ComponentPriceFrom: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['PriceFrom']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceFrom', value:val});                    
                }
            },
            ComponentPriceTo: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['PriceTo']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceTo', value:val});                    
                }
            }
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('updatePrices', (val) => {
                //     this.$emit('updatePrices', {val});                
                // }, { immediate: false });
            }
            // this.ComponentPriceFrom = this.$store.getters['ListingSearch/get']['PriceFrom'];
            // this.ComponentPriceTo = this.$store.getters['ListingSearch/get']['PriceTo'];
        },

        created() {
           
        },

        methods: {

            clearClick: function() {
                //clear both properties
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceTo', value:null});       
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PriceFrom', value:null});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

        }
    }
</script>
