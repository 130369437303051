<template>
    <div class="section--sidebar-pods">
        <div class="section--sidebar-pods--title" v-html="trans('blog.related_posts')"></div>
        <loading-component v-if="loading"></loading-component> 
        <div class="section--sidebar-pods--item" v-for="post in posts">
            <div class="item--content">
                <div class="item--post-type" v-html="postTypeLabel"></div>     
                <a :href="'/' + slugBase + '/' + post.slug" class="item--title text-decoration-none" v-html="post.title.rendered"></a>
                <div class="item--excerpt">     
                    <p class="small text-dark line-clamp--2" v-if="post.excerpt" v-html="post.excerpt.rendered"></p>
                </div>
                <a :href="'/' + slugBase + '/' + post.slug" class="d-block link-arrow-sm text-decoration-none">{{ trans('blog.read_article') }}</a>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props: ['postType', 'postTypeLabel', 'slugBase', 'postId', 'postCategories', 'amount'],

        data: function() {
            return {
                posts: [],
                default: process.env.MIX_DEFAULT_IMAGE
            }
        },

        mounted() {
            this.getRelatedPosts();
        },

        methods: {
            getRelatedPosts: function() {
                
                axios.get('/api/' + this.postType, {
                    params: {
                        'show': this.amount,
                        'options': {
                            'categories': this.postCategories,
                            'exclude': this.postId,
                            'embed': true
                        }
                    }
                })
                .then(response => {    
                    this.posts = response.data.data.items.data;
                });
            },

            getBackgroundImageOfPost(post) {
                if (post['_embedded'] != undefined && 
                    post['_embedded']['wp:featuredmedia'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details'] != undefined  && 
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes'] != undefined &&
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return process.env.MIX_DEFAULT_IMAGE;
                }
            }
            
        }
    }
</script>
