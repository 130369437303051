<template>
    <div class="section--my-profile">

        <div class="my-profile--actions">
            <a @click="emitEditProfile">
                <icon-edit />
                <span>{{ trans('account.edit_profile') }}</span>
            </a>
        </div>

        <div v-if="user" class="row my-30">
            <div class="col-6 col-md-3 mb-30">
                <img class="img-fluid" :src="getUserProfileImage()" />
            </div>
            <div class="col-12 col-md-9 my-profile--details">
                <div class="mb-15 mb-md-30">
                    <label class="mb-0">{{ trans('account.full_name') }}</label>
                    <div class="h5 text-quaternary mb-0">{{ user.name }}</div>
                </div>
                <div class="mb-15 mb-md-30">
                    <label class="mb-0">{{ trans('account.email') }}</label>
                    <div><span class="h5 text-quaternary mb-0">{{ user.email }}</span></div>
                </div>
                <div class="mb-30 mb-md-60" v-if='user.phone_number'>
                    <label class="mb-0">{{ trans('account.phone_number') }}</label>
                    <div><span class="h5 text-quaternary mb-0">{{ user.phone_number }}</span></div>
                </div>
                <div class="details--password mb-15 mb-md-30">

                    <div>
                        <a class="text-decoration-none font-weight-bold" @click="toggleChangePassword" v-if="!changePassword">
                            <icon-password />{{ trans('account.change_password') }}
                        </a>
                    
                        <my-profile-change-password v-if="changePassword" @closePasswordChangeComponent="toggleChangePassword"></my-profile-change-password>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="pod">
                <a href="/account/saved-properties">
                    <div>
                        <!-- <img class="img-fluid" src="/images/png/house-placeholder.png" />  -->
                        <span>{{ trans('account.saved_properties') }}</span>
                    </div>
                </a>
            </div>
            <div class="pod">
                <a href="/account/saved-searches">
                    <div>
                        <!-- <img class="img-fluid" src="/images/png/house-placeholder.png" />  -->
                        <span>{{ trans('account.saved_searches') }}</span>
                    </div>
                </a>
            </div>
            <div class="pod">
                <a href="/account/preferred-agents">
                    <div>
                        <!-- <img class="img-fluid" src="/images/png/house-placeholder.png" />  -->
                        <span>{{ trans('account.preferred_agents') }}</span>
                    </div>
                </a>
            </div>
            <div class="pod">
                <a href="/account/email-notifications">
                    <div>
                        <!-- <img class="img-fluid" src="/images/png/house-placeholder.png" />  -->
                        <span>{{ trans('account.email_notifications') }}</span>
                    </div>
                </a>
            </div>
        </div>

        <div class="text-center mb-30 d-md-none">
            <a href="/logout" class="btn btn-primary">{{ trans('header.logout') }}</a>
        </div>
    
    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                user: {
                    name: '',
                    email: '',
                    phone_number: ''
                },
                changePassword: false,                
            }
        },

        mounted() {
            this.getUser();
        },

        methods: {
            getUser: function() {
                axios.get('/user')
                .then(response => {    
                    this.user = response.data;
                });
            },

            toggleChangePassword: function() {
                this.changePassword = !this.changePassword;
            },

            emitEditProfile: function() {
                this.$emit('viewSubComponent');
            },

            getUserProfileImage: function() {
                if (this.user.avatar_url != null) {
                    return this.user.avatar_url;
                }
                else {
                    return '/images/icons/default-user-icon.png';
                }
            }
        }
    }
    
</script>
