<template>
    <div>
        <loading-component v-if="loading" theme="internal"></loading-component>
        <contact-form-enhanced v-if="contact_form" :env="env" :acf-fields="contact_form" :email-replace="emailReplace" :field-replace="fieldsReplace" :hide-whats-app="hideWhatsApp" :title-for-whatsapp="titleForWhatsapp" form-type="property"></contact-form-enhanced>
    </div>
</template>

<script>
    export default {
        props: ['env', 'emailReplace', 'fieldsReplace', 'hideWhatsApp', 'titleForWhatsapp'],

        data: function() {
            return {
                contact_form: null,
                loading: true,
            }
        },
        mounted() {
            this.getContactForm();
        },
        methods: {         
            getContactForm: function() {
                axios.get('/api/contact_form/property')
                .then(response => {
                    this.contact_form = {};
                    this.contact_form.contact_form_parsed = response.data.data.contact_form_parsed;
                    this.contact_form.contact_form = {};
                    this.contact_form.contact_form.ID = response.data.data.contact_form_id;
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>
