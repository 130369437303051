<template>
    <div class="sale-to-let--fields">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label v-if="showBuy" class="btn btn-primary"  :class="ComponentForSale == true ? 'active' : ''">
                <input type="checkbox" name="sale_type" id="sale1" v-model="ComponentForSale" :disabled="ComponentForSale == true"> 
                {{ trans('field-search-containers.buy') }}
            </label>

            <label v-if="showRent" class="btn btn-primary" :class="ComponentForRent == true ? 'active' : ''">
                <input type="checkbox" name="sale_type" id="tolet1" v-model="ComponentForRent" :disabled="ComponentForRent == true"> 
                {{ trans('field-search-containers.rent') }}
            </label>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['showBuy', 'showRent'],

        computed: {
            ComponentForSale: {
                get () {                    
                    return this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['ForSale']);
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForSale', value: true});                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForRent', value: false});                    
                }
            },
            ComponentForRent: {
                get () {                    
                    return this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['ForRent']);
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForRent', value: true});                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForSale', value: false});                    
                }
            }
        },
    }
</script>
