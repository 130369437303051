<template>

    <div v-if="showStats" class="row no-gutters col-12 pt-35">

        <div class="col-lg-3 text-center px-15 pt-15">

            <div class="rounded bg-default-faded py-15">
                <div class="h2 text-white">{{ this.counter1 }}</div>
                <div class="lead text-white">{{ this.snippet1 }}</div>
            </div>

        </div>

        <div class="col-lg-3 text-center px-15 pt-15">

            <div class="rounded bg-default-faded py-15">
                <div class="h2 text-white">{{ this.counter2 }}</div>
                <div class="lead text-white">{{ this.snippet2 }}</div>
            </div>

        </div>

        <div class="col-lg-3 text-center px-15 pt-15">

            <div class="rounded bg-default-faded py-15">
                <div class="h2 text-white">{{ this.counter3 }}</div>
                <div class="lead text-white">{{ this.snippet3 }}</div>
            </div>

        </div>

        <div class="col-lg-3 text-center px-15 pt-15">

            <div class="rounded bg-default-faded py-15">
                <div class="h2 text-white">{{ this.counter4 }}</div>
                <div class="lead text-white">{{ this.snippet4 }}</div>
            </div>

        </div>

    </div>

</template>

<script>
export default {

    props: {
        statistics: Object,
    },

    data: function() {
        return {  
            locale: document.getElementsByTagName('html')[0].getAttribute('lang').replace('_', '-'),
            showStats: true,
            counter1: 0,
            counter2: 0,
            counter3: 0,
            counter4: 0,
            snippet1: '',
            snippet2: '',
            snippet3: '',
            snippet4: '',
        }
    },

    methods: {
        /**
         * Formats a number according to locale
         * @param {*} number 
         */
        formatNumber(number) {
            return number.toLocaleString(this.locale)
        },

        /**
         * Makes counter from 0 to value
         * @param {*} counterName 
         * @param {*} value 
         */
        initCounter(counterName, value) {
            var numeric = value.replace(/\D/g, '');

            const speed = 150;

            const animate = () => {
                const data = +this[counterName]
                const time = numeric / speed;

                if(data < numeric) {
                    this[counterName] = Math.ceil(data + time);
                    setTimeout(animate, 1);
                }
                else {
                    this[counterName] = value;
                }
            }
            
            animate();
        },
    }, 

    created() {
        // Handle the snippets as soon as the component loads
        const keys = Object.keys(this.statistics);

        keys.forEach((key, index) => {
            var number = index + 1;
            var snippetName = 'snippet' + number;

            this[snippetName] = this.statistics[key].substring(this.statistics[key].indexOf(' ') + 1);;
        });
    },

    mounted() {
        // Initialise counter from 0 to sent value from statistics
        const keys = Object.keys(this.statistics);

        keys.forEach((key, index) => {
            if( !this.statistics[key] ) { this.showStats = false; return; }
            var number = index + 1;
            var counterName = 'counter' + number;

            this.initCounter(counterName, this.statistics[key].substring(0, this.statistics[key].indexOf(' ')));
        });
    }

}
</script>