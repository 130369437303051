<template>
    <div>
        <loading-component v-if="loading"></loading-component> 
        <flickity  v-if="posts.length" ref="slick" :options="carouselSettings">
            <blog-card-minimal v-if="minimal" class="carousel-cell" v-for="post in posts" v-bind:key="post.Id" :article="post" :type="type"></blog-card-minimal>
            <blog-card v-else class="carousel-cell" v-for="post in posts" v-bind:key="post.Id" :article="post" :type="type"></blog-card>
        </flickity>   
        <div v-else-if="!loading" class="text-center">
            <h4 class="mb-0">There are no items to show.</h4>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['show', 'type', 'filter', 'order', 'minimal', 'taxonomySlug'],

        data: function() {
            return {
                posts: [],
                carouselSettings: {
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'left',
                    wrapAround: true,
                    freeScroll: false,
                    autoPlay: 2300,
                },
                loading: true,
            }
        },

        created() {
            
        },

        mounted() {
            this.getBlogs(this.show, this.type, this.filter, this.order);
        },

        methods: {
            getBlogs: function(show, type, filter, order) {
                this.loading = true;
                var options = this.buildApiCriteriaApiStructure(filter, order);

                axios.get('/api/'+type, {
                    params: {
                    'show': show,
                    'options': options
                    }
                })
                .then(response => {    
                    this.posts = response.data.data.items.data;
                    this.loading = false;
                });
            },

            buildApiCriteriaApiStructure: function(filter, order) {

                var response = {
                    '_embed': true
                };

                if (order != undefined) {
                    var order_group = order.split(' '); 
                    response.orderby = (order_group[0]).toLowerCase();
                    response.order = (order_group[1]).toLowerCase();
                }

                if ((filter != undefined) && (filter != "false")) {
                    var categories = [];

                    //if filter is string, parse it as JSON object
                    if (typeof filter == 'string') {
                        categories = JSON.parse(filter);
                    }
                    else {
                        categories = filter;
                    }

                    if (this.type == "guides") {
                        response.guide_category = categories;
                    } else if (this.type == "localities") {
                        response.region = categories;
                    } else {
                        response.categories = categories;
                    }

                }
                
                return response;
            },            
        }
         
    }
</script>
