

<template>
    <div class="container">
        <contact-form v-if="contact_form" :acf-fields="contact_form" :field-replace="linkObject"></contact-form>
    </div>
</template>

<script>
    export default {
        props: ['link'],
        data: function() {
            return {
                contact_form: null,
                linkObject: {
                                key: 'link', 
                                value: this.link
                            }
            }
        },
        mounted() {
            this.getContactForm();
        },
        methods: {         
            getContactForm: function() {
                axios.get('/api/contact_form/share-compare')
                .then(response => {
                    this.contact_form = {};
                    this.contact_form.contact_form_parsed = response.data.data.contact_form_parsed;
                    this.contact_form.contact_form = {};
                    this.contact_form.contact_form.ID = response.data.data.contact_form_id;
                });
            },
        }
    }
</script>
