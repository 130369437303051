<template>
    <div class="sale-to-let--fields w-100">
    	<div class="row d-flex align-items-center">
    		<div class="col-12 col-md-4 mb-30 mb-md-0">
		        <div class="btn-group btn-group-toggle" data-toggle="buttons">
		            <label v-if="showBuy" class="btn btn-primary"  :class="ComponentForSale == true ? 'active' : ''">
		                <input type="checkbox" name="sale_type" id="sale1" v-model="ComponentForSale" :disabled="ComponentForSale == true"> 
		                {{ trans('field-search-containers.buy') }}
		            </label>

		            <label v-if="showRent" class="btn btn-primary" :class="ComponentForRent == true ? 'active' : ''">
		                <input type="checkbox" name="sale_type" id="tolet1" v-model="ComponentForRent" :disabled="ComponentForRent == true"> 
		                {{ trans('field-search-containers.rent') }}
		            </label>
		        </div>
		    </div>

            <div v-if="ComponentForSale == true" class="col-12 col-md-6 offset-md-2">
                <contract-type-select></contract-type-select>
            </div>

            <div v-if="ComponentForRent == true"  class="col-12 col-md-8 container">
                <div class="row">
                    <div class="col-12 col-md-6"><periodic-payment-type-select></periodic-payment-type-select></div>
                    <div class="col-12 col-md-6"><chance-of-availability-type-select></chance-of-availability-type-select></div>
                </div>
            </div>

		</div>
    </div>
</template>

<script>
    export default {

        props: ['showBuy', 'showRent'],

        computed: {
            ComponentForSale: {
                get () {                    
                    return this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['ForSale']);
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForSale', value: true});                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForRent', value: false});  
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PeriodicalPaymentTypeId', value: null});              
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ChanceOfAvailabilityTypeId', value: null});              
                }
            },
            ComponentForRent: {
                get () {                    
                    return this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['ForRent']);
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForRent', value: true});                    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ForSale', value: false});    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'ContractTypeId', value: null});                   
                }
            }
        },
    }
</script>
