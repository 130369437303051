<template>
    <span :class="this.class" v-html="require('!html-loader!./../../../../../public/images/icons/svg/icons-calendar.svg')">Icon</span>
</template>

<script>
export default {
    props: {
        class: {
            type: String,
            default: ''
        },
    }
}
</script>