<template>

    <div>
        <div class="section--fields--header">{{ trans('field-search-containers.select_property_types') }}</div>

        <div class="container">

            <div class="dropdown-header-search mt-15">

                <div class="dropdown-search">
                    <input type="text" id="search" class="form-control px-15" :placeholder="trans('field-search-containers.search_for_property_type_placeholder')" v-on:keyup="listFilter" />
                </div>

            </div>

            <div class="section--fields-column-display">
                <div v-bind:class="limitDisplay ? 'limit-display show-16': ''" v-for="property_type in propertyTypesShow" v-bind:key="property_type.Id">
                    <label class="checkbox-label">
                        <input :id="'property_type_'+property_type.Id" type="checkbox" v-model="ComponentSelectedPropertyTypes" :value="property_type.Id" />
                        <span class="checkbox-text">{{ property_type.Name }}</span>  
                    </label>  
                </div>
            </div>

        </div>

        <div class="fields-column-display--show-all" v-if="propertyTypesShow.length > 16">
            <a @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
        </div>

        <div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['FilterResidentialCommercial', 'ApplyButton'],

        data: function() {
            return {
                PropertyTypes: null,
                propertyTypesShow: null,
                //ComponentSelectedPropertyTypes: this.SelectedPropertyTypes,
                limitDisplay: true,
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.PropertyTypes.length + " property types"
                                            : "Show Less";
            },
            
            ComponentSelectedPropertyTypes: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['SelectedPropertyTypes'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedPropertyTypes', value:val});
                }
            },
            ListingType() {
                return this.$store.getters['ListingSearch/get']['Residential'];
            }
        },

        mounted() {
            // if (!this.ApplyButton) {
                
            //     // this.$watch('ComponentSelectedPropertyTypes', (val) => {
            //     //     this.$emit('update:SelectedPropertyTypes', {val});                
            //     // }, { immediate: false });
            // }
        },

        created() {
            this.load(this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['Residential']), this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['Commercial']));
        },

        watch: {
            ListingType(oldValue, newValue) {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedPropertyTypes', value:[]});              

                // this.load(residential, commercial);
                this.load(this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['Residential']), this.$helpers.stringToBoolean(this.$store.getters['ListingSearch/get']['Commercial']));           
            }
        },

        methods: {
            filterByListing: function(data) {
                return (data.Name.toLowerCase().indexOf(this.Selected.toLowerCase())) > -1;
            },

            listFilter(event) {
                this.Selected = event.target.value;
                var filtered = (this.PropertyTypes.filter(this.filterByListing));
                this.propertyTypesShow = filtered;
            },

            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedPropertyTypes', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function(filterResidential, filterCommercial) {

                let residentialPropertyTypeFilterId = 1;
                let commercialPropertyTypeFilterId = 2;
                let filter = null;

                this.PropertyTypes = this.$store.getters['staticTypes/StaticTypes']("PropertyTypes");

                // return all property types
                if (filterResidential && filterCommercial) {
                    return;
                }

                // set filter ID based on parameters
                if (filterResidential) {
                    filter = residentialPropertyTypeFilterId;
                } else {
                    filter = commercialPropertyTypeFilterId;
                }
                                
                if (filter !== undefined) {
                    function filterByListing(data) {
                        return data.ListingTypeId == filter;
                    }
                    
                    this.PropertyTypes = this.PropertyTypes.filter(filterByListing)
                }

                this.propertyTypesShow = this.PropertyTypes;
            }
        }
    }
</script>
