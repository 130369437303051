<template>
    <div>
        <loading-component v-if="isLoading"></loading-component>
        <div v-if="properties.length && !isLoading">
            <loading-component v-if="this.isSliderLoading"></loading-component>
            <div :class="( this.isSliderLoading ? 'invisible' : 'visible' )">
                <flickity :class="getFlicktityClasses()" @init="onInit" class="flickity" ref="flickity" :options="carouselSettings">
                    <property-card-single class="carousel-cell" v-for="property in properties" v-bind:key="property.MLS" :property="property"></property-card-single> 
                </flickity> 
            </div>
            <div :class="getStaticPropertyClasses()" class="container-fluid">
                <div class="row justify-content-center">
                    <property-card-single v-for="property in properties" v-bind:key="property.MLS" :property="property"></property-card-single> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['show', 'criterias', 'carouselWatchCss'],

        data: function() {
            return {
                properties: [],
                carouselSettings: {
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'left',
                    wrapAround: true,
                    freeScroll: false,
                    autoPlay: 2300,
                    watchCSS: this.carouselWatchCss || false,
                    resize: true,
                },
                isLoading: true,
                isSliderLoading: true,
            }
        },

        mounted() {

            var filtering = null;
            //if prop is string, parse it as JSON object
            if (typeof this.criterias == 'string') {
                filtering = JSON.parse(this.criterias);
            }
            else {
                filtering = this.criterias;
            }

            this.getProperties(this.show, filtering);
        },

        methods: {

            onInit() {
                var that = this;
                this.$refs.flickity.once('settle', function () {
                    if(that.isSliderLoading) {
                        that.$refs.flickity.resize();
                        that.isSliderLoading = false;
                    }
                });
            },

            getFlicktityClasses: function() {
                let length = this.properties.length;
                switch(length) {
                  case 1:
                    return 'd-none';
                  case 2:
                    return 'd-md-none';
                  case 3:
                    return 'd-lg-none';
                  case 4:
                    return 'd-xl-none';
                }
            },

            getStaticPropertyClasses: function() {
                let length = this.properties.length;
                switch(length) {
                  case 2:
                    return 'd-none d-md-block';
                  case 3:
                    return 'd-none d-lg-block';
                  case 4:
                    return 'd-none d-xl-block';
                  default:
                    return 'd-none';
                }
            },

            getProperties: function(amount, filteringParameters) {
                this.isLoading = true;
                var that = this;
                var filteringParameters = this.mapObjectToAssocArray(filteringParameters);

                //add / overwrite the amount to show
                if (this.show != null) {
                    filteringParameters['Take'] = this.show;
                }               

                var query = this.$helpers.convertObjectInQueryParameters(filteringParameters);
                
                axios.get('/api/properties?'+query)
                .then(response => {  
                    this.properties = response.data.data.Properties;
                })
                .finally(() => {
                    this.isLoading = false;
                });
            },

            mapObjectToAssocArray: function(object) {
                var response = [];

                for (var obj in object) {
                    if (object.hasOwnProperty(obj)) {
                        response[object[obj].key] = object[obj].value;
                    }
                }

                return response;
            }
        },
         
    }
</script>
