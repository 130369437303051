<template>
	<div>
		<div class="features--row">
			<div v-for="feature in features" v-bind:class="(limitDisplay && limitDisplayCheck) ? 'limit-display show-8': ''" class="features-item">
	 			<div class="mr-10"> 
	 				<font-awesome-icon :icon="getIconOfFeature(feature.FAIcon)" size="lg"/>
				</div>
				<div>{{ feature.Name }}</div>
			</div>
		</div>
		<div v-if="limitDisplayCheck" class="features--row">
			<a v-if="features.length > 8" @click="limitDisplay = !limitDisplay" class="toggle-limit">{{ ShowAllText }}</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['features'],

		data() {
			return {
				limitDisplay: true,
				resolution: '',
			}
		},

		computed: {
			ShowAllText: function() {
				return (this.limitDisplay) ? "Show all features"
													: "Show Less";
			},

			limitDisplayCheck: function() {
				return ((this.resolution == 'xs' || this.resolution == 'sm') && this.features.length > 8);
			}
		},

		mounted() {
			this.resolution = this.$helpers.deviceSize();
			window.addEventListener('resize', this.updateResolution);
		},

		methods: {
			getIconOfFeature(featureIcon) {
				return 'fa-regular ' + (featureIcon || 'fa-check');
			},

			updateResolution() {
				this.resolution = this.$helpers.deviceSize();
			},
		},
	}
</script>