<template>
    <div class="bathroom--fields">
        <div class="section--fields--header">{{ DisplayHeader }}</div>
        <div class="bathroom--fields--content">
            <div>
                <span @click="sub"> - </span>
                <input type="text" v-model="ComponentMinimumBathroom" disabled />
                <span @click="add"> + </span>
            </div>
        </div>

        <div v-if="ApplyButton" class="fields-column-display--buttons">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
        <!--Max Bedroom <input type="text" v-model="ComponentMaximumBedroom" />-->
    </div>

</template>

<script>
    export default {
        props: ['ApplyButton', 'Header'],


        data: function() {
            return {
                DisplayHeader: this.Header ?? 'Select number of bedrooms'
            }
        },

        computed: {
            ComponentMinimumBathroom: {
                get () {                               
                    return (this.$store.getters['ListingSearch/get']['MinimumBathrooms'] ?? 0) + '+';
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumBathrooms', value:val});                    
                }
            },
            ComponentMaximumBathroom: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['MaximumBathrooms']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MaximumBathrooms', value:val});                    
                }
            }
        },

        mounted() {
            

            if (!this.ApplyButton) {
                // this.$watch('MinBathroom', (val) => {
                //     this.$emit('MinBathroom', {val});                
                // }, { immediate: false });

                // this.$watch('MaxBathroom', (val) => {
                //     this.$emit('MaxBathroom', {val});                
                // }, { immediate: false });
            }
        },

        methods: {
            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumBathrooms', value:null});       
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MaximumBathrooms', value:null});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            add() {
                var current = parseInt(this.$store.getters['ListingSearch/get']['MinimumBathrooms']);
                if(isNaN(current)) {
                    current = 0;
                }

                current++;

                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumBathrooms', value:current});                    
            },
            sub() {
                var current = parseInt(this.$store.getters['ListingSearch/get']['MinimumBathrooms']);
                if(isNaN(current)) {
                    current = 0;
                }

                //we dont want to get the integer in the negative
                if (current == 0) {
                    return;
                }

                current--;

                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'MinimumBathrooms', value:current});                    
            }
        }
    }
</script>
