<template>

    <div class="section--price-with-exchange">  
        <div class="price-with-exchange--price">
            <p class="float-left">
                {{ priceUpdated }} <span v-if="period" v-html="period"></span>
            </p>

            <p v-if="selected != defaultSelected" class="float-left">
                <a target="_blank" href="https://www.currenciesdirect.com/aff/v1/remax-malta.php?fbclid=IwAR0IY4N6tKaGyUtnVnleBAVp75zbRb5hT6LdiCP1snYTDZVnIz8SMhpayfo">
                        <!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                        <svg class="ml-20" width="130px" version="1.1" id="Artwork" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 170.1 44" style="enable-background:new 0 0 170.1 44;" xml:space="preserve">
                        <g>
                            <path style="fill: #e5720a" d="M22,44C9.9,44,0,34.1,0,22S9.9,0,22,0c7.8,0,15.1,4.2,19,11c0.3,0.5,0.1,1.2-0.4,1.6c-0.5,0.3-1.2,0.1-1.6-0.4
                                c-3.5-6.1-10-9.9-17-9.9C11.1,2.3,2.3,11.1,2.3,22S11.1,41.7,22,41.7c7,0,13.6-3.8,17.1-9.9c0.3-0.5,1-0.7,1.6-0.4
                                c0.5,0.3,0.7,1,0.4,1.6C37.1,39.8,29.8,44,22,44z"/>
                        </g>
                        <g>
                            <path style="fill: #e5720a" d="M22,35.6c-6.4,0-11.9-4.4-13.3-10.6c-0.1-0.3,0-0.7,0.2-1s0.5-0.4,0.9-0.4h24.4c0.3,0,0.7,0.2,0.9,0.4
                                c0.2,0.3,0.3,0.6,0.2,1C33.9,31.3,28.4,35.6,22,35.6z M11.3,25.9c1.6,4.5,5.8,7.4,10.7,7.4s9.1-3,10.7-7.4H11.3z"/>
                        </g>
                        <g>
                            <path class="st1" d="M86.1,20.1c0.6,0,1-0.4,1-1V12c0-2.4,1.6-3.9,3.9-3.9c0.7,0,1-0.5,1-0.9c0-0.5-0.3-0.9-1-0.9
                                c-3.2,0-5.8,1.8-5.8,5.3v7.5C85.1,19.7,85.5,20.1,86.1,20.1z"/>
                            <path class="st1" d="M100.3,7.3c0-0.5-0.3-0.9-1-0.9c-3.2,0-5.8,1.8-5.8,5.3v7.5c0,0.6,0.4,1,1,1s1-0.4,1-1V12
                                c0-2.4,1.6-3.9,3.9-3.9C100.1,8.2,100.3,7.7,100.3,7.3z"/>
                            <path class="st1" d="M81.5,28.7c-3.2,0-5.8,1.8-5.8,5.3v7.5c0,0.6,0.4,1,1,1s1-0.4,1-1v-7.1c0-2.4,1.6-3.9,3.9-3.9
                                c0.7,0,1-0.5,1-0.9C82.5,29.1,82.2,28.7,81.5,28.7z"/>
                            <path class="st1" d="M67.5,15.8c-0.3,0-0.5,0.1-0.6,0.3c-1.5,1.4-3.1,2.2-5.3,2.2c-3.9,0-6.9-3.2-6.9-7.3v-0.1
                                c0-4.1,2.9-7.3,6.9-7.3c2.2,0,3.7,0.8,5.1,2c0.2,0.1,0.4,0.2,0.7,0.2c0.5,0,1-0.5,1-1c0-0.4-0.2-0.6-0.4-0.8
                                c-1.7-1.4-3.5-2.3-6.3-2.3c-5.2,0-9,4.2-9,9.2V11c0,5.1,3.8,9.1,8.9,9.1c2.9,0,4.8-1,6.6-2.6c0.2-0.2,0.3-0.4,0.3-0.7
                                C68.4,16.3,68,15.8,67.5,15.8z"/>
                            <path class="st1" d="M139.2,16.5c-0.3,0-0.4,0.1-0.6,0.3c-1,0.9-2.2,1.6-3.7,1.6c-2.7,0-4.8-2.3-4.8-5.1v-0.1c0-2.8,2-5.1,4.7-5.1
                                c1.6,0,2.6,0.7,3.6,1.6c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,0.9-0.4,0.9-0.9c0-0.3-0.2-0.6-0.3-0.7c-1.2-1.1-2.6-1.9-4.8-1.9
                                c-3.8,0-6.7,3.1-6.7,6.9v0.1c0,3.7,2.9,6.8,6.7,6.8c2.2,0,3.7-0.9,5-2.1c0.2-0.2,0.3-0.4,0.3-0.6C140,17,139.6,16.5,139.2,16.5z"/>
                            <path class="st1" d="M165.9,12.3c-1.8-0.5-3.4-1-3.4-2.3l0,0c0-1.1,1-1.9,2.5-1.9c1.1,0,2.3,0.4,3.4,1c0.1,0.1,0.3,0.1,0.4,0.1
                                c0.5,0,0.9-0.4,0.9-0.8c0-0.4-0.2-0.7-0.5-0.8c-1.3-0.7-2.8-1.1-4.2-1.1c-2.6,0-4.5,1.5-4.5,3.8v0.1c0,2.4,2.3,3.1,4.3,3.7
                                c1.7,0.5,3.3,1.1,3.3,2.4v0.1c0,1.3-1.3,2-2.9,2c-1.6,0-2.8-0.6-3.8-1.5c-0.2-0.1-0.3-0.2-0.6-0.2c-0.5,0-0.9,0.4-0.9,0.8
                                c0,0.2,0.1,0.5,0.3,0.6c1.2,1.2,2.7,2,5,2c2.6,0,4.7-1.4,4.7-3.9v-0.1C170.1,13.8,167.9,12.9,165.9,12.3z"/>
                            <path class="st1" d="M58.7,24.5h-4.4c-0.5,0-1,0.5-1,1v15.7c0,0.6,0.5,1,1,1h4.4c5.6,0,9.4-3.9,9.4-8.9v-0.1
                                C68.2,28.3,64.3,24.5,58.7,24.5z M66.1,33.4c0,3.9-2.9,7-7.4,7h-3.4v-14h3.4C63.2,26.4,66.1,29.5,66.1,33.4L66.1,33.4z"/>
                            <path class="st1" d="M71.7,28.7c-0.5,0-1,0.5-1,1v11.7c0,0.6,0.4,1,1,1s1-0.4,1-1V29.7C72.7,29.1,72.3,28.7,71.7,28.7z"/>
                            <path class="st1" d="M107.7,38.8c-0.3,0-0.4,0.1-0.6,0.3c-1,0.9-2.2,1.6-3.7,1.6c-2.7,0-4.8-2.3-4.8-5.1l0,0c0-2.8,2-5.1,4.7-5.1
                                c1.6,0,2.6,0.7,3.6,1.6c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,0.9-0.4,0.9-0.9c0-0.3-0.2-0.6-0.3-0.7c-1.2-1.1-2.6-1.9-4.8-1.9
                                c-3.8,0-6.7,3.1-6.7,6.9v0.1c0,3.7,2.9,6.8,6.7,6.8c2.2,0,3.7-0.9,5-2.1c0.2-0.2,0.3-0.4,0.3-0.6C108.6,39.2,108.2,38.8,107.7,38.8
                                z"/>
                            <path class="st1" d="M116.8,40.3c-0.3,0-0.9,0.3-1.8,0.3c-1.3,0-2.2-0.6-2.2-2.2v-7.9h3.7c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9
                                h-3.7v-2.9c0-0.5-0.5-1-1-1c-0.6,0-1,0.5-1,1v12.7c0,2.7,1.6,3.7,3.7,3.7c0.8,0,1.8-0.2,2.5-0.5c0.3-0.2,0.6-0.4,0.6-0.8
                                C117.6,40.7,117.3,40.3,116.8,40.3z"/>
                            <circle class="st1" cx="71.7" cy="25.6" r="1.2"/>
                            <path class="st1" d="M143.4,6.4c-0.5,0-1,0.5-1,1v11.7c0,0.6,0.4,1,1,1s1-0.4,1-1V7.4C144.3,6.8,143.9,6.4,143.4,6.4z"/>
                            <circle class="st1" cx="143.4" cy="3.3" r="1.2"/>
                            <path class="st1" d="M107,20.1c2.2,0,3.7-0.8,5-2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.5-0.4-0.8-0.9-0.8c-0.2,0-0.4,0.1-0.6,0.2
                                c-1,0.9-2.2,1.5-3.8,1.5c-2.2,0-4.3-1.5-4.6-4.4h9.5c0.5,0,0.9-0.4,0.9-0.9c0-3.5-2.2-6.7-6.1-6.7c-3.6,0-6.3,3.1-6.3,6.9
                                C100.5,17.4,103.4,20.1,107,20.1z M106.7,8.1c2.5,0,3.9,2,4.2,4.3h-8.4C102.9,9.6,104.7,8.1,106.7,8.1z"/>
                            <path class="st1" d="M152.5,6.4c-3.6,0-6.3,3.1-6.3,6.9c0,4.1,2.9,6.8,6.6,6.8c2.2,0,3.7-0.8,5-2c0.2-0.2,0.3-0.4,0.3-0.6
                                c0-0.5-0.4-0.8-0.9-0.8c-0.2,0-0.4,0.1-0.6,0.2c-1,0.9-2.2,1.5-3.8,1.5c-2.2,0-4.3-1.5-4.6-4.4h9.5c0.5,0,0.9-0.4,0.9-0.9
                                C158.6,9.6,156.4,6.4,152.5,6.4z M148.3,12.3c0.4-2.7,2.2-4.3,4.2-4.3c2.5,0,3.9,2,4.2,4.3H148.3z"/>
                            <path class="st1" d="M88.9,28.7c-3.6,0-6.3,3.1-6.3,6.9c0,4.1,2.9,6.8,6.6,6.8c2.2,0,3.7-0.8,5-2c0.2-0.2,0.3-0.4,0.3-0.6
                                c0-0.5-0.4-0.8-0.9-0.8c-0.2,0-0.4,0.1-0.6,0.2c-1,0.9-2.2,1.5-3.8,1.5c-2.2,0-4.3-1.5-4.6-4.4h9.5c0.5,0,0.9-0.4,0.9-0.9
                                C95,31.9,92.8,28.7,88.9,28.7z M84.7,34.6c0.4-2.7,2.2-4.3,4.2-4.3c2.5,0,3.9,2,4.2,4.3H84.7z"/>
                            <path class="st1" d="M120.7,6.4c-3.2,0-5.8,1.8-5.8,5.3v7.5c0,0.6,0.4,1,1,1s1-0.4,1-1V12c0-2.4,1.6-3.9,3.9-3.9s3.6,1.4,3.6,3.9
                                v7.1c0,0.6,0.4,1,1,1c0.5,0,1-0.4,1-1v-7.5C126.3,8.3,123.8,6.4,120.7,6.4z"/>
                            <path class="st1" d="M76.6,20.1c3.2,0,5.6-1.8,5.6-5.3V7.4c0-0.6-0.4-1-1-1s-1,0.4-1,1v7.1c0,2.4-1.3,3.9-3.6,3.9
                                c-2.3,0-3.9-1.4-3.9-3.9V7.4c0-0.6-0.4-1-1-1c-0.5,0-1,0.4-1,1v7.5C70.7,18.3,73.4,20.1,76.6,20.1z"/>
                        </g>
                    </svg>
                </a>
            </p>
        </div>
        <div class="price-with-exchange--dropdown">
            <select v-on:change="currencyChange($event)" v-model="selected">
                <option v-for="(value) in currencies" :value="value">{{ value }}</option>
            </select>
        </div>
    </div>

</template>

<script>
    export default {

        props: ['price', 'period'],

        data(){
            return {
                currencies: Array,
                priceUpdated: 0,//this.price,
                defaultSelected: 'EUR',
                selected: 'EUR' //by default select EUR
            }
        },
        
        mounted() {
            this.getCurrencies();

            this.priceUpdated = new Intl.NumberFormat().format(this.price);

        },

        methods: {

            getCurrencies: function() {
                axios.get('/api/currencies')
                .then(response => {
                    this.currencies = response.data.data;
                });
            },

            currencyChange: function(event) {
                axios.get('/api/currency-convert/'+event.target.value+'/'+this.price)
                .then(response => {
                    this.priceUpdated = response.data.data;
                });
            }

        }
    }
</script>