<template>
    <div>

        <div class="dropdown">

            <button class="btn btn-light dropdown-toggle" :class="buttonMinimal" type="button" id="dropdownMenuButton-towns" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                <span class="dropdown-toggle--selected">{{ getFirstSelection }}</span> <span class="dropdown-toggle--count">{{ getTownIdsDataLength }}</span>
            </button>

            <div id="dropdownMenuButton-town-select" class="dropdown-menu minimal dropdown-small keep-open-on-click" aria-labelledby="dropdownMenuButton-towns">
                <towns-select :ApplyButton="apply" :toggle="this.toggle"></towns-select>
            </div>

        </div>

        <!--<label @click="Open = !Open">
            Town Container
            <span>{{ getTownIdsDataLength }}</span>
        </label>

        <div v-show="Open">
            <towns></towns>
        </div> -->       
    </div>
</template>

<script>
    export default {
        props: ['applyButton', 'minimal', 'text'],

        data: function() {
            return {
                //Open: false
                Towns: [],
                Selected: null,
                DisplayText: '',
                toggle: false,
            }
        },

        mounted() {
            this.Towns = this.$store.getters['staticTypes/StaticTypes']("Towns");

            this.DisplayText = this.text ?? 'select a town';

            var that =  this;

            // pass to toggle variable to componenet to highlight the search
            $('.dropdown').on('shown.bs.dropdown', function() {
                that.toggle = true;
            });

            // pass to toggle variable to component
            $('.dropdown').on('hidden.bs.dropdown', function() {
                that.toggle = false;
            });
        },

        computed: {
            apply() {
                return this.applyButton ?? true;
            },
            
            buttonMinimal() {
                if(this.minimal == true) {
                    return 'btn-sm';
                }
                else {
                    return 'btn-lg';
                }
            },

            getTownIdsDataLength() {
                if (Array.isArray(this.$store.getters['ListingSearch/get']['TownIds'])) {
                    var length = this.$store.getters['ListingSearch/get']['TownIds'].length;
                    return length;
                }
                else {
                    return 0;
                }
            },

            getFirstSelection() {
                this.Selected = (this.$store.getters['ListingSearch/get']['TownIds'])[0]
                var filtered = (this.Towns.filter(this.filterByListing))[0];
                
                return (filtered == undefined) ? this.DisplayText : filtered.Name;
            }, 
        },

        methods: {
            filterByListing: function(data) {
                return data.Id == this.Selected;
            }
        }
    }
</script>
