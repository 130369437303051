<template>
    <div class="section--main-selection-component">
        <div class="main-selection-component--header">
            <a href="/"> 
                <img title="RE/MAX - Malta" alt="RE/MAX - Malta" :src="logo"/> 
            </a>
            <a @click="$emit('closeSelectionComponent')">
                <icon-close></icon-close>
            </a>
        </div>

        <nav class="main-selection-component--contents">  
            <div> 
                <div class="row--items" v-for="item in menuData">
                    <div class="contents--item">
                        <div>
                            <a class="item" :href="item.url" :class="item.classes.join(' ')">
                                {{ item.title }}
                            </a> 
                            <main-child-open-component @closeSelectionComponent="$emit('closeSelectionComponent')" :text='item.title' :sub-menu="item.children" />
                        </div>
                    </div>
                </div>     
            </div>

            <div v-if="buttons">
                <div class="row--buttons" v-for="button in buttons">
                    <div class="contents--button">
                        <a class="btn btn-primary btn-block" :href="button.url"> 
                            {{ button.text }}
                        </a>
                    </div>
                </div>  
            </div>    
        </nav>
    </div>
</template>

<script>
    export default {
        props: ['logo', 'menus', 'buttons'],

        data() {
            return {
                'menuData': this.menus
            }
        },

        methods: {
            openDrawer(data) {
                data.open = true;
            }
        }
    }

</script>