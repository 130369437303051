<template>
    <div class="section--fields-dropdown">
        <div class="fields section--fields-dropdown-display">
            <!--<div class="section--fields--header">{{ trans('field-search-containers.select_location') }}</div>-->
            <div class="dropdown-header-search">

                <div class="dropdown-search">
                    <input type="text" id="search" class="form-control px-15" :placeholder="trans('field-search-containers.search_for_town_placeholder')" v-on:keyup="listFilter" />
                </div>
            </div>

            
            <div class="dropdown-elements px-15 mt-15">
                <div v-bind:class="limitDisplay ? 'limit-display': ''" v-for="town in townsShow" v-bind:key="town.Id">

                    <label class="checkbox-label">
                        <input type="checkbox" :name="'towns_'+id+'[]'" :id="'town_'+town.Id+'_'+id" v-model="componentTownIds" :value="town.Id" />
                        <span class="checkbox-text">{{ town.Name }}</span>  
                    </label>   

                </div>
            </div>
        </div>
            

        <div v-if="ApplyButton" class="fields-column-display--buttons top-shadow">
            <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
            <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
        </div>
        
    </div>
</template>

<script>
    export default {
        props: {
            ApplyButton: { type: Boolean|String },
            toggle: {
                type: Boolean,
                default: false,
            }
        },

        data: function() {
            return {
                towns: null,
                townsShow: null,
                // provinces: null,
                limitDisplay: true,
                id: null
            }
        },

        computed: {
            componentTownIds: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['TownIds']
                },
                set (val) {    
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value:val});                    
                }
            }
        },

        mounted() {
            //create random id - this is used to allow the same component to be 
            //displayed multiple times on a page by adding a random string to the id
            this.id = Math.random().toString(36).substring(2, 5);
        },

        created() {
            this.load();
        },

        methods: {

            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                this.towns = this.$store.getters['staticTypes/StaticTypes']("Towns");
                this.townsShow = this.towns;
                // this.provinces = this.$store.getters['staticTypes/StaticTypes']('Provinces');
            },

            listFilter(event) {
                this.Selected = event.target.value;
                var filtered = (this.towns.filter(this.filterByListing));
                this.townsShow = filtered;
                //return (filtered == undefined) ? 'select a province' : filtered.Name;
            },

            // toggleTowns(provinceId) {
            //     //empty selected towns
            //     this.clearClick();

            //     //get towns by province
            //     var townsOfProvince = this.towns.reduce(function(filtered, option) {
            //         if (option.ProvinceId == provinceId) {
            //             filtered.push(option.Id);
            //         }
            //         return filtered;
            //     }, []);

            //     this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'TownIds', value:townsOfProvince});                    
            // },

            filterByListing: function(data) {
                return (data.Name.toLowerCase().indexOf(this.Selected.toLowerCase())) > -1;
            }

        },

        watch: {

            /**
             * If the updated data is true, place focus on search input and seelct it automatically
             * @param {*} updatedData 
             */
            toggle(updatedData) {
                if(updatedData == true) {
                    var input = document.getElementById('search');
                    input.focus();
                    input.select();
                }
            },

        }
    }
</script>
