<template>
    <div class="section--agent-tel-listings-links">
        <div v-if="showNumber" class="agent--telephone-number">
            <a :href="'tel:'+internationalExtension+tel">{{ internationalExtension }} {{ tel }}</a>
        </div>
        <div class="agent--links">
            <div>
                <a @click="showNumber = !showNumber">
                    <icon-phone />
                    <span>{{ viewNumberText }}</span>
                </a>
            </div>
            <div v-if="tel">
                <a :href="whatsappLink + internationalExtension + tel" target="_blank">
                    <icon-whatsapp />
                    <span>{{ trans('misc.whatsapp_link') }}</span>
                </a>
            </div>
            <!-- <div v-if="associateUrl">
                <a :href="associateUrl">
                    <icon-listings />
                    <span>{{ trans('misc.view_associate_details') }}</span>
                </a>
            </div> -->
            <div v-if="officeUrl">
                <a :href="officeUrl">
                    <icon-listings />
                    <span>{{ trans('misc.view_office_details') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['tel', 'associateUrl', 'officeUrl'],

        data: function() {
            return {
                showNumber: false,
            }
        },

        computed: {
            viewNumberText: function() {
                return (this.showNumber == false) ? 'View Agent Number'
                                                : 'Hide Agent Number';
            },

            internationalExtension: function() {
                return process.env.MIX_PHONE_NUMBER_EXTENSION;
            },

            whatsappLink: function() {
                return process.env.MIX_WHATSAPP_CHAT_LINK;
            }
        },

        methods: {

        }

    }
</script>