<template>
    <div>
        <custom-map v-if="map_markers" :markers="map_markers"/>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                map_markers: null
            }
        },

        created() {
            this.loadBranches();
        },

        methods: {
            loadBranches: function() {
                var that = this;

                axios.get('/api/branches')
                .then(function (response) {
                    that.setMapData(response.data.data);
                })
                .catch(function (error) {
                })
            },

            setMapData: function(branches) {
                var array_temp = [];

                for(var i = 0; i < branches.length; i++)
                {
                    var this_branch = branches[i];

                    array_temp.push({ 
                        'town': this_branch.Name,
                        'coordinates' : {lat: this_branch.Latitude, lon: this_branch.Longitude}, 
                        'link' : '/branch/'+this_branch.OfficeId
                    });

                }

                this.map_markers = array_temp;
            }
        }
    }
</script>