<template>
    <div>
        <flickity v-if="localities.length" class="flickity" ref="flickity" :options="carouselSettings">
            <div class="carousel-cell" v-for="locality in localities">
                    <!--<a :href="'/localities/'+locality.slug">-->
                        <div :style="'background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0) 58%, rgba(0, 0, 0, 0.5)), url('+getLocalityBackgroundImage(locality)+')'">
                            {{ locality.title.rendered }}
                        </div>
                    <!--</a>-->
                </div>
        </flickity> 
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                localities: [],
                carouselSettings: {
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    cellAlign: 'left',
                    wrapAround: true,
                    freeScroll: false,
                    autoPlay: 2300,
                },
            }
        },

        mounted() {
            this.getLocalities();
        },

        methods: {
            getLocalities: function() {
                axios.get('/api/localities')
                .then(response => {    
                    this.localities = response.data.data.items.data;
                });
            },

            getLocalityBackgroundImage(locality) {
                if (locality['_embedded'] != undefined && locality['_embedded']['wp:featuredmedia'] != undefined) {
                    return locality['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return '';
                }
            }
        }
         
    }
</script>
