<template>
    <div class="section--preferred-agents">

        <div class="my-profile--actions">
            <a href="/account/my-profile">
                <icon-nav-arrow-left></icon-nav-arrow-left>
                <span>{{ trans('account.back_to_my_profile') }}</span>
            </a>
            <a href="/account/email-notifications">
                <icon-cog />
                <span>{{ trans('account.notification_preferences') }}</span>
            </a>
        </div>
        
        <div class="mt-30">
            <h4 class="mb-0">{{ trans('account.preferred_agents') }}</h4>
        </div>

        <loading-component v-if="isLoading"></loading-component>


        <div v-if="!isLoading">
            <div v-if="agents.length > 0">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 mt-30">
                    <div class="col mb-15 mb-lg-30" v-for="agent in agents" >
                        <agent-card :key="agent.AssociateId" :agent="agent"></agent-card>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="mt-15">{{ trans('account.preferred_agents_empty') }}</div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                agents: [],
                isLoading: true,
            }
        },

        mounted() {
            this.getPreferredAgents();
        },

        methods: {
            getPreferredAgents() {
                this.isLoading = true;

                axios.get('/favourite/agent')
                .then(response => {  
                    if (response.data.data != null) {
                        this.agents = response.data.data;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
    
</script>
