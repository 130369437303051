<template>
    <div>
        <label class="checkbox-label">
            <input type="checkbox" v-model="isPropertySelected" :disabled="checkboxDisable" />
            <span class="checkbox-text">{{ trans('misc.share_compare') }}</span>
        </label>
    </div>
</template>

<script>
    export default {
        props: ['property'],

        data: function() {
            return {
                checked: Boolean
            }
        },
        methods: {
            
            addProperty(image, mls) {
                this.$store.dispatch('shareCompare/addProperty', { image: image , mls: mls });                    
            },

            removeProperty(mls) {
                this.$store.dispatch('shareCompare/removeProperty', mls);                    
            },

            getPropertyImage() {
                return this.property.Image;
            },

            getPropertyMls() {
                return this.property.MLS;
            },

            // isPropertySelected() {
            //     return this.$store.getters['shareCompare/IsPropertyInList'](this.getPropertyMls());
            // },

        },

        computed: {
            isPropertySelected: {
                get() {
                    return this.$store.getters['shareCompare/IsPropertyInList'](this.getPropertyMls());
                },
                set(val) {
                    switch(val) {
                        case true:
                            this.addProperty(this.getPropertyImage(), this.getPropertyMls());
                        break;
                        case false:
                            this.removeProperty(this.getPropertyMls());
                        break;
                    }
                }
            },
            checkboxDisable: {
                get() {
                    //true if property count is equal or more to 4, and 
                    //property not in list ( to allow the ability to remove a property from the list )
                    return !(this.$store.getters['shareCompare/GetPropertiesCount'] < 4) && 
                    !this.$store.getters['shareCompare/IsPropertyInList'](this.getPropertyMls());
                }
            }
        }

    }
</script>