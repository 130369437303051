<template>
    <div class="section--blog-row-pagination">     
        <div class="blog-row-pagination--container">   
            <div class="row mb-40">
                <form @submit="submitSearch" class="form w-100">
                    <div class="input-group">
                        <input class="form-control" type="text" v-model="searchQuery" :placeholder="trans('components.search_generic_placeholder')" />
                        <div class="input-group-append">
                            <input type="submit" value="Search" class="btn btn-block" />
                        </div>
                    </div>
                </form>
                <div class="mt-15 w-100 text-center" v-if="searchQuery && submittedSearch">
                    <div type="button" @click="clearSearchQuery" class="text-uppercase small">
                        <font-awesome-icon class="text-danger mr-10" icon="fa-regular fa-trash-can"></font-awesome-icon>{{ trans('components.search_generic_clear') }}
                    </div>
                </div>
            </div>
            <loading-component v-if="loading"></loading-component>
            <div v-else>
                <div v-if="emptyResponse" class="post--no-posts">
                    <p>{{ trans('blog.no_posts_found') }}</p>
                </div>
                <div v-else>
                    <div v-for="post in posts" class="post--item--row"> 
                        <div class="item--image">
                            <!-- image -->
                            <img class="d-lg-none" :src="getBackgroundImageOfPost(post)">
                            <div class="d-none d-lg-block" :style="'background-image: url('+getBackgroundImageOfPost(post)+');'"></div>
                        </div>
                        <div class="item--content">
                            <!-- title -->
                            <div class="item--title">
                                <a :href="'/'+postLink+'/'+post.slug" class="text-decoration-none"><h4 v-html="post.title.rendered"></h4></a>
                            </div>
                            <!-- date 
                            <div class="item--date" v-if="type.toLowerCase() != 'localities'">
                                <p>{{ formatDate(post.date) }}</p>
                            </div>
                            -->
                            <!-- excerpt -->
                            <div class="item--excerpt">
                                <div class="text-dark" v-html="post.excerpt.rendered"></div>
                            </div>
                            <!-- read more -->
                            <div class="item--link">
                                <a :href="'/'+postLink+'/'+post.slug" class="link-arrow-sm">{{ trans('blog.read_more') }}</a>
                            </div>
                        </div>
                    </div> 
                    <div class="section--pagination" v-if="total_pages > 1">
                        <div v-if="pagination" v-for="page in pagination">  
                            <div v-if="page.page != null" class="pagination--button" :class="{ 'pagination--selected' : page.page == pageLoad }" v-on:click="executePagination(page.page)"><p>{{ page.text }}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['page', 'show', 'type', 'filter', 'tagFilter', 'order', 'taxonomy'],

        data: function() {
            return {
                posts: [],
                pageLoad: parseInt(this.page) ?? 1,
                total_pages: 0,
                pagination: [],
                maxPaginationNext: 3,
                maxPaginationPrev: 3,
                loading: true,
                emptyResponse: false,
                searchQuery: '',
                submittedSearch: false,
            }
        },
        
        mounted() {
            this.loadBlogs();
        },

        computed: {
            postLink() {
                return (this.type.toLowerCase() == 'posts') ? 'blog' : this.type.toLowerCase();  
            },
        },

        methods: {

            submitSearch: function(e) {
                e.preventDefault();
                this.submittedSearch = true;
                this.loadBlogs();
            },

            clearSearchQuery: function() {
                this.searchQuery = '';
                this.submittedSearch = false;
                this.loadBlogs();
            },

            executePagination(page) {
                var queryString = [];

                if (this.getUrlParameter('categories') != "" && this.getUrlParameter('categories') != null) {
                    queryString.push('categories='+this.getUrlParameter('categories'))
                }

                queryString.push('page='+page)
                
                var path = window.location.origin+window.location.pathname + '?'+queryString.join('&');

                window.location.replace(path);

                return false;
            },


            getUrlParameter(name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            },

            setPagination(page_count, total_count, this_page) {

                var total_pages = Math.ceil(total_count / page_count);

                this.pagination = [];
                this.pagination.push({
                    text: '< prev',
                    page: (this_page > 1) ? this_page - 1 : null
                });

                for (var i = this_page - this.maxPaginationPrev; i < this_page; i++){
                    if (i < 1) {
                        continue;
                    }

                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                 this.pagination.push({
                    text: this_page,
                    page: this_page
                });

                for (var i = this_page + 1; i <= this_page + this.maxPaginationNext; i++){
                    if (i > total_pages) {
                        continue;
                    }
                    
                    this.pagination.push({
                        text: i,
                        page: i
                    });
                }

                if ((this_page + this.maxPaginationNext) < total_pages) {
                    this.pagination.push({
                        text: '...',
                        page: null
                    });
                    this.pagination.push({
                        text: total_pages,
                        page: total_pages
                    });
                }

                this.pagination.push({
                    text: 'next >',
                    page: (this_page < total_pages) ? this_page + 1 : null
                });
                
                // maxPaginationNext
                // maxPaginationPrev

                // for (var i = 1; i <= Math.ceil(total_pages); i++){
                //     this.pagination.push({
                //         text: i,
                //         page: i
                //     });
                // }
            },

            loadMore: function() {
                //increment page
                this.pageLoad++;

                this.loadBlogs();
            },

            loadBlogs: function() {
                this.getPosts(this.show, this.type, this.filter, this.tagFilter, this.order, this.pageLoad, this.taxonomy);
            },

            getPosts: function(show, type, filter, tagFilter, order, pageLoad, taxonomy) {
                
                this.loading = true;
                var that = this;
                var options = this.buildApiCriteriaApiStructure(filter, tagFilter, order, taxonomy);
                
                axios.get('/api/'+type.toLowerCase(), {
                    params: {
                    "page": pageLoad,
                    'show': show,
                    'options': options
                    }
                })
                .then(response => {
                    if (response.data.data.items.data.length > 0) {
                        that.emptyResponse = false;

                        this.posts = this.posts = response.data.data.items.data;
                        this.total_pages = response.data.data.headers['total_pages'];
                        this.setPagination(this.show, response.data.data.headers['total_posts'], this.pageLoad);
                    }
                    else {
                        that.emptyResponse = true;
                    }
                    //this.posts = this.posts.concat(response.data.data.items.data);
                    
                })
                .catch(function (error) {
                    that.emptyResponse = true;
                })
                .then(function () {
                    that.loading = false;
                });  ;
            },

            buildApiCriteriaApiStructure: function(filter, tagFilter, order, taxonomy) {
                // [0] = order by(date,title,etc..) [1] = order(asc,desc)
                var response = {
                    '_embed': true
                };

                if (order != undefined) {
                    var order_group = order.split(' '); 
                    response.orderby = (order_group[0]).toLowerCase();
                    response.order = (order_group[1]).toLowerCase();
                }

                if (filter) {
                    var term_ids = [];

                    //if filter is string, parse it as JSON object
                    if (typeof filter == 'string') {
                        term_ids = JSON.parse(filter);
                    }
                    else {
                        term_ids = filter;
                    }

                    if (taxonomy == "category") {
                        response.categories = term_ids;
                    } else if (taxonomy == "guide_category") {
                        response.guide_category = term_ids;
                    } else if (taxonomy == "region") {
                        response.region = term_ids;
                    }
                }

                if (tagFilter) {
                    var tags = [];

                    //if filter is string, parse it as JSON object
                    if (typeof tagFilter == 'string') {
                        tags = JSON.parse(tagFilter);
                    }
                    else {
                        tags = tagFilter;
                    }

                    response.tags = tags;
                }

                if (this.searchQuery) {
                    response.search = encodeURIComponent(this.searchQuery);
                }
                
                return response;
            },

            getBackgroundImageOfPost(post) {
                if (post['_embedded'] != undefined && 
                    post['_embedded']['wp:featuredmedia'] != undefined && 
                    post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'] != undefined) {
                    return post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                }
                else {
                    return process.env.MIX_DEFAULT_IMAGE;
                }
            },

            formatDate(date) {
                var d = new Date(date);
                var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
                var mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
                var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

                return `${da}-${mo}-${ye}`;
            }
        }
         
    }
</script>
