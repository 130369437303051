<template>
    <l-map v-if="map_markers" style="width:100%; height:60vh;" :zoom="zoom" :center="center" class="rounded shadow">  
        <l-tile-layer :url="url"></l-tile-layer>          
        
        <l-marker v-for="(marker, index) in map_markers" :key="marker.name" :lat-lng="marker.coordinates">
            <l-popup> 
                <div class="popup--content">
                    <div class="popup--content--image">
                        <div :style="'background-image: url('+marker.image+')'"></div>
<!--                         <a :href="marker.link">
                            <div class="image" :style="'background-image: url('+marker.image+')'">
                            &nbsp;
                            </div>
                        </a> -->
                    </div>
                    <div class="popup--content--text">
                        <a class="popup--content--text--name" :href="marker.link" v-html="marker.name"></a>
                        <p class="address">{{ marker.address }}</p>
                        <branch-map-telephone :tel="marker.phone "></branch-map-telephone>
                    </div>
                </div>
            </l-popup>
        </l-marker>

    </l-map>
</template>

<script>

    import { Icon }  from 'leaflet'
    import {LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

    Vue.component('l-map', LMap)
    Vue.component('l-tile-layer', LTileLayer)
    Vue.component('l-marker', LMarker)
    Vue.component('l-popup', LPopup)


    // this part resolve an issue where the markers would not appear
    delete Icon.Default.prototype._getIconUrl;

    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        data: function() {
            return {
                url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
                zoom: 11,
                center: [35.9281689, 14.359101], //centered malta .. ideally this is put as env variable
                map_markers: []
            }
        },
        
        mounted() {
            this.getBranches();
        },
        
        methods: {

            getBranches() {
                var that = this;
                axios.get('/api/branches')
                .then(function (response) {
                    that.setMapData(response.data.data);
                })
                .catch(function (error) {

                })
                .finally(function () {
                });
            },

            setMapData(listings) {
                var temp =[];
                for (let key in listings) {
                    if(listings.hasOwnProperty(key)) {
                        var this_object = listings[key];

                        var object = { 
                            'name': this_object.Name,
                            'coordinates' : {lat: this_object.Latitude, lon: this_object.Longitude}, 
                            'address' : this_object.Address,
                            'phone' : this_object.PhoneNumber,
                            'image' : this_object.Photos[0].ImageURL,
                            'link' : '/branch/'+this_object.ShortLink
                        };
                        temp.push(object)
                    }
                }
                
                this.map_markers = temp;
            }
        }
        
    }

</script>