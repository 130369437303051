<template>
    <div>
        <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" :class="buttonMinimal" type="button" id="dropdownMenuButton-price-range" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="dropdown-toggle--selected">{{ trans('field-search-containers.price') }}</span>
            </button>
            <div class="dropdown-menu dropdown-medium keep-open-on-click" aria-labelledby="dropdownMenuButton-price-range">
                <price-range-dropdown :ApplyButton="true" :showSliderRange="true"></price-range-dropdown>
            </div>
        </div>      
    </div>
</template>

<script>
    export default {
        props: ['minimal', 'text'],

        data: function() {
            return {
                Open: false
            }
        },

        computed: {
            buttonMinimal() {
                if(this.minimal == true) {
                    return 'btn-sm';
                }
                else {
                    return 'btn-lg';
                }
            },
        }
    }
</script>
