<template>
    <div>
        <label @click="Open = !Open">
            {{ trans('field-search-containers.bathrooms') }}
        </label>

        <div v-show="Open">
            <bathroom></bathroom>
        </div>        
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                Open: false
            }
        },
    }
</script>
