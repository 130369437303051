<template>

    <div>  
        <component v-if="load" :is="load"> </component>
    </div>

</template>

<script>
    export default {

        props: ['icon'],

        data: function() {
            return {
                'load': null,
                'default': 'icon-size'
            }
        },

        mounted() {
            this.load = (Vue.options.components['icon-'+this.icon] === undefined) ? this.default
                                                                                 : 'icon-'+this.icon;
        }

    }
</script>