<template>
    <span class='icon-garage' v-bind:class="span">
      <svg v-bind:class="color" aria-hidden="true" :height="height" :width="width" focusable="false" data-prefix="fab" data-icon="garage" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M597.9 110.1 346.3 5.3a68.1 68.1 0 0 0 -52.6 0L42.1 110.1A68.1 68.1 0 0 0 0 173.2V496a16 16 0 0 0 16 16H32a16 16 0 0 0 16-16V173.2a20.2 20.2 0 0 1 12.5-18.8L312.2 49.6a20.5 20.5 0 0 1 15.7 0L579.5 154.4A20.4 20.4 0 0 1 592 173.2V496a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V173.2A68.1 68.1 0 0 0 597.9 110.1zM504 192H136a40 40 0 0 0 -40 40V496a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V352H496V496a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V232A40 40 0 0 0 504 192zm-8 128H144V240H496zM368 416H272a16 16 0 0 0 0 32h96a16 16 0 0 0 0-32z"/></svg>
    </span>
  </template>
  
  <script>
      export default {
  
          props: {
            span: {
              type: String,
              default: ''
            },
            color: {
              type: String,
              default: ''
            },
            height: {
              type: [Number, String],
              default: 24
            },
            width: {
              type: [Number, String],
              default: 24
            }
        },
  
      }
  </script>