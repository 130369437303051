<template>
    <div  v-if="showComponent" class="section--show-properties-carousel" :style="getSectionBgColour">
        <div>
            <div class="component-structure--heading">
                <div class="component-structure--heading-header">
                    <h2 class="h1">{{ acfFields.header }}</h2>
                </div>
            </div>

            <div class="component-structure--carousel">
                <div>
                    <loading-component v-if="isLoading || this.isSliderLoading"></loading-component>
                    <div :class="( this.isSliderLoading ? 'invisible' : 'visible' )">
                        <flickity v-if="properties.length" @init="onInit" class="flickity" ref="flickity" :options="carouselSettings">
                            <property-card-single class="carousel-cell" v-for="property in properties" v-bind:key="property.MLS" :property="property"></property-card-single> 
                        </flickity> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['acfFields'],

        data: function() {
            return {
                properties: [],
                carouselSettings: {
                    initialIndex: 0,
                    prevNextButtons: false,
                    pageDots: false,
                    freeScroll: false,
                    cellAlign: 'left',
                    autoPlay: 2300,
                    contain: true,
                    adaptiveHeight: true,
                    resize: true,
                },
                isLoading: false,
                isSliderLoading: true,
                showComponent: false,
            }
        },

        computed: {
            getSectionBgColour() {
                return (this.acfFields.background_colour) ? 'background-color:'+this.acfFields.background_colour+';'
                : '';
            },
        },

        mounted() {
            let recent_properties = this.getRecentProperties();

            if (recent_properties != null) {
                this.showComponent = true;

                let payload = [{
                    key: 'Favourites',
                    value: recent_properties.join(',')
                }];

                this.getProperties(payload);
            }
        },

        methods: {
            onInit() {
                var that = this;
                this.$refs.flickity.once('settle', function () {
                    if(that.isSliderLoading) {
                        that.$refs.flickity.resize();
                        that.isSliderLoading = false;
                    }
                });
            },

            getRecentProperties: function() {
                let property_ids = localStorage.getItem('recentProperties');

                if (property_ids == null) {
                    return null;
                }
                else {
                    return JSON.parse(localStorage.getItem('recentProperties'));
                }

            },

            getProperties: function(filteringParameters) {
                this.isLoading = true;
                var filteringParameters = this.mapObjectToAssocArray(filteringParameters);
   

                var query = this.$helpers.convertObjectInQueryParameters(filteringParameters);
                
                axios.get('/api/properties?'+query)
                .then(response => {  
                    this.properties = response.data.data.Properties;
                    this.isLoading = false;
                });
            },

            mapObjectToAssocArray: function(object) {
                var response = [];

                for (var obj in object) {
                    if (object.hasOwnProperty(obj)) {
                        response[object[obj].key] = object[obj].value;
                    }
                }

                return response;
            }
        }
         
    }
</script>
