<template>
    <div class="section--agent-tel-listings-links">
        <div v-if="showNumber" class="agent--telephone-number">
            <a :href="'tel:'+internationalExtension+tel">{{ internationalExtension }} {{ tel }}</a>
        </div>
        <div class="agent--links">
            <div>
                <a @click="showNumber = !showNumber">
                    <icon-phone />
                    <span>{{ viewNumberText }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['tel'],

        data: function() {
            return {
                showNumber: false,
            }
        },

        computed: {
            viewNumberText: function() {
                return (this.showNumber == false) ? 'View Office Number'
                                                : 'Hide Office Number';
            },

            internationalExtension: function() {
                return process.env.MIX_PHONE_NUMBER_EXTENSION;
            },
        },

    }
</script>