<template>
    <div class="section--burger-icon-toggle">
        <a @click="isOpen = !isOpen"> 
            <icon-nav-burger />
        </a>

        <main-selection-component v-if="isOpen" :logo="logo" :menus="menu" :buttons="componentButton" @closeSelectionComponent="isOpen = !isOpen"></main-selection-component>
    </div>
</template>

<script>

    export default {
        props: ['logo', 'menu'],

        data() {
            return {
                isOpen: false,
                componentButton: [],
            }
        },

        mounted() {
            var button1 = this.loginButton;

            this.componentButton.push(button1);
        },

        computed: {
            loginButton() {
                return (this.$store.getters['user/isLoggedIn']) 
                ? {
                    text: 'Logout',
                    url: '/logout'
                } 
                : {
                    text: 'Login',
                    url: '/login'
                };
            }
        }
    }

</script>