<template>
    <div class="container">
        <loading-component v-if="loading" theme="internal"></loading-component>
        <contact-form v-if="contact_form" :acf-fields="contact_form" :email-replace="emailReplace" :field-replace="fieldsReplace"></contact-form>
    </div>
</template>

<script>
    export default {
        props: ['emailReplace', 'fieldsReplace'],
        data: function() {
            return {
                contact_form: null,
                loading: true,
            }
        },
        mounted() {
            this.getContactForm();
        },
        methods: {         
            getContactForm: function() {
                axios.get('/api/contact_form/callback')
                .then(response => {
                    this.contact_form = {};
                    this.contact_form.contact_form_parsed = response.data.data.contact_form_parsed;
                    this.contact_form.contact_form = {};
                    this.contact_form.contact_form.ID = response.data.data.contact_form_id;
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>
