<template>
    <div class="contract-type--fields">
        <div>
        	<label>{{ trans('field-search-containers.periodical_payment_types') }}</label>
        	<select v-model="PeriodicalPaymentTypeId">
        		<option :value="null">{{ trans('field-search-containers.default_no_value_periodical_payment_type') }}</option>
        		<option v-for="(periodicalPaymentType) in staticPeriodicalPaymentTypes" :value="periodicalPaymentType.Id">{{ periodicalPaymentType.Name }}</option>
        	</select>
        </div>
    </div>
</template>

<script>
    export default {

        data: function() {
            return {
                staticPeriodicalPaymentTypes: null,
            }
        },

        created() {
            this.load();
        },

        methods: {

            load: function() {
                this.staticPeriodicalPaymentTypes = this.$store.getters['staticTypes/StaticTypes']("PeriodicalPaymentTypes");
            },
            
        },

        computed: {
            PeriodicalPaymentTypeId: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['PeriodicalPaymentTypeId'];
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'PeriodicalPaymentTypeId', value: val});                     
                }
            },
        }
    }
</script>
