<template>
    <div class="section--email-notification">

        <div class="my-profile--actions">
            <a href="/account/my-profile">
                <icon-nav-arrow-left></icon-nav-arrow-left>
                <span>{{ trans('account.back_to_my_profile') }}</span>
            </a>
        </div>

        <div class="mt-30">
            <h4 class="mb-0">{{ trans('account.email_notifications') }}</h4>
        </div>

        <loading-component v-if="isLoadingUser && isLoadingNotifications"></loading-component>

        <div v-else>
            <div v-if="searches.length && !isLoadingSearches" class="email-notification--row">
                <div class="email-notification--column">
                     <div class="email-notification--option" v-if="notificationsLoaded && userNotificationsLoaded" v-for="notification in notifications" :key="notification.name">   
                        <email-notifications-box @updatedNotification="updatedNotificationListener" :id="notification.name" :name="notification.display_name" :current="getNotificationCurrent(notification.name)"></email-notifications-box>            
                    </div>
                </div>
            </div>
            <div class="mt-15" v-else>
                <p>Save some searches to enable notifications.</p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                searches: [],
                notifications: [],
                userNotifications: [],
                notificationsLoaded : false,
                userNotificationsLoaded : false,
                isLoadingUser: true,
                isLoadingNotifications: true,
                isLoadingSearches: true,
            }
        },

        mounted() {
            this.getNotifications();
            this.getUserNotifications();
            this.getSavedSearches();
        },

        methods: {
            getNotificationCurrent: function(name)
            {
                if (this.userNotifications.indexOf(name) >= 0) {
                    return true;
                }
                else {
                    return false;
                }
            },

            updatedNotificationListener: function(data) {
                switch(data.value) {
                    case true:
                        this.addUserNotification(data.id);
                        break;
                    case false:
                        this.removeUserNotification(data.id);
                        break;
                }
            },

            getNotifications: function() {
                axios.get('/api/notifications')
                .then(response => {  
                    this.notifications = response.data;
                    this.notificationsLoaded = true;
                    this.isLoadingNotifications = false;
                });
            },

            getUserNotifications: function() {
                axios.get('/notification/user')
                .then(response => {  
                    this.userNotifications = response.data;                   
                    this.userNotificationsLoaded = true;
                    this.isLoadingUser = false;
                });
            },

            addUserNotification: function(notification) {
                axios.post('/notification/user/'+notification)
                .then(response => {
                    
                });
            },
            
            removeUserNotification: function(notification) {
                axios.delete('/notification/user/'+notification)
                .then(response => {  
                    // console.log(response);
                });
            },

            getSavedSearches: function() {
                this.isLoadingSearches = true;

                axios.get('/saved-searches')
                .then(response => {  
                    //convert return object to array so that the data is reactive
                    this.searches = Object.entries(response.data.data).map(([key, value]) => ({key,value}));
                    this.isLoadingSearches = false;
                });
            },

        }
    }
    
</script>
