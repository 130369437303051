<template>
    <div class="marketing-categories--fields">
        <div class="section--fields--header"
            data-toggle="collapse" 
            data-target="#marketing-categories--fields-collapse" 
            aria-expanded="false" 
            aria-controls="marketing-categories--fields-collapse">
            {{ trans('field-search-containers.select_marketing_categories') }}
        </div>

        <div class="collapse" :class="(CloseByDefault == true) ? '' : 'show'" id="marketing-categories--fields-collapse">
            <div class="container-fluid">
                <div class="section--fields-column-display">
                    <div v-bind:class="limitDisplay ? 'limit-display show-8': ''" v-for="category in MarketingCategories" v-bind:key="category.Id"> 
                        <label class="checkbox-label">
                            <input type="checkbox" v-model="ComponentSelectedMarketingCategories" :value="category.Id" />
                            <span class="checkbox-text">{{ category.Name }}</span>  
                        </label>        
                    </div>
                </div>
            </div>

            <div class="fields-column-display--show-all" v-if="MarketingCategories.length > 16">
                <a @click="limitDisplay = !limitDisplay">{{ ShowAllText }}</a>
            </div>

            <div v-if="ApplyButton" class="fields-column-display--buttons">
                <a v-on:click="clearClick" class="no-style text-secondary font-weight-bold mr-25">{{ trans('field-search-containers.clear') }}</a>
                <a v-on:click="applyClick" class="link-arrow-sm no-style text-primary font-weight-bold">{{ trans('field-search-containers.apply') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['ApplyButton', 'CloseByDefault'],

        data: function() {
            return {
                MarketingCategories: null,
                //ComponentSelectedInternalFeatures: this.SelectedInternalFeatures,
                limitDisplay: true
            }
        },

        computed: {
            ShowAllText: function() {
                return (this.limitDisplay) ? "Show all " + this.MarketingCategories.length + " marketing categories"
                                            : "Show Less";
            },

            ComponentSelectedMarketingCategories: {
                get () {                    
                    return this.$store.getters['ListingSearch/get']['SelectedMarketingCategories']
                },
                set (val) {                                        
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedMarketingCategories', value:val});                    
                }
            }
        },

        mounted() {
            if (!this.ApplyButton) {
                // this.$watch('ComponentSelectedInternalFeatures', (val) => {
                //     this.$emit('update:SelectedInternalFeatures', { val });                
                // }, { immediate: false });
            }
        },

        created() {
            this.load();
        },

        methods: {
            clearClick: function() {
                this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SelectedMarketingCategories', value:[]});       
            },

            applyClick: function() {
                this.$root.$emit('search-submit');
            },

            load: function() {
                this.MarketingCategories = this.$store.getters['staticTypes/StaticTypes']("MarketingCategories");
            }
        }
    }
</script>
