<template>
 	<div class="section--property-sort dropdown">
		<button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuSort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<span class="dropdown-toggle--selected">{{ getFirstSelection }}</span>
		</button>
		<div class="dropdown-menu dropdown-menu-filter" aria-labelledby="dropdownMenuSort">
			<div class="property-sort--sort-item" v-for="order in orders" :key="order.Id" @click="setSortBy(order.Name)">
				{{ order.Name }}
			</div>
		</div>
	</div> 
</template>

<script>
	export default {
		data() {
			return {
				orders: [],
				Selected: '',
			}
		},
		
		computed: {
			getFirstSelection() {
				this.Selected = (this.$store.getters['ListingSearch/get']['SortBy']) || '-- Sort --';
				
				return this.Selected;
			}
		},

		mounted() {
			this.orders = this.$store.getters['staticTypes/StaticTypes']("ListingSearchSortingTypes");
		},

		methods: {
			setSortBy(val) {
				this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'page', value:1});  
				this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: 'SortBy', value:val});  
				this.$root.$emit('search-submit');  
			}
		},

	}
</script>