<template>
    <div> 

        <div class="dropdown">
            <button class="btn btn-light dropdown-toggle"  :class="buttonMinimal" type="button" id="dropdownMenuButton-provinces" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="dropdown-toggle--selected">{{ getFirstSelection }}</span> <span class="dropdown-toggle--count">{{ getProvincesIdsDataLength }}</span>
            </button>
            <div class="dropdown-menu dropdown-medium keep-open-on-click" aria-labelledby="dropdownMenuButton-provinces">
                <provinces :ApplyButton="true"></provinces>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['minimal', 'text'],

        data: function() {
            return {
                Provinces: [],
                Selected: null,
                DisplayText: ''
                //Open: false
            }
        },

        mounted() {
            this.Provinces = this.$store.getters['staticTypes/StaticTypes']("Provinces");

            this.DisplayText = this.text ?? 'select a province';
        },

        computed: {
            buttonMinimal() {
                if(this.minimal == true) {
                    return 'btn-sm';
                }
                else {
                    return 'btn-lg';
                }
            },
            getProvincesIdsDataLength() {
                if (Array.isArray(this.$store.getters['ListingSearch/get']['ProvinceIds'])) {
                    var length = this.$store.getters['ListingSearch/get']['ProvinceIds'].length;
                    return (length > 1) ? '+' + length : length;
                }
                else {
                    return 0;
                }
            },
            getFirstSelection() {
                this.Selected = (this.$store.getters['ListingSearch/get']['ProvinceIds'])[0]
                var filtered = (this.Provinces.filter(this.filterByListing))[0];
                
                return (filtered == undefined) ? this.DisplayText : filtered.Name;
            }
        },

        methods: {
            filterByListing: function(data) {
                return data.Id == this.Selected;
            }
        }
    }
</script>
