<template>
    <div>
        <sale-to-let :show-buy="showBuy" :show-rent="showRent"></sale-to-let>
    </div>
</template>

<script>

    export default {
        props: ['showBuy', 'showRent']
    }

</script>
