const state = {
  //get data from local storage
  StaticTypes: {},
};

const getters = {
  StaticTypes: (state) => (id) => {
    return state.StaticTypes[id];
  }
};

const mutations = {
  setLookups(state, value) {
    state.StaticTypes = value;
  },

  setLookupsInLocalStorage(state, value) {
    var expires = (24 * 60 * 60) * 1000;  // default: milliseconds for 1 day
    //since local storage does not expire, implement manual expiration.
    value.timestamp = Date.now() + expires;
    localStorage.setItem("StaticTypes", JSON.stringify(value));
  }
};

function addListingTypeIdToPropertyMacroType(data) {
  var macroTypes = [];
  //create an array having IDs as array index 
  //(this is to facilitate the searching in the next loop)
  for (var macroType in data.PropertyMacroTypes) {
    var thisMacroType = data.PropertyMacroTypes[macroType];
    macroTypes[thisMacroType.Id] = thisMacroType;
  }

  //loop through all property types and get the listing type id according to its parent
  for (var type in data.PropertyTypes) {
    data.PropertyTypes[type].ListingTypeId = macroTypes[data.PropertyTypes[type].PropertyMacroTypeId].ListingTypeId;
  }

  return data;
}

const actions = {

  //sync call, load before continue
  async loadLookups({ dispatch, commit }) {

    if (localStorage.getItem('StaticTypes') !== null) {
      var lookups = JSON.parse(localStorage.getItem('StaticTypes'));

      dispatch('getLookupsFromApi');

      var current_time = Date.now();

      //if storage is expired, reload
      if (lookups.timestamp < current_time) {
        await dispatch('getLookupsFromApi');
      }
      else {
        //retrieve state from localStorage
        commit('setLookups', lookups)
      }
    }
    else {
      //items not in storage, retrieve.
      await dispatch('getLookupsFromApi');
    }
  },

  getLookupsFromApi({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/get_static_types')
        .then(function (response) {
          //modify data - include ListingTypeId also on lower PropertyType level
          response.data.data = addListingTypeIdToPropertyMacroType(response.data.data);

          commit('setLookups', response.data.data);
          commit('setLookupsInLocalStorage', response.data.data);
          //resolve promise
          resolve();
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {

        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};