<template>
  <div class="section--agents-search">
    <div class="agents-search--container">
      <div class="agents-search--row py-35">
          <div class="search-for-agent">
            
            <div v-if="filterName && submittedSearch" class="pb-15">
              <h4>{{ trans('components.searches_for') }} '{{ filterName }}'</h4>
            </div>

            <div>
              <form method="GET" action="/branch-search">
                  <div class="input-group">
                    <input name="s" v-model="filterName" type="text" class="form-control" :placeholder="trans('components.search_by')" aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button" @click="searchOffices()">
                        {{ trans('components.search') }}
                      </button>
                    </div>
                  </div>
              </form>
            </div>
          </div>

<!--           <div class="agent-sort">
            <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuSort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="dropdown-toggle--selected">{{ getSortBy }}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-filter" aria-labelledby="dropdownMenuSort">
              <div class="" v-for="sort in officeSort" :key="sort.Id" @click="setSortBy(sort)">
                  {{ sort.Name }}
              </div>
            </div>
          </div>  -->
      </div>

      <div class="divider-section"></div>

      <div class="agents-search--row elements-after-another mobile-sticky">
        <div class="col d-flex flex-wrap">
          
          <div class="language-filter mr-15 mb-15">
            <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuLanguages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="dropdown-toggle--selected">{{ getFilterLanguage }}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-filter" aria-labelledby="dropdownMenuLanguages">
              <div class="" v-for="language in languages" :key="language.Id" @click="setFilterLanguage(language.Name)">
                  {{ language.Name }}
              </div>
            </div>
          </div>

          <div class="sorting-filter mr-15 mb-15">
            <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuSort" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="dropdown-toggle--selected">{{ getSortBy }}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-filter" aria-labelledby="dropdownMenuSort">
              <div class="" v-for="sort in officeSort" :key="sort.Id" @click="setSortBy(sort)">
                  {{ sort.Name }}
              </div>
            </div>
          </div>

          <div hidden class="sorting-filter">
            <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuOfficeType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="dropdown-toggle--selected">{{ getOfficeTypeId }}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-filter dropdown-menu-right" aria-labelledby="dropdownMenuOfficeType">
              <div class="" v-for="type in officeType" :key="type.Id" @click="setOfficeTypeId(type)">
                  {{ type.Name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="agents-search--row">
        <loading-component v-if="isLoading"></loading-component>

        <div class="col-12" v-if="offices.length > 0">
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
            <div class="col mb-15 mb-lg-30" v-for="office in offices" >
              <office-card :key="office.OfficeId" :office="office"></office-card>
            </div>
          </div>   
        </div>
        
        <div v-if="offices.length == 0 && isLoading == false" class="preferred-agents--row align-items-center justify-content-center d-flex">
            <div class="col-12 text-center py-50">
              <span class="h4">No branches found</span>
            </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['term', 'prePopOffices'],

    data() {
      return {
        officeSort: [
          {
            Id: 'NameAsc',
            Name: 'Name Ascending'
          },
          {
            Id: 'NameDesc',
            Name: 'Name Descending'
          }
        ],
        languages: [],
        sortBy: null,
        sortByShow: null,
        filterLanguage: null,
        filterName: '',
        offices: [],
        isLoading: false,
        submittedSearch: false,
        officeType: [
          {
            Id: null,
            Name: 'Any'
          },
          {
            Id: '1',
            Name: 'Letting'
          },
          {
            Id: '2',
            Name: 'Sales'
          }
        ],
        officeTypeId: null,
        officeTypeIdShow: null,
      }
    },

    mounted() {
      // this.selected = 
      this.getLanguages();

      this.offices = this.prePopOffices;
      this.filterName = this.term;
    },

    computed: {
      getSortBy() {
        return this.sortByShow || 'Sort';
      },
      getFilterLanguage() {
        return this.filterLanguage || 'Language';
      },
      getOfficeTypeId() {
        return this.officeTypeIdShow || 'Office Type';
      }
    },

    methods: {
      setSortBy(val) {
        this.sortBy = val.Id;
        this.sortByShow = val.Name;
        this.searchOffices();
      },

      setFilterLanguage(val) {
        if(val == 'Any') {
          val = null;
        }

        this.filterLanguage = val;
        this.searchOffices();
      },

      setOfficeTypeId(val) {
        this.officeTypeId = val.Id;
        this.officeTypeIdShow = val.Name;
        this.searchOffices();
      },

      getLanguages() {
        this.languages = [
          {
            Id : null,
            Name: 'Any',
          },
          {
            Id : 'English',
            Name: this.trans('components.english'),
          },
          {
            Id : 'Maltese',
            Name: this.trans('components.maltese'),
          },
          {
            Id : 'French',
            Name: this.trans('components.french'),
          },
          {
            Id : 'German',
            Name: this.trans('components.german'),
          },
          {
            Id : 'Spanish',
            Name: this.trans('components.spanish'),
          },
        ];
      },

      searchOffices() {
        this.isLoading = true;
        this.offices = [];
        this.submittedSearch = true;
        
        axios.get('/api/branches', {
          params: {
            s: this.filterName,
            sorting: this.sortBy,
            languages: (this.filterLanguage == null) ? this.filterLanguage : [this.filterLanguage],
            office_type_id: this.officeTypeId,
          }
        })
        .then(response => {
          this.isLoading = false;
          this.offices = response.data.data;
        });
      },
    },

  }
</script>