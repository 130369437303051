<template>

    <form class="mt-n40" @submit.prevent>

        <label>{{ trans('field-search-containers.ai_search_label') }}</label>

        <div class="input-group property-search-quick--ai-search">
            <textarea class="form-control" :class="promptClass" v-model="prompt"></textarea>
        </div>

        <template v-if="promptError != ''"><span class="text-danger">{{ this.promptError }}</span></template>

        <div class="property-search-quick--selection-buttons justify-content-md-end">
            <button v-if="disableAISearchButton" type="submit" class="btn btn-primary" disabled><icon-loading span="pr-10"/>{{ trans('properties.search_properties') }}</button>
            <button v-else type="submit" @click="onSubmit" class="btn btn-primary">{{ trans('properties.search_properties') }}</button>
        </div>

    </form>

</template>

<script>
export default {
    props: {
        oldPrompt: String,
    },

    data() {
        return {
            disableAISearchButton: false,
            prompt: this.oldPrompt,
            promptClass: '',
            promptError: '',
        }
    },

    methods: {
        /**
         * Submits the AI search form
         */
        onSubmit() {
            this.disableAISearchButton = true;
            this.promptClass = '';
            this.promptError = '';
            var request = { prompt: this.prompt };

            axios.post('/api/properties/ai/listings-criteria', request)
                .then(response => {
                
                    this.$store.dispatch('ListingSearch/updateFullSearchCriteria', response.data.data.Criteria);
                    var urlParams = this.$helpers.convertObjectInQueryParameters(this.$store.getters['ListingSearch/get']);

                    //redirect to properties with params attaced
                    window.location.href = '/listings?' + urlParams;
                }).catch(error => {

                    this.promptClass = 'invalid';
                    
                    if (error.response.status === 422) {
                        this.promptError = error.response.data.errors.prompt[0];
                    }
                    else {
                        this.promptError = error.response.data.message;
                    }

                }).finally(() => {
                    this.disableAISearchButton = false;
                })
        }
    }
    
}
</script>