<template>
  <span class='icon-circle' v-bind:class="span">
    <svg xmlns="http://www.w3.org/2000/svg" v-bind:class="color" aria-hidden="true" :height="height" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/></svg>
  </span>
</template>

<script>
  export default {

      props: {
        span: {
          type: String,
          default: ''
        },
        color: {
          type: String,
          default: ''
        },
        height: {
          type: [Number, String],
          default: 24
        }
    },

  }
</script>