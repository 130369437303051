<template>
    <div class="section--link-pods">
        <div class="link-pods--title">{{ trans('blog.categories') }}</div>
        <loading-component v-if="loading"></loading-component> 
        <div class="link-pods--group">

          <div v-for="category in categories">
            <a v-if="category.children.length == 0" :href="'/'+postLink+'?categories='+category.cat_ID" class="link-pods--item">
                <div v-html="category.name" class="item--excerpt link-arrow-sm"></div>
            </a>

            <div v-else class="link-pods--item">
              <div class="item--excerpt" data-toggle="collapse" :data-target="'#collapse'+category.cat_ID" aria-expanded="false" :aria-controls="'collapse'+category.cat_ID">
                <div class="font-weight-bold" v-html="category.name"></div>
                <span class="collapse-icon"></span>
            </div>
              <div class="collapse" :id="'collapse'+category.cat_ID">
                <div class="mt-20">
                    <a :href="'/'+postLink+'?categories='+category.cat_ID" class="d-flex small font-weight-medium my-10">{{ trans('components.view_all') }}</a>  
                    <a v-for="child in category.children" :href="'/'+postLink+'?categories='+child.cat_ID" v-html="child.name" class="d-flex small font-weight-medium my-10"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ['slug', 'postType'],

        data: function() {
            return {
                categories: [],
                loading: true,
            }
        },
        
        mounted() {
            this.getCategories();
        },

        computed: {
            postLink() {
                return this.slug.toLowerCase();  
            },
        },

        methods: {
            getCategories: function() {
                axios.get('/api/' + this.postType + '/categories/hierarchical', {})
                .then(response => {    
                    this.categories = response.data.data;
                    // console.log(response.data);
                    this.loading = false;
                });
            },
        }
         
    }
</script>
