<template>
    <div class="section--property-search-quick property-search-smaller">

        <modal id="advanced-search-container" v-cloak>
            <template slot="title"><h4 class="mb-0">{{ trans('properties.advanced_filters') }}</h4></template>
            <div slot="body">
                <advanced-search-container></advanced-search-container>
            </div>
            <div slot="footer">
                <advanced-search-container-buttons-quick @closeModal="closeModal"></advanced-search-container-buttons-quick>
            </div>
        </modal>

        <div class="desktop">

            <div class="property-search-quick--selection-1">
                <ai-residential-commercial-container
                    :enable-ai-search="this.enableAiSearch" :showResidential="showResidential" :showCommercial="showCommercial"
                    @showAISearch="updateShowAISearch"
                />
            </div>

            <template v-if="showAISearch">
                <property-search-ai :old-prompt="aiSearchOldValue" />
            </template>

            <template v-else>

                <div class="property-search-quick--selection-2">

                    <div class="flex-wrap">

                        <div class="mb-10 mr-20 sale-to-let--smaller">
                            <sale-to-let-container :show-buy="showBuy" :show-rent="showRent"></sale-to-let-container>
                        </div>

                        <div class="mr-10 mb-10 d-flex align-items-center">
                            <mls :ApplyButton="false"></mls>
                        </div>

                    </div>

                    <div class="flex-wrap pt-30">

                        <div class="mr-10 mb-10 d-flex align-items-center">
                            <property-types-container :apply-button="false" :text="trans('field-search-containers.property_type')"></property-types-container>
                        </div>

                        <div class="mr-10 mb-10 d-flex align-items-center">
                            <towns-container :apply-button="false" :text="trans('field-search-containers.location')"></towns-container>
                        </div>

                        <div class="mr-10 mb-10 d-flex align-items-center">
                            <zones-container :apply-button="false" :text="trans('field-search-containers.zone')"></zones-container>
                        </div>

                    </div>

                    <div>
                        <div class="property-search-quick--selection2--budget">
                            <div>
                                <price-range></price-range>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="property-search-quick--selection-buttons justify-content-between">
                    <a class="btn btn-link" @click="handleAdvancedFiltersClickQuick()"><icon-filter />{{ trans('properties.advanced_filters') }}</a>
                    <button class="btn btn-primary" @click="handleSearchPropertiesClick()">{{ trans('properties.search_properties') }}</button>
                </div>

            </template>

        </div>
        
        <div class="mobile">

            <div class="property-search-quick--selection-1">
                <ai-residential-commercial-container
                    :enable-ai-search="this.enableAiSearch" :showResidential="showResidential" :showCommercial="showCommercial"
                    @showAISearch="updateShowAISearch"
                />
            </div>

            <template v-if="showAISearch">
                <property-search-ai :old-prompt="aiSearchOldValue" />
            </template>

            <template v-else>

                <div class="property-search-quick--selection-2">
                    <div>
                        <!-- <div class="title" v-html="trans('properties.looking_for_a_property_to')"></div> -->
                        <div><sale-to-let-container :show-buy="showBuy" :show-rent="showRent"></sale-to-let-container></div>
                    </div>
                    <div>
                        <!-- <div class="title" v-html="trans('properties.select_property_type')"></div> -->
                        <div><property-types-container :apply-button="false" :text="trans('field-search-containers.property_type')"></property-types-container></div>
                    </div>
                    <div>
                        <div><towns-container :apply-button="false" :text="trans('field-search-containers.location')"></towns-container></div>
                    </div>
                    <div>
                        <div><zones-container :apply-button="false" :text="trans('field-search-containers.zone')"></zones-container></div>
                    </div>
                    <div>
                        <div class="property-search-quick--selection2--budget">
                            <div>
                                <price-range></price-range>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="property-search-quick--selection-buttons">
                    <a class="btn btn-link" @click="handleAdvancedFiltersClickQuick()"><icon-filter />{{ trans('properties.advanced_filters') }}</a>
                    <button class="btn btn-primary" @click="handleSearchPropertiesClick()">{{ trans('properties.search_properties') }}</button>
                </div>

            </template>
        </div>
    </div>
</template>

<script>

    export default {

        props: {
            enableAiSearch: Boolean,
            aiSearchOldValue: String,
            buyAndRent: Object,
            preFilter: Array,
            propertyTypes: Object
        },

        data: function() {
            return {              
                showAdvancedFilter: false,
                showAISearch: false,
            }
        },

        mounted() {
            this.setPreFiltering(this.preFilter);
        },

        computed: {
            showBuy: function () {
                return this.buyAndRent.show_buy;
            },
            showRent: function () {
                return this.buyAndRent.show_rent;
            },
            showResidential: function () {
                return this.propertyTypes.show_residential;
            },
            showCommercial: function () {
                return this.propertyTypes.show_commercial;
            }
        },

        methods: {
            /**
             * @param {*} updatedData 
             */
            updateShowAISearch(updatedData) {
                this.showAISearch = updatedData;
            },

            setPreFiltering(data) {
                for (var i = 0; i < data.length; i++) {
                    var this_filter = data[i];
                    this.$store.dispatch('ListingSearch/updateSearchCriteria', {key: this_filter.key, value: this_filter.value});          
                }
            },

            closeModal: function() {
                this.showAdvancedFilter = false;
                VoerroModal.hide('advanced-search-container');
            },

            handleSearchPropertiesClick: function() {
                //get url params
                var url_params = this.$helpers.convertObjectInQueryParameters(this.$store.getters['ListingSearch/get']);

                //redirect to properties with params attaced
                window.location.href = '/listings?'+url_params;
            },

            handleAdvancedFiltersClickQuick: function() {
                //open advanced filters popup
                this.showAdvancedFilter = !this.showAdvancedFilter;
                if (this.showAdvancedFilter) {
                    VoerroModal.show('advanced-search-container');
                }
                else {
                    VoerroModal.hide('advanced-search-container');
                }
            },
        },  
    }
</script>
